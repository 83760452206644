import { call, put, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import axios from 'axios';

import { actionTypes as recipesActionType } from '../reducers';
import { isFunc } from '../../../utils/functions';

const Api = {
  getRecipes: ({ query, limit, offset }) =>
    axios.get(`/recipe?limit=${limit ?? 25}${query && query.trim() !== '' ? `&search=${query}` : ''}&offset=${offset ?? 0}`),
  getSuggestedRecipes: ({ query, limit }) =>
    axios.get(`/recipe/suggested?limit=${limit ?? 25}${query && query.trim() !== '' ? `&search=${query}` : ''}`),
  getRecipe: (id) => axios.get(`/recipe/${id}`),
  deleteRecipe: (id) => axios.delete(`/recipe/${id}`),
  setRating: ({ id, score }) => axios.put(`/recipe/${id}/rating`, { score }),
  importToList: ({ recipeId, listId, rejectedIngredientIds }) =>
    axios.put(`/recipe/${recipeId}/import-to/${listId}`, rejectedIngredientIds?.length ? { rejectedIngredientIds } : {}),
  importToNewList: ({ recipeId, rejectedIngredientIds }) =>
    axios.put(`/recipe/${recipeId}/import`, rejectedIngredientIds?.length ? { rejectedIngredientIds } : {}),
  upsertRecipe: (recipe) => axios.put('/recipe', recipe),
  saveRecipe: (id) => axios.get(`/recipe/${id}/save`),
  acceptRecipe: (recipeId) => axios.get(`/recipe/${recipeId}/save`),
  declineRecipe: (recipeId) => axios.get(`/recipe/${recipeId}/decline`),
  getRecipeNotes: (id) => axios.get(`/recipe/${id}/notes`),
  setRecipeNotes: ({ id, text }) => axios.put(`/recipe/${id}/notes`, { text }),
  getBloggersRecipes: ({ query, limit, offset, publisherId }) =>
    axios.get(`/recipe/blogger?limit=${limit ?? 10}${query && query.trim() !== '' ? `&search=${query}` : ''}
    &offset=${offset ?? 0}&publisherId=${publisherId ?? 'undefined'}`),
  promoteRecipe: (id) => axios.get(`/recipe/${id}/set-to-promote`),
  registerUrlClick: (id) => axios.get(`/recipe/${id}/register-url-click`),
  getPromotionsInfo: (recipeIds) => axios.post('/recipe/promotions-info', { recipeIds })
};

export const actionTypes = {
  GET_RECIPES: 'GET_RECIPES',
  GET_SUGGESTED_RECIPES: 'GET_SUGGESTED_RECIPES',
  GET_BLOGGERS_RECIPES: 'GET_BLOGGERS_RECIPES',
  GET_RECIPE: 'GET_RECIPE',
  UPSERT_RECIPE: 'UPSERT_RECIPE',
  GET_RECIPES_WITH_DEBOUNCE: 'GET_RECIPES_WITH_DEBOUNCE',
  GET_SUGGESTED_RECIPES_WITH_DEBOUNCE: 'GET_SUGGESTED_RECIPES_WITH_DEBOUNCE',
  GET_BLOGGERS_RECIPES_WITH_DEBOUNCE: 'GET_BLOGGERS_RECIPES_WITH_DEBOUNCE',
  SET_RATING: 'SET_RATING',
  IMPORT_TO_LIST: 'IMPORT_TO_LIST',
  IMPORT_TO_NEW_LIST: 'IMPORT_TO_NEW_LIST',
  SAVE_RECIPE: 'SAVE_RECIPE',
  GET_RECIPE_NOTES: 'GET_RECIPE_NOTES',
  SET_RECIPE_NOTES: 'SET_RECIPE_NOTES',
  PROMOTE_RECIPE: 'PROMOTE_RECIPE',
  ACCEPT_RECIPE: 'ACCEPT_RECIPE',
  DECLINE_RECIPE: 'DECLINE_RECIPE',
  REGISTER_URL_CLICK: 'REGISTER_URL_CLICK',
  GET_PROMOTIONS_INFO: 'GET_PROMOTIONS_INFO',
  DELETE_RECIPE: 'DELETE_RECIPE'
};

function* sagaGetRecipes({ payload: { query, limit, offset, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getRecipes, { query, limit, offset });
    yield put({ type: recipesActionType.RECIPES, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSuggestedRecipes({ payload: { query, limit, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getSuggestedRecipes, { query, limit });
    yield put({ type: recipesActionType.SUGGESTED_RECIPES, payload: (data ?? []) });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetBloggersRecipes({ payload: { query, publisherId, limit, offset, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getBloggersRecipes, { query, limit, offset, publisherId });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPromotionsInfo({ payload: { recipeIds, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getPromotionsInfo, recipeIds);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetRecipe({ payload: { onSuccess, onError, recipeId } }) {
  try {
    const { data } = yield call(Api.getRecipe, recipeId);
    yield put({ type: recipesActionType.RECIPE, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteRecipe({ payload: { onSuccess, onError, recipeId } }) {
  try {
    const result = yield call(Api.deleteRecipe, recipeId);
    if (result.status === 204 && isFunc(onSuccess)) onSuccess();
    else if (result.status >= 400 && isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAcceptRecipe({ payload: { onSuccess, onError, recipeId } }) {
  try {
    const { data } = yield call(Api.acceptRecipe, recipeId);
    yield put({ type: recipesActionType.RECIPE, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeclineRecipe({ payload: { onSuccess, onError, recipeId } }) {
  try {
    const { data } = yield call(Api.declineRecipe, recipeId);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetRecipeNotes({ payload: { onSuccess, onError, id } }) {
  try {
    const { data } = yield call(Api.getRecipeNotes, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSetRecipeNotes({ payload: { onSuccess, onError, id, text } }) {
  try {
    const { data } = yield call(Api.setRecipeNotes, { id, text });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpsertRecipe({ payload: { onSuccess, onError, recipe } }) {
  try {
    const { data } = yield call(Api.upsertRecipe, recipe);
    //yield put({ type: recipesActionType.RECIPE, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSaveRecipe({ payload: { onSuccess, onError, id } }) {
  try {
    const { data } = yield call(Api.saveRecipe, id);
    //yield put({ type: recipesActionType.RECIPE, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSetRating({ payload: { onSuccess, onError, id, score } }) {
  try {
    const { data } = yield call(Api.setRating, { id, score });
    yield put({ type: recipesActionType.RECIPE, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaImportToList({ payload: { onSuccess, onError, recipeId, listId, rejectedIngredientIds } }) {
  try {
    const { data } = yield call(Api.importToList, { recipeId, listId, rejectedIngredientIds });
    if (!data && isFunc(onError)) onError();
    // ACTIVE_LIST should be updated in onSuccess
    else if (data && isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaImportToNewList({ payload: { onSuccess, onError, recipeId, rejectedIngredientIds } }) {
  try {
    const { data } = yield call(Api.importToNewList, { recipeId, rejectedIngredientIds });
    if (!data && isFunc(onError)) onError();
    // ACTIVE_LIST should be updated in onSuccess
    else if (data && isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaPromoteRecipe({ payload: { onSuccess, onError, id } }) {
  try {
    const { data } = yield call(Api.promoteRecipe, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRegisterUrlClick({ payload: { onSuccess, onError, id } }) {
  try {
    const { data } = yield call(Api.registerUrlClick, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getRecipes = (payload) => ({ type: actionTypes.GET_RECIPES, payload });
export const getSuggestedRecipes = (payload) => ({ type: actionTypes.GET_SUGGESTED_RECIPES, payload });
export const getBloggersRecipes = (payload) => ({ type: actionTypes.GET_BLOGGERS_RECIPES, payload });
export const getRecipe = (payload) => ({ type: actionTypes.GET_RECIPE, payload });
export const acceptRecipe = (payload) => ({ type: actionTypes.ACCEPT_RECIPE, payload });
export const declineRecipe = (payload) => ({ type: actionTypes.DECLINE_RECIPE, payload });
export const getRecipesWithDebounce = (payload) => ({ type: actionTypes.GET_RECIPES_WITH_DEBOUNCE, payload });
export const getSuggestedRecipesWithDebounce = (payload) => ({ type: actionTypes.GET_SUGGESTED_RECIPES_WITH_DEBOUNCE, payload });
export const getBloggersRecipesWithDebounce = (payload) => ({ type: actionTypes.GET_BLOGGERS_RECIPES_WITH_DEBOUNCE, payload });
export const setRating = (payload) => ({ type: actionTypes.SET_RATING, payload });
export const importToList = (payload) => ({ type: actionTypes.IMPORT_TO_LIST, payload });
export const importToNewList = (payload) => ({ type: actionTypes.IMPORT_TO_NEW_LIST, payload });
export const upsertRecipe = (payload) => ({ type: actionTypes.UPSERT_RECIPE, payload });
export const saveRecipe = (payload) => ({ type: actionTypes.SAVE_RECIPE, payload });
export const getRecipeNotes = (payload) => ({ type: actionTypes.GET_RECIPE_NOTES, payload });
export const setRecipeNotes = (payload) => ({ type: actionTypes.SET_RECIPE_NOTES, payload });
export const promoteRecipe = (payload) => ({ type: actionTypes.PROMOTE_RECIPE, payload });
export const registerUrlClick = (payload) => ({ type: actionTypes.REGISTER_URL_CLICK, payload });
export const getPromotionsInfo = (payload) => ({ type: actionTypes.GET_PROMOTIONS_INFO, payload });
export const deleteRecipe = (payload) => ({ type: actionTypes.DELETE_RECIPE, payload });

export default [
  takeLatest(actionTypes.GET_RECIPES, sagaGetRecipes),
  takeLatest(actionTypes.GET_SUGGESTED_RECIPES, sagaGetSuggestedRecipes),
  takeLatest(actionTypes.GET_RECIPE, sagaGetRecipe),
  takeLatest(actionTypes.ACCEPT_RECIPE, sagaAcceptRecipe),
  takeLatest(actionTypes.DECLINE_RECIPE, sagaDeclineRecipe),
  debounce(600, actionTypes.GET_RECIPES_WITH_DEBOUNCE, sagaGetRecipes),
  debounce(600, actionTypes.GET_SUGGESTED_RECIPES_WITH_DEBOUNCE, sagaGetSuggestedRecipes),
  takeLatest(actionTypes.SET_RATING, sagaSetRating),
  takeEvery(actionTypes.IMPORT_TO_LIST, sagaImportToList),
  takeEvery(actionTypes.IMPORT_TO_NEW_LIST, sagaImportToNewList),
  takeEvery(actionTypes.UPSERT_RECIPE, sagaUpsertRecipe),
  takeEvery(actionTypes.SAVE_RECIPE, sagaSaveRecipe),
  takeLatest(actionTypes.GET_RECIPE_NOTES, sagaGetRecipeNotes),
  takeEvery(actionTypes.SET_RECIPE_NOTES, sagaSetRecipeNotes),
  takeEvery(actionTypes.PROMOTE_RECIPE, sagaPromoteRecipe),
  takeLatest(actionTypes.GET_BLOGGERS_RECIPES, sagaGetBloggersRecipes),
  debounce(600, actionTypes.GET_BLOGGERS_RECIPES_WITH_DEBOUNCE, sagaGetBloggersRecipes),
  takeLatest(actionTypes.REGISTER_URL_CLICK, sagaRegisterUrlClick),
  takeLatest(actionTypes.GET_PROMOTIONS_INFO, sagaGetPromotionsInfo),
  takeEvery(actionTypes.DELETE_RECIPE, sagaDeleteRecipe)
];
