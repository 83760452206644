const defaultState = {
  usersStatistics: undefined,
  bloggers: undefined,
  users: undefined
};

export const actionTypes = {
  USERS_STATISTICS: 'USERS_STATISTICS',
  BLOGGERS: 'BLOGGERS',
  USERS: 'USERS'
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.USERS_STATISTICS:
      return {
        ...state,
        usersStatistics: payload
      };
    case actionTypes.BLOGGERS:
      return {
        ...state,
        bloggers: payload
      };
    case actionTypes.USERS:
      return {
        ...state,
        users: payload
      };
    default:
      return state;
  }
}
