const mergeModules = (...modules) => {
  return {
    routes: modules.reduce((acc, { routes }) => (routes ? [...acc, ...routes] : acc), []),
    reducers: modules.reduce((acc, { reducers }) => (reducers ? { ...acc, ...reducers } : acc), {}),
    sagas: modules.reduce((acc, { sagas }) => (sagas ? [...acc, ...sagas] : acc), []),
    locales: modules.reduce((acc, { locales }) => {
      const addLocales = () => {
        return Object.keys(locales.resources).reduce(
          (acc2, language) => ({
            ...acc2,
            [language]: { ...acc2[language], [locales.ns]: locales.resources[language] }
          }),
          acc
        );
      };

      return locales && locales.resources ? addLocales() : acc;
    }, {}),
    menuItems: modules.reduce((acc, { menuItems }) => (menuItems ? [...acc, ...menuItems] : acc), [])
  };
};

export default mergeModules;
