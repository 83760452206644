import React from 'react';
import { FreeRoute } from '../auth/components';

import { Terms } from './pages';

import locales from './locales';

export default {
  routes: [
    <FreeRoute key="terms" exact path="/terms" component={Terms} shouldHideMainLayoutWrapper />
  ],
  locales: { ns: 'terms', resources: locales }
};
