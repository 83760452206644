import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntModal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components';

import './styles.global.scss';

const DefaultModal = ({
  handleOk,
  handleCancel,
  modal,
  body,
  ok,
  cancel,
  closable,
  maskClosable,
  bodyStyle,
  width = 800
}) => {
  const { t } = useTranslation('common');

  const renderFooter = () => {
    const { isHide, isDisabled = false, isLoading } = ok;
    const okBtn = !isHide && (
      <Button
        key="ok"
        disabled={isDisabled}
        loading={isLoading}
        type={ok.btnType ? ok.btnType : 'primary'}
        onClick={handleOk}
        id={ok.btnId}
      >
        {ok.title || t('btns.save')}
      </Button>
    );

    const cancelBtn = !cancel.isHide && (
      <Button
        key="cancel"
        onClick={handleCancel}
        type={cancel.btnType ? cancel.btnType : 'default'}
        id={cancel.btnId && cancel.btnId !== '' ? cancel.btnId : null}
      >
        {cancel.title || t('btns.cancel')}
      </Button>
    );

    const btns = [cancelBtn, okBtn].filter(Boolean);

    return (
      <Row type="flex" justify="end" align="middle" style={{ width: '100%' }}>
        <Col style={{ display: 'flex' }}>{btns.length ? btns : null}</Col>
      </Row>
    );
  };

  const { Component, componentProps = {} } = body;

  return (
    <AntModal
      onClick={e => e.stopPropagation()}
      closable={closable}
      maskClosable={maskClosable}
      {...modal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={renderFooter()}
      bodyStyle={bodyStyle}
      className={'DefaultModal'}
      width={width}
      centered
    >
      {body.title && (
        <div
          className="modal-title-text"
          style={{
            textAlign: body.titleAlignment || 'center',
            padding: body.titleAlignment === 'left' ? '0 40px 20px 0' : '0 40px 20px'
          }}
        >
          {body.title}
        </div>
      )}
      {body.text && <p style={{ textAlign: body.textAlignment || 'center', marginBottom: '20px' }}>{body.text}</p>}
      {Component && <Component {...componentProps} />}
    </AntModal>
  );
};

DefaultModal.propTypes = {
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  body: PropTypes.object,
  ok: PropTypes.object,
  cancel: PropTypes.object,
  back: PropTypes.object,
  forward: PropTypes.object,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  bodyStyle: PropTypes.object,
  width: PropTypes.number
};

export default DefaultModal;
