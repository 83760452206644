import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col } from 'antd';

import { General } from '../components';
import styles from './styles.module.scss';
import { Footer } from '../../common/components';

const Account = () => {
  return (
    <>
      <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
        <Col className={styles.cardWrapper}>
          <General />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

Account.propTypes = {
  user: PropTypes.object
};

export default compose(
  connect(
    ({ user: { user } }) => ({
      user
    }),
    {}
  )
)(Account);
