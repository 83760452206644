import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { Button, Icon } from '../../../common/components';
import history from '../../../../utils/history';
import { withDimensions } from '../../../common/HOC';

const TitleArea = ({ dimensions, hide, mainTitleRef, mainDescriptionRef }) => {
  const { t } = useTranslation('landing');

  return (
    <div className={`${styles.titleAreaWrapper} ${dimensions?.isScreenBiggerMD ? '' : styles.mobile}`}>
      <Row type="flex" justify="center" align="top">
        <div className={`${styles.mainTitle} ${hide?.mainTitle ? styles.hidden : ''}`} ref={mainTitleRef}>
          {t('mainTitle')}
        </div>
      </Row>
      <Row type="flex" justify="center" align="top">
        <div
          className={`${styles.mainDescription} ${hide?.mainDescription ? styles.hidden : ''}`}
          ref={mainDescriptionRef}
        >
          {t('mainDescription')}
        </div>
      </Row>
      <Row type="flex" justify="center" align="top">
        <Col>
          <Button
            type="primary"
            className={`${styles.createListBtn} ${hide?.mainCreateListButton ? styles.hidden : ''}`}
            onClick={() => history.push('/lists')}
          >
            <Icon name="heap" height={dimensions?.isScreenBiggerMD ? 22 : 16} />
            {t('createMyList')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

TitleArea.propTypes = {
  dimensions: PropTypes.object.isRequired,
  hide: PropTypes.object
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(TitleArea);
