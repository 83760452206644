import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useModal } from '../../hooks';

const DeleteModal = ({ entity, deleteFn, modalTriggerComponent, entityName }) => {
  const { t } = useTranslation('common');

  const { Modal: DeleteEntityModal } = useModal({
    body: {
      title: `${t('delete')} ${entityName}`,
      titleAlignment: 'left',
      Component: () => (
        <p>
          Are you sure you want to delete {(entity.name ?? entity.email) ? <b>{entity.name ?? entity.email}</b> : t('this')} {entityName}?
        </p>
      )
    },
    bodyStyle: {
      textAlign: 'left'
    },
    btnComponent: modalTriggerComponent
      ?? (({ onClick }) => (
        <div
          onClick={onClick}
        >
          {t('delete')}
        </div>
      )),
    ok: {
      title: t('delete'),
      onClick: () =>
        deleteFn(entity),
      btnType: 'primary'
    },
    cancel: {
      btnType: 'default'
    },
    modal: {
      width: 600
    },
    closable: true,
    maskClosable: true
  });

  return <>{DeleteEntityModal}</>;
};

DeleteModal.propTypes = {
  entity: PropTypes.object.isRequired,
  modalTriggerComponent: PropTypes.func,
  deleteFn: PropTypes.func,
  entityName: PropTypes.string.isRequired
};

export default DeleteModal;
