import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'antd';
import styles from '../../../privacyPolicy/pages/PrivacyPolicy/styles.module.scss';
import { Header, Footer } from '../../../landing/components';

const Terms = () => {
  const { t } = useTranslation('terms');

  const availableModes = [
    { key: 'landing', title: t('howItWorks'), width: 107 },
    { key: 'blogger', title: t('areYouBlogger'), width: 169 }
  ];

  return (
      <div className={styles.pageWrapper}>
        <Header mode="terms" availableModes={availableModes} />
        <Row type="flex" justify="center" align="top">
          <div className={styles.contentWrapper}>
            <p className={styles.mainTitle}>{t('terms')}</p>
            <p className={styles.subHeader}>{t('points.p_1')}</p>
            <div className={styles.pageContent}>
              <p>{t('points.p_1_1')}</p>
              <p>{t('points.p_1_1_1')}</p>
              <p>{t('points.p_1_1_2')}</p>
              <p>{t('points.p_1_1_3')}</p>
              <p>{t('points.p_1_2')}</p>
              <p>{t('points.p_1_3')}</p>
            </div>
            <p className={styles.subHeader}>{t('points.p_2')}</p>
            <div className={styles.pageContent}>
              <p>{t('points.p_2_1')}</p>
            </div>
            <p className={styles.subHeader}>...</p>
          </div>
        </Row>
        <Footer mode="terms" />
      </div>
  );
};

export default Terms;
