import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, notification, Form } from 'antd';
import { object, string } from 'yup';
import { Formik } from 'formik';

import styles from './styles.module.scss';

import { getRedirectToAmazonCart } from '../../../amazon/sagas';
import { getRedirectToInstacartCart } from '../../../instacart/sagas';
import { Button, FieldAdapter as Field, RenderField, Spinner } from '../../../common/components';
import { updateUser } from '../../../user/sagas';

const OnlineShopsSelector = ({
  list,
  getRedirectToAmazonCart,
  getRedirectToInstacartCart,
  user,
  values: { zipCode },
  setValues,
  handleSubmit,
  isValid
}) => {
  const { t } = useTranslation('lists');

  const [amazonList, setAmazonList] = useState(undefined);
  const [instacartList, setInstacartList] = useState(undefined);
  const [mode, setMode] = useState('selector');// selector|amazon|instacart
  const [processing, setProcessing] = useState(false);
  const [targetLink, setTargetLink] = useState(undefined);

  useEffect(() => {
    const notPurchasedItems = list.items.filter((it) => !it.purchased);
    if (list && !amazonList) {
      const amazonItems = notPurchasedItems.map((it) => {
        const ingredient = { name: it.name };

        if (it.amount || (it.unit?.name)) {
          const quantityItem = { amount: it.amount ?? 1 };
          quantityItem.unit = it.unit?.name ?? 'COUNT';
          ingredient.quantityList = [quantityItem];
        }

        return ingredient;
      });
      setAmazonList({ ingredients: amazonItems });
    }
    if (list && !instacartList) {
      const instacartItems = notPurchasedItems.map((it) => { return { name: it.name }; });
      setInstacartList(instacartItems);
    }
  }, [list]); // eslint-disable-line

  useEffect(() => {
    setTargetLink(undefined);
  }, [mode]); // eslint-disable-line

  useEffect(() => {
    setValues({ zipCode: user?.location?.zipCode ?? '' });
  }, [user]); // eslint-disable-line

  const goToAmazonCart = (list) => {
    if (list?.ingredients?.length && !processing) {
      setProcessing(true);
      setMode('amazon');
      getRedirectToAmazonCart({
        ingredients: list,
        onError: () => {
          notification.error({ message: 'Unable to process items' });
          setProcessing(false);
          setMode('selector');
        },
        onSuccess: (data) => {
          setProcessing(false);
          setTargetLink(data.longUrl);
        }
      });
    }
  };

  const goToInstacartCart = (list) => {
    if (list?.length && !processing && zipCode) {
      setProcessing(true);
      setMode('instacart');
      getRedirectToInstacartCart({
        ingredients: list,
        zipCode,
        onError: () => {
          notification.error({ message: 'Unable to process items' });
          setProcessing(false);
          setMode('selector');
        },
        onSuccess: (data) => {
          setProcessing(false);
          setTargetLink(data.url);
        }
      });
    }
  };

  return (
    <>
      {mode === 'selector' && (<>
        <div className={styles.subTitle}>{t('location')}</div>
        <div className={styles.locationSelector}>
          <Form name="editProfile" onSubmit={handleSubmit}>
            <Row type="flex" justify="start" align="top">
              <Col className={styles.labelWrapper}>{t('currentZipCode')}:</Col>
              <Col className={styles.inputWrapper}>
                <Field
                  size="large"
                  name="zipCode"
                  component={RenderField}
                  type="text"
                  value={zipCode}
                />
              </Col>
              <Col>
                { user && (
                  <Button
                    disabled={!isValid || (zipCode === (user?.location?.zipCode ?? ''))}
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                  >
                    {t('save')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        <div className={styles.subTitle}>{t('selectYourShop')}</div>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={`${styles.shopButton} ${!processing && amazonList?.ingredients?.length ? '' : styles.disabled}`}
          onClick={() => goToAmazonCart(amazonList)}
        >
          <Col><img src="/AmazonFresh.jpeg" alt="Amazon Fresh logo" className={styles.amazonLogo} /></Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={`${styles.shopButton} ${!processing && instacartList?.length && zipCode && isValid ? '' : styles.disabled}`}
          onClick={() => goToInstacartCart(instacartList)}
        >
          <Col><img src="/Instacart.svg" alt="Instacart logo" className={styles.instacartLogo} /></Col>
          <Col className={styles.warning}>{t('zipCodeRequired')}</Col>
        </Row>
      </>)}
      {mode === 'amazon' && (<>
        <div className={styles.linkBack}>
          <Button type="bareLink" disabled={processing} onClick={() => setMode('selector')}>
            {t('backToShopsList')}
          </Button>
        </div>
        <div className={styles.amazonLogoSmallWrapper}>
          <img src="/AmazonFresh.jpeg" alt="Amazon Fresh logo" className={styles.amazonLogoSmall} />
        </div>
        {processing ? (
          <Row type="flex" justify="center" align="middle" className={styles.spinnerWrapper}>
            <Spinner className={styles.spinner} size="large" />
          </Row>
        ) : (<>
          <Row type="flex" justify="center" align="middle" className={styles.spinnerWrapper}>
            {!!targetLink && (<>
              <div className={styles.completedLabel}>{t('yourItemsAreProcessed')}</div>
              <div className={styles.completedLink}>
                <a href={targetLink} target="_blank" rel="noopener noreferrer">{t('linkToAmazon')}</a>
              </div>
            </>)}
          </Row>
        </>)}
      </>)}
      {mode === 'instacart' && (<>
        <div className={styles.linkBack}>
          <Button type="bareLink" disabled={processing} onClick={() => setMode('selector')}>
            {t('backToShopsList')}
          </Button>
        </div>
        <div className={styles.instacartLogoSmallWrapper}>
          <img src="/Instacart.svg" alt="Instacart logo" className={styles.instacartLogoSmall} />
        </div>
        {processing ? (
          <Row type="flex" justify="center" align="middle" className={styles.spinnerWrapper}>
            <Spinner className={styles.spinner} size="large" />
          </Row>
        ) : (<>
          <Row type="flex" justify="center" align="middle" className={styles.spinnerWrapper}>
            {!!targetLink && (<>
              <div className={styles.completedLabel}>{t('yourItemsAreProcessed')}</div>
              <div className={styles.completedLink}>
                <a href={targetLink} target="_blank" rel="noopener noreferrer">{t('linkToInstacart')}</a>
              </div>
            </>)}
          </Row>
        </>)}
      </>)}
    </>
  );
};

OnlineShopsSelector.propTypes = {
  list: PropTypes.object,
  user: PropTypes.object,
  updateUser: PropTypes.func
};

const getValidationSchema = t => {
  return object().shape({
    zipCode: string()
      .matches(/^[0-9]{5}$/, t('incorrectZipCode'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('lists');
  const validationSchema = getValidationSchema(t);
  const onSubmit = (values) => {
    if (props.user) {
      const data = { location: { zipCode: values.zipCode } };
      props.updateUser({
        userId: props.user._id,
        userData: data
      });
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount={true}
      displayName="EditProfileForm"
      onSubmit={onSubmit}
      initialValues={{ zipCode: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default compose(
  connect(
    ({ user: { user } }) => ({
      user
    }),
    {
      getRedirectToAmazonCart, getRedirectToInstacartCart, updateUser
    }
  ),
  withFormik
)(OnlineShopsSelector);
