import reducers from './reducers';
import locales from './locales';

export default {
  reducers: {
    common: reducers.mainLayoutAppearance,
    dimensions: reducers.dimensions
  },
  locales: { ns: 'common', resources: locales }
};
