import React from 'react';
import PropTypes from 'prop-types';
import { Row, Spin } from 'antd';

const Spinner = ({ className = '', ...props }) => {
  return (
    <Row className={className} type="flex" justify="center" align="middle">
      <Spin {...props} />
    </Row>
  );
};

Spinner.propTypes = {
  className: PropTypes.string
};

export default Spinner;
