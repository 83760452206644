export default {
  title: 'Title',
  logOut: 'Log out',
  accountSettings: 'Account settings',
  editProfile: 'Edit profile',
  areYouBlogger: 'Are you a food blogger?',
  mobileDetected: 'Mobile device detected',


  navMenu: {
    recipes: 'Recipes',
    lists: 'Lists',
    account: 'Account',
    publishers: 'Publishers',
    users: 'Users',
    adminDashboard: 'Admin Dashboard',
    dashboard: 'Dashboard',
    instructions: 'Instructions',
    testTool: 'Test Tool'
  },

  btns: {
    accept: 'Accept',
    decline: 'Decline',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    gotIt: 'Got it'
  },
  delete: 'Delete'
};
