import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { setIsHiddenMainLayoutWrapper } from '../../../common/reducers';
import { logOut } from '../../../user/sagas';

const FreeRoute = ({
  component: Component,
  logOut,
  isSoftLogout,
  shouldHideMainLayoutWrapper,
  setIsHiddenMainLayoutWrapper,
  ...rest
}) => {
  useEffect(() => {
    setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper);
    if (isSoftLogout) {
      logOut({ isSoft: true });
    }
  }, []); // eslint-disable-line

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

FreeRoute.propTypes = {
  setIsHiddenMainLayoutWrapper: PropTypes.func.isRequired,
  component: PropTypes.any,
  shouldHideMainLayoutWrapper: PropTypes.bool,
  isSoftLogout: PropTypes.bool,
  logOut: PropTypes.func.isRequired
};

export default compose(
  connect(
    null,
    { logOut, setIsHiddenMainLayoutWrapper }
  )
)(FreeRoute);
