import React from 'react';
import { AuthRoute } from '../auth/components';

import { AdminDashboard, Publishers, TestTool } from './pages';

import locales from './locales';
import sagas from './sagas';
import reducers from './reducers';

export default {
  routes: [
    <AuthRoute key="admin-dashboard" exact path="/admin-dashboard" authorization={['admin']} component={AdminDashboard} />,
    <AuthRoute key="users" exact path="/users" authorization={['admin']} component={Publishers}/>,
    <AuthRoute key="testTool" exact path="/testTool" authorization={['admin']} component={TestTool}/>
  ],
  locales: { ns: 'admin', resources: locales },
  menuItems: [
    {
      name: 'adminDashboard',
      path: '/admin-dashboard',
      authorization: ['admin'],
      width: 140
    },
    {
      name: 'users',
      path: '/users',
      authorization: ['admin'],
      width: 59
    },
    {
      name: 'testTool',
      path: '/testTool',
      authorization: ['admin'],
      width: 80
    }
  ],
  sagas,
  reducers: { admin: reducers }
};
