import React, { useState/*, useEffect*/ } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'antd';
import { object, string, ref } from 'yup';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { RenderField, FieldAdapter as Field, Button } from '../../../common/components';

const SignUpForm = props => {
  const {
    values: { firstName, lastName, password, email, passwordConfirmation },
    handleSubmit,
    isValid
  } = props;

  const { t } = useTranslation('login');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  return (
    <Form name="signUp" onSubmit={handleSubmit} className={styles.form} id="SignUp_Form">
      <span className={styles.label}>{t('firstName')}</span>
      <Field
        size="large"
        name="firstName"
        component={RenderField}
        type="text"
        value={firstName}
      />
      <span className={styles.label}>{t('lastName')}</span>
      <Field
        size="large"
        name="lastName"
        component={RenderField}
        type="text"
        value={lastName}
      />
      <span className={styles.label}>{t('email')}</span>
      <Field
        size="large"
        name="email"
        component={RenderField}
        type="text"
        value={email}
      />
      <span className={styles.label}>{t('password')}</span>
      <Field
        size="large"
        name="password"
        component={RenderField}
        type={isShowPassword ? 'text' : 'password'}
        value={password}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowPassword(!isShowPassword)}
            />
          )
        }}
      />
      <span className={styles.label}>{t('repeatPassword')}</span>
      <Field
        size="large"
        name="passwordConfirmation"
        component={RenderField}
        type={isShowConfirmPassword ? 'text' : 'password'}
        value={passwordConfirmation}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowConfirmPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
            />
          )
        }}
      />
      <Button
        disabled={!isValid}
        type="primary"
        htmlType="submit"
        className={styles.fullWidthButton}
        onClick={handleSubmit}
      >
        {t('createAccount')}
      </Button>
    </Form>
  );
};

SignUpForm.propTypes = {
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  location: PropTypes.object,
  setValues: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    firstName: string()
      .max(40, t('enterMaxName'))
      .required(t('enterFirstName')),
    lastName: string()
      .max(40, t('enterMaxName')),
    email: string()
      .email(t('invalidEmail'))
      .required(t('enterEmail')),
    password: string()
      .required(t('enterPassword')),
    passwordConfirmation: string()
      .oneOf([ref('password')], t('passwordMustMatch'))
      .required(t('confirmPassword'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('login');
  const validationSchema = getValidationSchema(t);
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount={true}
      displayName="SignUpForm"
      onSubmit={values => { props.onSubmit(values); }}
      initialValues={{ firstName: '', lastName: '', password: '', email: '', passwordConfirmation: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default withFormik(withRouter(SignUpForm));
