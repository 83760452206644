import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//import config from '../../../../config';

import styles from './styles.module.scss';

const Logo = ({ to = '/', height, width }) => (
  <Link to={to}>
    <img
      width="580"
      height="141"
      className={styles.logo}
      src="/zestary.svg"
      alt="zestary logo"
      style={ height ? { height: `${height}px`, width: 'auto' } : width ? { width: `${width}px`, height: 'auto' } : {} }
    />
  </Link>
);

Logo.propTypes = {
  to: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Logo;
