import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import { setDimensions } from '../../reducers';
import { computeSizes } from './computeSizes';

const DEBOUNCE_DELAY = 200;

const getDimensions = ({ innerWidth, innerHeight }) => {
  return {
    innerWidth,
    innerHeight
  };
};

function withDimensions(Component) {
  const NewComponent = ({ setDimensions, ...props }) => {
    useEffect(() => {
      const onResize = () => setDimensions(computeSizes(getDimensions(window)));
      const onChange = debounce(onResize, DEBOUNCE_DELAY);

      onChange();

      window.addEventListener('resize', onChange, false);
      window.addEventListener('orientationchange', onChange, false);

      return () => {
        window.removeEventListener('resize', onChange, false);
        window.removeEventListener('orientationchange', onChange, false);
      };
      // eslint-disable-next-line
    }, []);

    return <Component {...props} />;
  };

  return connect(
    null,
    { setDimensions }
  )(NewComponent);
}

export default withDimensions;
