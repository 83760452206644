import account from './account';
import admin from './admin';
import amazon from './amazon';
import instacart from './instacart';
import auth from './auth';
import common from './common';
import login from './login';
import landing from './landing';
import privacyPolicy from './privacyPolicy';
import terms from './terms';
import lists from './lists';
import modal from './modal';
import recipe from './recipe';
import user from './user';
import noRoute from './404';

import mergeModules from '../utils/mergeModules';

// noRoute should be at the end!
export default mergeModules(
  auth,
  modal,
  account,
  admin,
  amazon,
  instacart,
  landing,
  privacyPolicy,
  terms,
  user,
  lists,
  recipe,
  login,
  common,
  noRoute
);
