import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../config';
import { processAPIErrors } from './errorProcessing';

const cookies = new Cookies();
const appConfig = config;

const commonHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache'
};

const addHeader = (config) => {
  return new Promise(async (resolve) => {
    const jwtCookie = await cookies.get('X-JWT'); // eslint-disable-line
    const baseHeaders = config.url.indexOf('api.nal.usda.gov') >= 0 ? config.headers : { ...config.headers, ...commonHeaders };
    const jwtHeader = jwtCookie?.length > 2 ? { 'X-JWT': jwtCookie } : null;
    const headers = config.url.indexOf('api.nal.usda.gov') >= 0
      ? Object.assign({}, baseHeaders)
      : Object.assign({}, baseHeaders, jwtHeader);
    return resolve({ ...config, headers });
  });
};

axios.defaults.baseURL = `${appConfig.SERVER_URL}/api/v1/`;

axios.interceptors.request.use(
  async (config) => {
    return await addHeader(config).catch((config) => config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  function (error) {
    if (error.response) {
      try {
        const resp = error.response;
        if (resp.data && resp.config && resp.status) {
          processAPIErrors(resp.data, resp.config, resp.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
);
