import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import { withRouter } from 'react-router-dom';

import { Row, Col, notification, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { getRecipe, upsertRecipe, saveRecipe } from '../../sagas';
import {
  getSuggestedItemsForArray,
  findUSDAProducts,
  getUSDAProduct,
  addSuggestedItem,
  parseIngredients
} from '../../../lists/sagas/listsSagas';
import { setExternalRecipe, setRawExternalRecipe, setExternalSource, setIFrameId } from '../../reducers';
import { getUser } from '../../../user/sagas';
import { Button, Spinner } from '../../../common/components';
import { ImportIngredientsModal } from '../../components';
import Icon from '../../../common/components/Icon';
import history from '../../../../utils/history';
import { parseRecipeData } from '../../helpers';
import { addCategoryForUnknownItems } from '../../../../utils/addCategoryForUnknownItem';
import { OnlineShopsSelector } from '../../../lists/components';


const IngredientImporter = ({
  user,
  isLoadedUser,
  getUser,
  upsertRecipe,
  saveRecipe,
  externalRecipe,
  rawExternalRecipe,
  externalSource,
  iFrameId,
  setExternalRecipe,
  setRawExternalRecipe,
  setExternalSource,
  setIFrameId,
  getSuggestedItemsForArray,
  findUSDAProducts,
  getUSDAProduct,
  addSuggestedItem,
  parseIngredients
}) => {
  const { t } = useTranslation('recipe');
  const [rejectedIngredients, setRejectedIngredients] = useState([]);
  const [mode, setMode] = useState('ingredients'); //ingredients|shops
  const [formedList, setFormedList] = useState(undefined);

  useEffect(() => {
    if (!user) getUser();

    if (window.removeEventListener) {
      window.removeEventListener('message', receiveMessage, false);
    } else if (window.detachEvent) { // ie8
      window.detachEvent('onmessage', receiveMessage);
    }

    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    } else if (window.attachEvent) { // ie8
      window.attachEvent('onmessage', receiveMessage);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', receiveMessage, false);
      } else if (window.detachEvent) { // ie8
        window.detachEvent('onmessage', receiveMessage);
      }
    };
  }, [user]); //eslint-disable-line

  useEffect(() => {
    if (user && rawExternalRecipe) {
      upsertRecipe({
        recipe: rawExternalRecipe,
        onSuccess: (data) => { /*console.log('Processed external recipe:', data);*/ setExternalRecipe(data); },
        onError: () => { /*if (isFunc(onErrorFn)) onErrorFn();*/ }
      });
    }
  }, [user, rawExternalRecipe]); //eslint-disable-line

  useEffect(() => {
    setRejectedIngredients([]);
  }, [externalRecipe]); //eslint-disable-line

  useEffect(() => {
    if (externalRecipe || rawExternalRecipe) {
      const newList = { name: (externalRecipe ?? rawExternalRecipe).title, items: (externalRecipe ?? rawExternalRecipe).ingredients ?? [] };
      newList.items = newList.items.filter((ingredient) => rejectedIngredients.indexOf(ingredient._id) < 0);
      setFormedList(newList);
    } else setFormedList(undefined);
  }, [externalRecipe, rawExternalRecipe, rejectedIngredients]); //eslint-disable-line

  const receiveMessage = (event) => {
    const onErrorFn = (message = t('unableProcessRecipe')) => {
      console.error('Error message:', message);
      notification.error({ message });
      setTimeout(() => {
        event.source.postMessage({ action: 'close', recipeId: event.data.recipeId }, '*');
      }, 2000);
    };

    if (event.source && !externalSource) {
      setExternalSource(event.source);
    }

    if (event.data?.recipe && event.data?.publisherId && event.data?.recipeId && event.source) {
      if (event.data.intervalId) event.source.postMessage({ action: 'killInterval', intervalId: event.data.intervalId }, '*');

      const onSuccessFn = (result) => {
        const recipeObj = Object.assign({}, result);
        recipeObj.externalId = event.data.recipeId;
        recipeObj.creator = event.data.publisherId;

        setIFrameId(event.data.recipeId);

        const names = recipeObj.ingredients.map((ingredient) => ingredient.name);
        getSuggestedItemsForArray({
          names,
          onSuccess: (results) => {
            const unknownIngredients = [];
            recipeObj.ingredients = recipeObj.ingredients.map((ingredient, idx) => {
              const matchIndex =
                results[idx].map((result) => result.name.trim().toLowerCase()).indexOf(ingredient.name.trim().toLowerCase());

              if (matchIndex >= 0) {
                return results[idx].category ? { category: results[idx].category, ...ingredient } : ingredient;
              }
              unknownIngredients.push(ingredient);
              return ingredient;
            });

            const callback = (categoryDefinitionResults) => {
              const { _id, owners, createdAt, updatedAt, rating, ...cleanedRecipe } = recipeObj;
              const knownIngredients = unknownIngredients.map((ui, idx) => {
                return { category: categoryDefinitionResults[idx]?.categoryId, ...ui };
              });
              cleanedRecipe.ingredients = cleanedRecipe.ingredients.map((ingredient, idx) => {
                const nowKnownIngredient = knownIngredients.find((ki) => ki.name === ingredient.name);
                const updatedIngredient = nowKnownIngredient
                  ? (nowKnownIngredient.category ? { category: nowKnownIngredient.category, ...ingredient } : ingredient)
                  : ingredient;
                updatedIngredient._id = idx;
                return updatedIngredient;
              });

              if (typeof cleanedRecipe.referenceUrl === 'string') cleanedRecipe.referenceUrl = cleanedRecipe.referenceUrl.replace(/#recipe$/, '');

              //console.log('Cleaned recipe:', cleanedRecipe);
              setRawExternalRecipe(cleanedRecipe);
            };

            addCategoryForUnknownItems(unknownIngredients, callback, findUSDAProducts, getUSDAProduct, addSuggestedItem);
          },
          onError: onErrorFn
        });
      };

      parseRecipeData(event.data.recipe, event.data.recipeUrl, event.data.recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn);
    }
  };

  const getGlobalRating = () => {
    const sum = externalRecipe.rating?.reduce?.((acc, r) => (r.score + acc), 0) ?? 0;
    const votes = externalRecipe.rating?.length ?? 0;
    return votes > 0 ? +(sum / votes).toFixed(2) : 0;
  };

  const checkIngredient = (id, newState) => {
    let currentlyRejected = rejectedIngredients;
    if (!newState) {
      currentlyRejected.push(id);
      currentlyRejected = currentlyRejected.filter((value, index, self) => self.indexOf(value) === index);
    } else currentlyRejected = currentlyRejected.filter((rejectedId) => rejectedId !== id);
    setRejectedIngredients(currentlyRejected);
  };

  const onSaveRecipe = () => {
    saveRecipe({
      id: externalRecipe._id,
      onSuccess: () => {
        notification.success({ message: t('recipeSaved') });
        setTimeout(() => {
          externalSource.postMessage({ action: 'close', recipeId: externalRecipe?.externalId }, '*');
        }, 2000);
      },
      onError: () => {
        notification.error({ message: t('unableSaveRecipe') });
      }
    });
  };

  const renderIngredient = (item) => {
    return (
      <Row
        className={styles.item}
        key={item.name + item._id}
        align="middle"
        justify="space-between"
      >
        <Col>
          <span className={styles.checkBoxWrapper}>
            <Checkbox
              onChange={(e) => checkIngredient(item._id, e.target.checked)}
              checked={rejectedIngredients.indexOf(item._id) < 0}
            />
          </span>
          <span className={styles.name}>{item.name}</span>
        </Col>
        <Col>
          <span className={styles.amount}>
            {item.amount ? `${item.amount} ` : (item.unit?.name ? '1 ' : '')}
            {item.unit?.pluralName && item.amount !== 1 ? item.unit.pluralName : (item.unit?.name ?? (item.unit ?? ''))}
          </span>
        </Col>
      </Row>
    );
  };

  const renderIngredientsControl = () => {
    return (
      <>
        {!(externalRecipe || rawExternalRecipe) || !externalSource || !iFrameId
          ? (<Spinner className={styles.spinner} size="large" />)
          : (
            <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
              <Col className={styles.pageContent}>
                <div className={styles.pageTitle}>
                  {t('importIngredientsTitle')}
                </div>
                <div className={styles.recipeTitle}>
                  {(externalRecipe ?? rawExternalRecipe).title}
                </div>
                {(externalRecipe ?? rawExternalRecipe).rating?.length >= 1 && (
                  <div className={styles.ratingWrapper}>
                    <StarRatings
                      rating={getGlobalRating()}
                      starRatedColor="#FFBD00"
                      starHoverColor="#FFBD00"
                      starEmptyColor="#9C9BA2"
                      numberOfStars={5}
                      name='rating'
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                )}
                <div>
                  <Button type="primary" className={styles.buyOnlineBtn} onClick={() => setMode('shops')}>
                    <FontAwesomeIcon icon="cart-plus" className={styles.menuIcon} />
                    {t('buyOnline')}
                  </Button>
                </div>
                {!user && rawExternalRecipe && isLoadedUser && (
                  <div className={styles.loginSuggestion}>
                    <Button type="bareLink" onClick={() => history.push('/login')}>{t('logInOrCreateAccount')}</Button>
                    {' '}{t('toSaveIngredientsAndRecipe')}.
                  </div>
                )}
                <Row type="flex" justify="space-between" >
                  <Col className={styles.rightArea}>
                    <div className={styles.ingredientsSubtitle}>{t('ingredients')}</div>
                    <div>
                      {(externalRecipe ?? rawExternalRecipe).ingredients.map((ingredient) => renderIngredient(ingredient))}
                    </div>
                    <div className={styles.importBtnWrapper}>
                      {user && externalRecipe && (
                        <ImportIngredientsModal
                          recipe={externalRecipe}
                          rejectedIngredientIds={rejectedIngredients}
                          iFrameSource={externalSource}
                          modalTriggerComponent={({ onClick }) => (
                            <Button type="primary" onClick={onClick} className={styles.importBtn}>
                              {t('addSelectedToShoppingList')}
                            </Button>
                          )}
                        />
                      )}
                    </div>
                    {user && externalRecipe?.owners?.indexOf?.(user._id) < 0 && (
                      <div className={styles.importBtnWrapper}>
                        <Button type="secondary" onClick={() => onSaveRecipe()} className={styles.importBtn}>
                          <Icon name="golden-book" />{t('justSaveRecipe')}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
      </>
    );
  };

  const renderShopSelector = () => {
    return (
      <div className={styles.shopSelectorWrapper}>
        <div className={styles.linkBack}>
          <Button type="bareLink" onClick={() => setMode('ingredients')}>
            {'< '}{t('backToIngredientsList')}
          </Button>
        </div>
        <OnlineShopsSelector list={formedList} />
      </div>
    );
  };

  return (
    <>
      <Row type="flex" justify="space-between" align="middle" className={styles.header}>
        <Col>
          <img
            width="580"
            height="141"
            src="/zestary.svg"
            alt="zestary logo"
            style={ { height: '36px', width: 'auto' } }
          />
        </Col>
        <Col >
          { (user && user.email && user.firstName) && (
            <div title={user.email}>
              {user?.picture?.value ? (
                <img
                  width="40"
                  height="40"
                  className={`${styles.accountName}`}
                  src={user.picture?.value ?? '/avatar.svg'}
                  alt="user avatar"
                />
              ) : (
                <div className={styles.accountName}>
                  {user.firstName?.trim()?.[0] ?? ''}{user.lastName?.trim()?.[0] ?? ''}
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      {mode === 'ingredients' ? renderIngredientsControl() : renderShopSelector()}
      <div className={styles.cancelBtnWrapper}>
        <Button
          type="secondary"
          disabled={!externalSource || !iFrameId}
          onClick={() => { if (externalSource && iFrameId) externalSource.postMessage({ action: 'close', recipeId: iFrameId }, '*'); }}
          className={styles.cancelBtn}
        >
          {t('cancel')}
        </Button>
      </div>
    </>
  );
};

IngredientImporter.propTypes = {
  getRecipe: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  isLoadedUser: PropTypes.bool,
  upsertRecipe: PropTypes.func.isRequired,
  saveRecipe: PropTypes.func.isRequired,
  setExternalRecipe: PropTypes.func.isRequired,
  setRawExternalRecipe: PropTypes.func.isRequired,
  setExternalSource: PropTypes.func.isRequired,
  setIFrameId: PropTypes.func.isRequired,
  externalRecipe: PropTypes.object,
  externalSource: PropTypes.any,
  iFrameId: PropTypes.string,
  getSuggestedItemsForArray: PropTypes.func.isRequired,
  findUSDAProducts: PropTypes.func.isRequired,
  getUSDAProduct: PropTypes.func.isRequired,
  addSuggestedItem: PropTypes.func.isRequired,
  parseIngredients: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user, isLoadedUser }, recipe: { externalRecipe, rawExternalRecipe, externalSource, iFrameId } }) => (
      { user, isLoadedUser, externalRecipe, rawExternalRecipe, externalSource, iFrameId }
    ),
    {
      getRecipe,
      getUser,
      upsertRecipe,
      saveRecipe,
      setExternalRecipe,
      setRawExternalRecipe,
      setExternalSource,
      setIFrameId,
      getSuggestedItemsForArray,
      findUSDAProducts,
      getUSDAProduct,
      addSuggestedItem,
      parseIngredients
    }
  ),
  withRouter
)(IngredientImporter);
