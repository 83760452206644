export const addCategoryForUnknownItem = (itemData, callback, findUSDAProducts, getUSDAProduct, addSuggestedItem) => {
  findUSDAProducts({
    itemName: itemData.name,
    onSuccess: (foundProducts) => {
      const productId = foundProducts?.foods?.[0]?.fdcId;
      if (productId) {
        getUSDAProduct({
          productId,
          onSuccess: (foodData) => {
            const item = itemData.name;
            const baseItem = foundProducts.foods[0].description ?? null;
            const score = foundProducts.foods[0].score ?? null;
            const category = foodData.wweiaFoodCategory?.wweiaFoodCategoryDescription
              ?? (foodData.brandedFoodCategory ?? (foodData.foodCategory?.description ?? (foodData.inputFoods.length ? (
                foodData.inputFoods[0].foodCategory?.description ?? (foodData.inputFoods[0].foodDescription ?? null)
              ) : null)));
            addSuggestedItem({
              itemData: { item, baseItem, category, score },
              onSuccess: (newItemCategory) => {
                if (newItemCategory.categoryId) itemData.category = newItemCategory.categoryId;
                callback(itemData);
              },
              onError: () => {
                callback(itemData);
              }
            });
          },
          onError: () => {
            callback(itemData);
          }
        });
      } else {
        callback(itemData);
      }
    },
    onError: () => {
      callback(itemData);
    }
  });
};

export const addCategoryForUnknownItems = (itemsData, callback, findUSDAProducts, getUSDAProduct, addSuggestedItem) => {
  const results = [];

  const addCategoryForHeadItem = (itemsData) => {
    if (!itemsData.length) callback(results);
    else {
      const internalCallback = (itemData) => {
        results.push(itemData);
        addCategoryForHeadItem(itemsData.slice(1));
      };
      addCategoryForUnknownItem(itemsData[0], internalCallback, findUSDAProducts, getUSDAProduct, addSuggestedItem);
    }
  };

  addCategoryForHeadItem(itemsData);
};
