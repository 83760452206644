export default {
  myLists: 'My lists',
  editList: 'Edit list',
  shareList: 'Share list',
  duplicateList: 'Duplicate list',
  deleteList: 'Delete list',
  sharedLists: 'Shared lists',
  addList: 'Add list',
  addItem: 'Add Item',
  newListCreated: 'New List created!',
  listCreationFailed: 'List creation failed!',
  unableGetLists: 'Unable to retrieve lists!',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  accept: 'Accept',
  decline: 'Decline',
  save: 'Save',
  name: 'Name',
  other: 'Other',
  purchased: 'Bought Items',
  restoreAll: 'Uncheck all items',
  description: 'Description',
  unableGetList: 'Unable to retrieve the list',
  unableGetCategories: 'Unable to retrieve available product categories, please, refresh the page',
  unableGetCollaborators: 'Unable to retrieve collaborators info',
  listRetrieved: 'List retrieved',
  noListSelected: 'No active list selected',
  noItemsYet: 'No items in this list yet',
  listDeleted: 'List deleted',
  listDeclined: 'List declined',
  listAccepted: 'List accepted',
  listDeletionFailed: 'List deletion failed!',
  listDecliningFailed: 'List declining failed!',
  listAcceptanceFailed: 'List acceptance failed!',
  listUpdateFailed: 'List update failed!',
  editListTitle: 'Edit List',
  createListTitle: 'Create new list',
  provideAnyDetails: 'Add short description here (optional)',
  nameRequired: 'List name is required',
  nameShouldBeUnique: 'List name should be unique',
  groupBy: 'Group by',
  alphabet: 'None',
  category: 'Category',
  recipe: 'Recipe',
  enterEmail: 'Email',
  invalidEmail: 'Invalid email',
  emailIsRequired: 'Email is required',
  shareThisList: 'Share List',
  share: 'Share',
  collaborator: 'collaborator',
  collaborators: 'Collaborators',
  grouping: {
    category: 'By category',
    alphabet: 'By alphabet',
    recipe: 'By recipe'
  },
  itemNamePlaceholder: 'e.g. Salted Butter',
  addNewItemPlaceholder: 'add new item, e.g. 2 liter apple juice',
  unitPlaceholder: 'Unit',
  myItems: 'My Items',
  keepListsInOnePlace: 'Keep all your grocery lists in one place',
  groupAndSort: 'Group your items by category or recipe.',
  startAddingManually: 'Start adding items manually above',
  importFromRecipe: 'Import from saved recipe',
  list: 'list',
  pending: 'pending',
  buyOnline: 'Buy online',
  selectYourShop: 'Choose your online shop',
  yourItemsAreProcessed: 'Your items are processed and available by link below',
  backToShopsList: '< Back to the list of shops',
  linkToAmazon: 'Link to Amazon Fresh store',
  linkToInstacart: 'Link to Instacart store',
  incorrectZipCode: 'Must be exactly 5 digits',
  currentZipCode: 'Your current zip code',
  location: 'Your location',
  zipCodeRequired: 'Zip code is required'
};
