import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { Button, Icon } from '../../../common/components';
import history from '../../../../utils/history';
import { withDimensions } from '../../../common/HOC';

const PlateWithIngredients = ({ dimensions, hide, saladImgRef, isIngredientsAppeared }) => {
  const { t } = useTranslation('landing');
  const ingredients = ['spinach', 'tomatoes', 'asparagus', 'salmon', 'eggs'];

  return (
    <Row type="flex" justify="center" align="top">
      <div
        ref={saladImgRef}
        className={`${styles.saladPlate} ${dimensions?.isScreenBiggerLG ? '' : styles.mobile}`}
      >
        <img
          width={dimensions?.isScreenBiggerLG ? 1200 : 600}
          height={dimensions?.isScreenBiggerLG ? 675 : 338}
          className={`${styles.plateImage} ${hide?.saladImg ? styles.hidden : ''}`}
          src="/salad.svg"
          alt="salad"
        />
        {ingredients.map((ingredient) => (
          <img
            key={ingredient}
            width={ingredient === 'asparagus' ? 430 : 410}
            height={ingredient === 'asparagus' ? 160 : 140}
            className={
              `${styles.ingredient} ${styles[ingredient]} ${dimensions?.isScreenBiggerLG ? '' : styles.mobile}
               ${hide?.saladImg ? styles.hidden : ''} ${isIngredientsAppeared ? '' : styles.notAppeared}`
            }
            src={`/${ingredient}.svg`}
            alt={ingredient}
          />
        ))}
        <Button
          type="primary"
          className={
            `${styles.createListBtn} ${hide?.saladImg ? styles.hidden : ''} 
            ${dimensions?.isScreenBiggerLG ? '' : styles.mobile}`
          }
          onClick={() => history.push('/lists')}
        >
          <Icon name="heap" height={dimensions?.isScreenBiggerLG ? 22 : 16} />
          {t('createMyList')}
        </Button>
      </div>

    </Row>

  );
};

PlateWithIngredients.propTypes = {
  dimensions: PropTypes.object.isRequired,
  hide: PropTypes.object,
  isIngredientsAppeared: PropTypes.bool
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(PlateWithIngredients);
