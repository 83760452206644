import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row } from 'antd';

import { withFormik } from 'formik';
import { object, string } from 'yup';
import { FieldAdapter as Field, RenderField } from '../../../common/components';
import styles from './styles.module.scss';

const ChangeDetailsForm = ({
  bindSubmit,
  bindIsValid,
  submitForm,
  isValid,
  values: { newName },
  setValues,
  handleSubmit,
  previousName = '',
  newDescription,
  setNewDescription,
  existingNames // eslint-disable-line
}) => {
  const { t } = useTranslation('lists');
  bindSubmit(submitForm);
  bindIsValid(isValid);
  useEffect(() => {
    setValues({
      newName: previousName
    });
    // eslint-disable-next-line
  }, []);

  const onDescriptionChange = value => setNewDescription(value);

  return (
    <Form onSubmit={handleSubmit}>
      <Row className={styles.nameFieldWrapper} >
        <span className={styles.label}>
          {t('name')}
        </span>
        <Field
          size="large"
          name="newName"
          value={newName}
          component={RenderField}
          type="text"
          className={styles.nameField}
          hasFeedback={false}
        />
      </Row>
      <Row className={styles.descriptionTextAreaWrapper} >
        <span className={styles.label}>{t('description')}</span>
        <Field
          size="large"
          name="description"
          onChange={value => onDescriptionChange(value)}
          value={newDescription}
          component={RenderField}
          type="textArea"
          className={styles.descriptionTextArea}
          placeholder={t('provideAnyDetails')}
        />
      </Row>
    </Form>
  );
};

ChangeDetailsForm.propTypes = {
  values: PropTypes.object.isRequired,
  bindSubmit: PropTypes.func.isRequired,
  bindIsValid: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  previousName: PropTypes.string,
  newDescription: PropTypes.string,
  setNewDescription: PropTypes.func.isRequired,
  existingNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

const getValidationSchema = (t, existingNames, previousName) => {
  return object().shape({
    newName: string()
      .required(t('nameRequired'))
      .notOneOf(existingNames.filter(n => n !== previousName), t('nameShouldBeUnique'))
  });
};

const changeDetailsFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ newName: '' }),
  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
  validationSchema: props => getValidationSchema(props.t, props.existingNames, props.previousName),
  displayName: 'ChangeDetailsForm' // helps with React DevTools
});

export default changeDetailsFormWithFormik(ChangeDetailsForm);
