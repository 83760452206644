import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'antd';

import { withFormik } from 'formik';
import { object, string } from 'yup';
import { FieldAdapter as Field, RenderField } from '../../../common/components';
import styles from './styles.module.scss';

const ShareListForm = ({
  bindSubmit,
  bindIsValid,
  submitForm,
  isValid,
  values: { email },
  setValues,
  handleSubmit,
  list,
  user,
  collaborators
}) => {
  const { t } = useTranslation('lists');
  bindSubmit(submitForm);
  bindIsValid(isValid);
  useEffect(() => {
    setValues({
      email: ''
    });
    // eslint-disable-next-line
  }, []);

  const renderCollaboratorInfo = (collaborator, isPending, isMe) => {
    return (
      <Row type="flex" justify="start" align="middle" className={styles.collaboratorWrapper} key={collaborator._id}>
        <Col>
          {collaborator?.picture?.value ? (
            <img
              width="40"
              height="40"
              className={`${styles.accountName}`}
              src={collaborator.picture.value}
              alt="collaborator avatar"
            />
          ) : (
            <div className={styles.accountName}>
              {collaborator.firstName?.trim()?.[0] ?? ''}{collaborator.lastName?.trim()?.[0] ?? ''}
            </div>
          )}
        </Col>
        <Col className={styles.infoBlock}>
          <Row type="flex" justify="start" align="middle">
            <Col className={`${styles.name} ${isMe ? styles.me : ''}`}>
              {`${collaborator.firstName?.trim() ? `${collaborator.firstName.trim()} ` : ''} `}{collaborator.lastName?.trim() ?? ''}
            </Col>
            {!!isPending && (
              <Col className={styles.pending}>
                {t('pending')}
              </Col>
            )}
          </Row>
          <div className={styles.email}>{collaborator.email?.trim() ?? ' '}</div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className={styles.title}>{t('enterEmail')}</div>
        <Field
          size="large"
          name="email"
          component={RenderField}
          type="text"
          value={email}
        />
      </Form>
      <div className={styles.collaborators}>
        {t('collaborators')}
        {renderCollaboratorInfo(user, list?.owners?.indexOf(user._id) < 0, true)}
        {collaborators.map((collaborator) => {
          return renderCollaboratorInfo(collaborator, list?.owners?.indexOf(collaborator._id) < 0, false);
        })}
      </div>
    </>
  );
};

ShareListForm.propTypes = {
  values: PropTypes.object.isRequired,
  bindSubmit: PropTypes.func.isRequired,
  bindIsValid: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  list: PropTypes.object,
  user: PropTypes.object,
  collaborators: PropTypes.arrayOf(PropTypes.object)
};

const getValidationSchema = t => {
  return object().shape({
    email: string()
      .email(t('invalidEmail'))
      .required(t('emailIsRequired'))
  });
};

const shareListFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ email: '' }),
  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
  validationSchema: ({ t }) => getValidationSchema(t),
  displayName: 'ShareListForm' // helps with React DevTools
});

export default shareListFormWithFormik(ShareListForm);
