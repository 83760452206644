import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useModal } from '../../../common/hooks';
import { ChangeDetailsForm } from '..';

let submitDetailsForm = null;

const ListDetailsModal = ({
  list,
  existingNames,
  onSubmitFn,
  modalTriggerComponent,
  title,
  successCallback = () => {},
  errorCallback = () => {}
}) => {
  const { t } = useTranslation('lists');

  const [isNameValid, setIsNameValid] = useState(false);
  const [newDescription, setNewDescription] = useState(list.description);

  useEffect(() => {
    setNewDescription(list.description);
  }, [list]); // eslint-disable-line

  const bindDetailsSubmit = submitFormFn => !submitDetailsForm && (submitDetailsForm = submitFormFn); // eslint-disable-line
  const bindIsNameValid = isNameValid => setTimeout(() => setIsNameValid(isNameValid));

  const submitDetails = () => {
    return submitDetailsForm?.();
  };

  function onChangeDetailsConfirmed({ newName }) {
    submitDetailsForm = null;
    onSubmitFn({
      listId: list._id,
      listData: { name: newName, description: newDescription },
      onSuccess: (data) => {
        successCallback(data);
        hideChangeDetailsModal();
      },
      onError: (error) => {
        errorCallback(error);
        setNewDescription(list.description);
      }
    });
  }

  const {
    Modal: ChangeDetailsModal,
    /*showModal: showChangeDetailsModal,*/ hideModal: hideChangeDetailsModal
  } = useModal({
    body: {
      title,
      Component: ChangeDetailsForm,
      titleAlignment: 'left',
      componentProps: {
        bindSubmit: bindDetailsSubmit,
        bindIsValid: bindIsNameValid,
        onSubmit: onChangeDetailsConfirmed,
        previousName: list.name,
        t,
        newDescription,
        setNewDescription,
        existingNames
      }
    },
    btnComponent:
      modalTriggerComponent ??
      (({ onClick }) => (
        <div
          onClick={onClick}
        >
          {t('edit')}
        </div>
      )),
    ok: {
      onClick: submitDetails,
      title: t('save'),
      isDisabled: !isNameValid
    },
    cancel: {
      onClick: () => setNewDescription(list.description)
    },
    modal: {
      destroyOnClose: true
    },
    closable: false,
    maskClosable: true
  });

  return <>{ChangeDetailsModal}</>;
};

ListDetailsModal.propTypes = {
  list: PropTypes.object.isRequired,
  title: PropTypes.string,
  existingNames: PropTypes.arrayOf(PropTypes.string),
  onSubmitFn: PropTypes.func.isRequired,
  modalTriggerComponent: PropTypes.func
};

export default ListDetailsModal;
