import React from 'react';
import PropTypes from 'prop-types';

import Primary from './Primary';
import Default from './Default';
import Danger from './Danger';
import Link from './Link';
import BareLink from './BareLink';
import BareDangerLink from './BareDangerLink';
import DangerLink from './DangerLink';
import Header from './Header';
import Icon from './Icon';
import Secondary from './Secondary';
import Success from './Success';

const Buttons = {
  Primary,
  Default,
  Danger,
  Link,
  BareLink,
  BareDangerLink,
  DangerLink,
  Header,
  Icon,
  Secondary,
  Success
};

const Button = ({ type = 'secondary', rounded = false, ...rest }) => {
  const Btn = Buttons[type[0].toUpperCase() + type.slice(1)];
  const style = {};
  if (rounded) style.borderRadius = '22px';
  return <Btn {...rest} style={style} />;
};

Button.propTypes = {
  type: PropTypes.string,
  rounded: PropTypes.bool
};

export default Button;
