import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { notification, Row, Col } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Icon } from '../../../common/components';
import styles from './styles.module.scss';
import { createList, getList, acceptList, declineList, getListWithRecipes } from '../../sagas/listsSagas';
import { setAvailableLists, setActiveList, setSharedLists } from '../../reducers';
import { EditModal } from '..';

const AllLists = (props) => {
  const {
    activeList,
    sharedLists,
    createList,
    availableLists,
    setAvailableLists,
    //getList,
    getListWithRecipes,
    setActiveList,
    setSharedLists,
    existingNames,
    acceptList,
    declineList,
    setMode,
    setRelatedRecipes
  } = props;
  const { t } = useTranslation('lists');

  const onDeclineList = (list) => {
    declineList({
      listId: list._id,
      onSuccess: () => {
        if (list._id === activeList?._id) setActiveList(undefined);
        notification.info({ message: t('listDeclined') });
        const cleanedListsList = sharedLists.filter((al) => al._id !== list._id);
        setSharedLists(cleanedListsList);
      },
      onError: () => {
        notification.error({ message: t('listDecliningFailed') });
      }
    });
  };

  const onAcceptList = (list) => {
    setNewActiveList(list);
    acceptList({
      listId: list._id,
      onSuccess: () => {
        notification.info({ message: t('listAccepted') });
        setAvailableLists([list, ...availableLists]);
        const cleanedListsList = sharedLists.filter((al) => al._id !== list._id);
        setSharedLists(cleanedListsList);
      },
      onError: () => {
        notification.error({ message: t('listAcceptanceFailed') });
      }
    });
  };

  const setNewActiveList = (newList) => {
    if (!activeList || newList._id !== activeList._id) {
      getListWithRecipes({
        id: newList._id,
        onError: () => notification.error({ message: t('unableGetList') }),
        onSuccess: (data) => { setRelatedRecipes(data.recipes ?? []); setMode('items'); }
      });
    } else {
      setMode('items');
    }
  };

  const createErrorCallback = () => {
    notification.error({ message: t('listCreationFailed') });
  };

  const createSuccessCallback = (data) => {
    notification.success({ message: t('newListCreated') });
    setAvailableLists([data, ...availableLists]);
    setNewActiveList(data);
  };

  const ListItem = ({ list }) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="start"
        className={`${styles.listItemWrapper} ${list._id === activeList?._id ? styles.active : ''}`}
        onClick={() => setNewActiveList(list)}
      >
        <Col className={styles.listName} >
          {list.name}
        </Col>
        <Col className={styles.numberOfItems}>
          {list.items?.length ? list.items.filter((item) => !item.purchased).length : 0}
        </Col>
      </Row>
    );
  };

  const SharedListItem = ({ list }) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className={`${styles.listItemWrapper} ${styles.shared} ${list._id === activeList?._id ? styles.active : ''}`}
      >
        <Col className={styles.sharedListDescriptionBlock} onClick={() => setNewActiveList(list)}>
          <span className={styles.sharedListName} >
            {list.name}
          </span>
          <span className={styles.numberOfItems}>
            {list.items?.length ?? 0}
          </span>
        </Col>
        <Col className={styles.btnWrapper}>
          <Button
            type="icon"
            className={styles.acceptBtn}
            onClick={() => onAcceptList(list)}
          >
            <FontAwesomeIcon icon="check" />
          </Button>
          <Button
            type="icon"
            className={styles.declineBtn}
            onClick={() => onDeclineList(list)}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </Col>
      </Row>
    );
  };


  return (
    <div>
      <div className={styles.sectionHeader}>
        <FontAwesomeIcon icon="angle-down" />{t('myLists')}
      </div>
      {availableLists?.map((list) => <ListItem key={list._id} list={list}/>)}
      <EditModal
        list={{ name: '', description: '' }}
        title={t('createListTitle')}
        onSubmitFn={createList}
        existingNames={existingNames}
        successCallback={createSuccessCallback}
        errorCallback={createErrorCallback}
        modalTriggerComponent={({ onClick }) => (
          <div className={styles.addListBtn} onClick={onClick}>
            <Icon name="plus" />
            {t('addList')}
          </div>
        )}
      />
      {!!sharedLists?.length && <div className={styles.sectionHeader}>
        <FontAwesomeIcon icon="angle-down" />{t('sharedLists')}
      </div>}
      {sharedLists?.map((list) => <SharedListItem key={list._id} list={list}/>)}
    </div>
  );
};

AllLists.propTypes = {
  user: PropTypes.object,
  activeList: PropTypes.object,
  setActiveList: PropTypes.func.isRequired,
  sharedLists: PropTypes.arrayOf(PropTypes.object),
  availableLists: PropTypes.arrayOf(PropTypes.object),
  getList: PropTypes.func.isRequired,
  getListWithRecipes: PropTypes.func.isRequired,
  setAvailableLists: PropTypes.func.isRequired,
  createList: PropTypes.func.isRequired,
  acceptList: PropTypes.func.isRequired,
  declineList: PropTypes.func.isRequired,
  existingNames: PropTypes.arrayOf(PropTypes.string),
  setMode: PropTypes.func.isRequired,
  setRelatedRecipes: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user }, lists: { activeList, availableLists, sharedLists } }) => ({
      user, activeList, availableLists, sharedLists
    }),
    {
      createList,
      setAvailableLists,
      getList,
      getListWithRecipes,
      setActiveList,
      acceptList,
      declineList,
      setSharedLists
    }
  )
)(AllLists);
