import React from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../index';

const ActionDropdown = ({ children, trigger, triggerId, listPlacement, triggerElement, disabled }) => (
  <div className="action-dropdown" /*onClick={e => e.stopPropagation()}*/>
    <Dropdown
      overlay={children}
      trigger={[trigger || 'click']}
      placement={listPlacement || 'bottomLeft'}
      disabled={disabled}
    >
      {triggerElement || (
        <Button type="icon" id={triggerId} rounded>
          <FontAwesomeIcon icon="ellipsis-h" />
        </Button>
      )}
    </Dropdown>
  </div>
);

ActionDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.string,
  triggerId: PropTypes.string,
  visible: PropTypes.bool,
  triggerElement: PropTypes.node
};

export default ActionDropdown;
