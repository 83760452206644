import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, notification, Input, Menu, Pagination } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import queryString from 'query-string';
import styles from './styles.module.scss';
import {
  getRecipes,
  getSuggestedRecipes,
  getRecipesWithDebounce,
  getSuggestedRecipesWithDebounce,
  acceptRecipe,
  declineRecipe,
  registerUrlClick,
  deleteRecipe
} from '../../sagas';
import { setRecipe } from '../../reducers';
import { ActionDropdown, Button, Icon, Spinner, DeleteEntityModal, Footer } from '../../../common/components';
import { ImportIngredientsModal } from '../../components';
//import history from '../../../utils/history';

//const { Search } = Input;

const Recipes = ({
  getRecipes,
  getSuggestedRecipes,
  getRecipesWithDebounce,
  getSuggestedRecipesWithDebounce,
  recipes,
  suggestedRecipes,
  setRecipe,
  acceptRecipe,
  declineRecipe,
  location,
  registerUrlClick,
  deleteRecipe
}) => {
  const { t } = useTranslation('recipe');
  const PAGE_SIZE = 25;
  const [isFiltered, setIsFiltered] = useState(false);
  const [mode, setMode] = useState('myRecipes');
  const [query, setQuery] = useState('');

  //const [page, setPage] = useState(1);

  useEffect(() => {
    const { switchToMode } = queryString.parse(location.search);
    if (switchToMode === 'myRecipes') {
      window.history.replaceState({}, document.title, '/recipes');
      setMode('myRecipes');
    } else if (switchToMode === 'suggestedRecipes') {
      window.history.replaceState({}, document.title, '/recipes');
      setMode('suggestedRecipes');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setRecipe(undefined);
    setQuery('');
    setIsFiltered(false);
    switch (mode) {
      case 'myRecipes':
        getRecipes({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          }
        });
        break;
      case 'suggestedRecipes':
        getSuggestedRecipes({
          limit: PAGE_SIZE,
          onError: () => {
            notification.error({ message: t('unableGetSuggestedRecipes') });
          }
        });
        break;
      default:
        getRecipes({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          }
        });
        break;
    }
  }, [mode]); //eslint-disable-line

  const onQueryChange = (query) => {
    setQuery(query);
    setIsFiltered(!!(query?.trim?.()?.length));
    switch (mode) {
      case 'myRecipes':
        getRecipesWithDebounce({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          },
          query
        });
        break;
      case 'suggestedRecipes':
        getSuggestedRecipesWithDebounce({
          limit: PAGE_SIZE,
          onError: () => {
            notification.error({ message: t('unableGetSuggestedRecipes') });
          },
          query
        });
        break;
      default:
        getRecipesWithDebounce({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          },
          query
        });
        break;
    }
  };

  const onSearch = (query) => {
    switch (mode) {
      case 'myRecipes':
        getRecipes({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          },
          query
        });
        break;
      case 'suggestedRecipes':
        getSuggestedRecipes({
          limit: PAGE_SIZE,
          onError: () => {
            notification.error({ message: t('unableGetSuggestedRecipes') });
          },
          query
        });
        break;
      default:
        getRecipes({
          limit: PAGE_SIZE,
          offset: 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          },
          query
        });
        break;
    }
  };

  const getGlobalRating = (recipe) => {
    const sum = recipe.rating?.reduce?.((acc, r) => (r.score + acc), 0) ?? 0;
    const votes = recipe.rating?.length ?? 0;
    return votes > 0 ? +(sum / votes).toFixed(2) : 0;
  };

  const onChangePage = (value) => {
    getRecipes({
      limit: PAGE_SIZE,
      offset: (value - 1) * PAGE_SIZE,
      onError: () => {
        notification.error({ message: t('unableGetRecipes') });
      }
    });
  };

  const reloadSuggestedRecipesList = () => {
    setQuery('');
    setIsFiltered(false);
    getSuggestedRecipes({
      limit: PAGE_SIZE,
      onError: () => notification.error({ message: t('unableGetSuggestedRecipes') })
    });
  };

  const doAcceptRecipe = (recipe) => {
    acceptRecipe({
      recipeId: recipe._id,
      onSuccess: reloadSuggestedRecipesList,
      onError: () => notification.error({ message: t('unableAcceptRecipe') })
    });
  };

  const doDeclineRecipe = (recipe) => {
    declineRecipe({
      recipeId: recipe._id,
      onSuccess: reloadSuggestedRecipesList,
      onError: () => notification.error({ message: t('unableDeclineRecipe') })
    });
  };

  const onDeleteRecipe = (recipe) => {
    deleteRecipe({
      recipeId: recipe._id,
      onSuccess: () => {
        notification.info({ message: t('recipeDeleted') });
        getRecipes({
          limit: PAGE_SIZE,
          offset: recipes?.offset ?? 0,
          onError: () => {
            notification.error({ message: t('unableGetRecipes') });
          },
          query
        });
      },
      onError: () => {
        notification.error({ message: t('recipeDeletionFailed') });
      }
    });
  };

  const onUrlClick = (id) => {
    registerUrlClick({ id });
  };

  return (
    <>
      {(!recipes && mode === 'myRecipes') || (!suggestedRecipes && mode === 'suggestedRecipes')
        ? (<Spinner className={styles.spinner} size="large" />)
        : (
          <>
            <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
              <Col className={styles.pageContent}>
                <Row type="flex" align="top" justify="start" className={styles.sectionHeader}>
                  <Col
                    className={`${styles.option}
                     ${mode === 'myRecipes' ? styles.active : ''}`}
                    onClick={() => setMode('myRecipes')}
                  >
                    {t('myRecipes')}
                  </Col>
                  <Col
                    className={`${styles.option}
                     ${mode === 'suggestedRecipes' ? styles.active : ''}`}
                    onClick={() => setMode('suggestedRecipes')}
                  >
                    {t('suggestedRecipes')}
                  </Col>
                </Row>
                <div className={styles.searchWrapper}>
                  <Input
                    placeholder={t('searchRecipes')}
                    onChange={(event) => onQueryChange(event.target.value)}
                    value={query}
                    onPressEnter={onSearch}
                    className={styles.filterInput}
                    prefix={<Icon name="search" onClick={onSearch}/>}
                  />
                </div>
                <Row className={styles.imagesContainer} type="flex" align="top" justify="space-around">
                  {(mode === 'myRecipes' ? recipes.entities : suggestedRecipes)?.length
                    ? (mode === 'myRecipes' ? recipes.entities : suggestedRecipes).map?.((recipe) => {
                      return (
                      <Col key={recipe._id} className={styles.cardContainer}
                      >
                        {!!(recipe.isNew && mode === 'suggestedRecipes') && (
                          <Link className={styles.newRecipeLabel} to={`/recipes/${recipe._id}`}>{t('newRecipeLabel')}</Link>
                        )}
                        <Link className={styles.imageWrapper} to={`/recipes/${recipe._id}`}>
                          <div
                            className={styles.image}
                            style={{ backgroundImage: recipe.image.indexOf('url(') === 0
                              ? recipe.image
                              : `url('${recipe.image}')`
                            }}
                          />
                        </Link>
                        <div className={styles.recipeDescriptionArea}>
                          <Row type="flex" align="top" justify="space-between">
                            <Col className={styles.recipeTitle}>
                              <Link to={`/recipes/${recipe._id}`}>{recipe.title}</Link>
                            </Col>
                            <Col>
                              {mode === 'myRecipes' ? (
                                <ActionDropdown
                                  triggerElement={(
                                    <Button type="icon" className={styles.actionDropdownBtn}>
                                      <Icon name="more-vert" />
                                    </Button>
                                  )}
                                  listPlacement="bottomRight"
                                >
                                  <Menu className="template-action-menu">
                                    <Menu.Item key={-400}>
                                      <ImportIngredientsModal
                                        recipe={recipe}
                                        modalTriggerComponent={({ onClick }) => (
                                          <div onClick={onClick}>
                                            <Icon name="dish" className={styles.menuIcon}/>
                                            {t('addAllToShoppingList')}
                                          </div>
                                        )}
                                      />
                                    </Menu.Item>
                                    <Menu.Item key={-401}>
                                      <DeleteEntityModal
                                        entity={recipe}
                                        entityName={t('recipe')}
                                        deleteFn={onDeleteRecipe}
                                        modalTriggerComponent={({ onClick }) => (
                                          <div onClick={onClick}>
                                            <Icon name="trash" className={styles.menuIcon}/>
                                            {t('deleteRecipe')}
                                          </div>
                                        )}
                                      />
                                    </Menu.Item>
                                  </Menu>
                                </ActionDropdown>
                              ) : (
                                <ActionDropdown
                                  triggerElement={(
                                    <Button type="icon" className={styles.actionDropdownBtn}>
                                      <Icon name="more-vert" />
                                    </Button>
                                  )}
                                  listPlacement="bottomRight"
                                >
                                  <Menu className="template-action-menu">
                                    <Menu.Item key={-402}>
                                      <ImportIngredientsModal
                                        recipe={recipe}
                                        customSuccessCallback={() => getSuggestedRecipes({
                                          limit: PAGE_SIZE,
                                          onError: () => notification.error({ message: t('unableGetSuggestedRecipes') })
                                        })}
                                        modalTriggerComponent={({ onClick }) => (
                                          <div onClick={onClick}>
                                            <Icon name="dish" className={styles.menuIcon}/>
                                            {t('addAllToShoppingList')}
                                          </div>
                                        )}
                                      />
                                    </Menu.Item>
                                    <Menu.Item key={-404}>
                                      <div onClick={() => doDeclineRecipe(recipe)}>
                                        <Icon name="trash" className={styles.menuIcon}/>
                                        {t('declineRecipe')}
                                      </div>
                                    </Menu.Item>
                                    <Menu.Item key={-405}>
                                      <div onClick={() => doAcceptRecipe(recipe)}>
                                        <Icon name="check" className={styles.menuIcon}/>
                                        {t('acceptRecipe')}
                                      </div>
                                    </Menu.Item>
                                  </Menu>
                                </ActionDropdown>
                              )}

                            </Col>
                          </Row>
                          <Row>
                            <div className={styles.globalStars}>
                              <StarRatings
                                rating={getGlobalRating(recipe)}
                                starRatedColor="#FFBD00"
                                starHoverColor="#FFBD00"
                                starEmptyColor="#9C9BA2"
                                numberOfStars={5}
                                name="averageRating"
                                starDimension="18px"
                                starSpacing="2px"
                              />
                            </div>
                          </Row>
                          {recipe.referenceUrl && <Row className={styles.recipesRef}>
                            <a href={recipe.referenceUrl} onMouseDown={() => onUrlClick(recipe._id)}>
                              {recipe.referenceAlias
                                ?? (recipe.referenceUrl.length < recipe.title.length ? recipe.referenceUrl : recipe.title)
                              }
                            </a>
                          </Row>}
                        </div>
                      </Col>
                      );
                    }) : (<div className={styles.nothingFound}>
                    { isFiltered ? (
                      <div className={styles.title}>{t('noRecipesFound')}</div>
                    ) : (
                      <>
                        {mode === 'myRecipes' ? (
                          <>
                            <div className={styles.title}>{t('keepRecipesInOnePlace')}</div>
                            <div className={styles.description}>{t('importRecipes')}</div>
                          </>
                        ) : (
                          <>
                            <div className={styles.title}>{t('noSuggestedRecipes')}</div>
                            <div className={styles.description}>{t('mayAppearLater')}</div>
                          </>
                        )}
                      </>
                    ) }
                  </div>)}
                </Row>
              </Col>

              {mode !== 'suggestedLists' && (
                <Row className={styles.paginationWrapper} type="flex" align="top" justify="center">
                  <Pagination
                    defaultPageSize={PAGE_SIZE}
                    pageSize={PAGE_SIZE}
                    total={recipes?.total || 0}
                    hideOnSinglePage
                    onChange={onChangePage}
                    current={1 + Math.floor((recipes?.offset || 0) / PAGE_SIZE)}
                    responsive
                  />
                </Row>
              )}
            </Row>
          </>
        )}
        <Footer />
    </>
  );
};

Recipes.propTypes = {
  getRecipes: PropTypes.func.isRequired,
  getSuggestedRecipes: PropTypes.func.isRequired,
  getRecipesWithDebounce: PropTypes.func.isRequired,
  getSuggestedRecipesWithDebounce: PropTypes.func.isRequired,
  recipes: PropTypes.object,
  suggestedRecipes: PropTypes.arrayOf(PropTypes.object),
  acceptRecipe: PropTypes.func.isRequired,
  declineRecipe: PropTypes.func.isRequired,
  registerUrlClick: PropTypes.func.isRequired,
  deleteRecipe: PropTypes.func.isRequired
};

export default compose(
  connect(({ recipe: { recipes, suggestedRecipes } }) => ({ recipes, suggestedRecipes }), {
    getRecipes,
    getRecipesWithDebounce,
    setRecipe,
    getSuggestedRecipes,
    getSuggestedRecipesWithDebounce,
    acceptRecipe,
    declineRecipe,
    registerUrlClick,
    deleteRecipe
  }),
  withRouter
)(Recipes);
