import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { withFormik } from 'formik';
import { object, ref, string } from 'yup';
import { FieldAdapter as Field, RenderField } from '../../../common/components';
import styles from './styles.module.scss';

const ChangePasswordForm = ({
  bindSubmit,
  bindIsValid,
  submitForm,
  isValid,
  values: { password, newPassword, passwordConfirmation },
  handleSubmit
}) => {
  const { t } = useTranslation('account');
  bindSubmit(submitForm);
  bindIsValid(isValid);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  return (
    <Form onSubmit={handleSubmit}>
        <div className={styles.title}>{t('currentPasswordLabel')}</div>
      <Field
        size="large"
        name="password"
        component={RenderField}
        type={isShowPassword ? 'text' : 'password'}
        // label={t('currentPasswordLabel')}
        value={password}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowPassword(!isShowPassword)}
            />
          )
        }}
      />
        <div className={styles.title}>{t('newPasswordLabel')}</div>
      <Field
        size="large"
        name="newPassword"
        component={RenderField}
        type={isShowNewPassword ? 'text' : 'password'}
        // label={t('newPasswordLabel')}
        value={newPassword}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowNewPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowNewPassword(!isShowNewPassword)}
            />
          )
        }}
      />
        <div className={styles.title}>{t('repeatPasswordLabel')}</div>
      <Field
        size="large"
        name="passwordConfirmation"
        component={RenderField}
        type={isShowConfirmPassword ? 'text' : 'password'}
        // label={t('repeatPasswordLabel')}
        value={passwordConfirmation}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowConfirmPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
            />
          )
        }}
      />
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  bindSubmit: PropTypes.func.isRequired,
  bindIsValid: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    password: string()
      .required(t('enterCurrentPassword')),
    newPassword: string()
      .required(t('enterNewPassword')),
    passwordConfirmation: string()
      .oneOf([ref('newPassword')], t('passwordMustMatch'))
      .required(t('confirmPassword'))
  });
};

const changePasswordFormWithFormik = withFormik({
  enableReinitialize: true,
  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
  validationSchema: ({ t }) => getValidationSchema(t),
  displayName: 'ChangePasswordForm' // helps with React DevTools
});

export default changePasswordFormWithFormik(ChangePasswordForm);
