import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Row, Col, InputNumber } from 'antd';

import styles from './styles.module.scss';
import './styles.global.scss';
import {
  getSuggestedItems,
  getSuggestedUnits,
  addItemToList,
  updateItemInList,
  findUSDAProducts,
  getUSDAProduct,
  addSuggestedItem
} from '../../sagas/listsSagas';
import { Button, Icon } from '../../../common/components';
import { withDimensions } from '../../../common/HOC';
import { addCategoryForUnknownItem } from '../../../../utils/addCategoryForUnknownItem';

const { Option } = AutoComplete;

const ItemEditor = (props) => {
  const {
    item,
    creating,
    setCreating,
    isAvailable,
    setItemUnderEditing,

    activeList,
    getSuggestedItems,
    getSuggestedUnits, //eslint-disable-line
    addItemToList,
    //categories,
    //recipes,
    updateItemInList,
    dimensions,
    findUSDAProducts,
    getUSDAProduct,
    addSuggestedItem
  } = props;
  const { t } = useTranslation('lists');
  const defaultItem = {
    name: '',
    amount: 0,
    unit: undefined,
    recipe: undefined,
    category: undefined
  };

  const [suggestedItems, setSuggestedItems] = useState([]);
  const [suggestedUnits, setSuggestedUnits] = useState([]);
  const [newItemName, setNewItemName] = useState('');
  const [newUnitName, setNewUnitName] = useState('');
  const [newAmount, setNewAmount] = useState(0);
  const [itemUnderConstruction, setItemUnderConstruction] = useState(item ?? defaultItem); //eslint-disable-line
  const [nameEnterDelay, setNameEnterDelay] = useState(undefined);
  const [unitEnterDelay, setUnitEnterDelay] = useState(undefined);

  useEffect(() => {
    return () => {
      if (nameEnterDelay) clearTimeout(nameEnterDelay);
      if (unitEnterDelay) clearTimeout(unitEnterDelay);
    };
  }); //eslint-disable-line

  useEffect(() => {
    if (item) {
      setItemUnderConstruction(item);
      setNewAmount(item.amount || 0);
      setNewItemName(item.name || '');
      setNewUnitName(item.unit?.name || '');
    }
  }, [item]); //eslint-disable-line

  const onNewNameFocus = (e) => {
    onNewNameChange(e.target.value || '');
  };

  const onNewNameChange = (value) => {
    setSuggestedItems([]);
    setNewItemName(value);
    const updatedItem = Object.assign({}, itemUnderConstruction);
    getSuggestedItems({
      query: value,
      onSuccess: (data) => {
        const exactMatch = data.find((item) => item.name.toLowerCase() === value.toLowerCase());
        if (exactMatch) {
          const others = data.filter((item) => item._id !== exactMatch._id);
          exactMatch.isNew = false;
          setSuggestedItems([exactMatch, ...others]);
          updatedItem.name = exactMatch.name;
          updatedItem.category = exactMatch.category;
          updatedItem.isNew = false;
        } else if (value.trim() === '') {
          setSuggestedItems([...data]);
          updatedItem.name = undefined;
          updatedItem.category = undefined;
          updatedItem.isNew = undefined;
        } else {
          const brandNew = { name: value.trim(), isNew: true };
          setSuggestedItems([brandNew, ...data]);
          updatedItem.name = value.trim();
          updatedItem.category = undefined;
          updatedItem.isNew = true;
        }
        setItemUnderConstruction(updatedItem);
      }
    });
  };

  const onNewNameKeyDown = (e) => {
    const valueNow = e.target?.value;
    if (e.keyCode === 13) {
      if (!suggestedItems.length) {
        processCurrentName();
      } else {
        if (nameEnterDelay) clearTimeout(nameEnterDelay);
        setNameEnterDelay(setTimeout(() => {
          const valueAfterTimeout = e.target?.value;
          if (suggestedItems.length && valueAfterTimeout && valueAfterTimeout === valueNow) {
            const updatedItem = Object.assign({}, itemUnderConstruction);
            updatedItem.name = suggestedItems[0].name;
            updatedItem.category = suggestedItems[0].category;
            setItemUnderConstruction(updatedItem);
            setNewItemName(suggestedItems[0].name);
          }
        }, 100));
      }
    }
  };

  const processCurrentName = () => {
    const currentName = newItemName;

    getSuggestedItems({
      query: currentName,
      onSuccess: (data) => {
        const exactMatch = data.find((item) => item.name.toLowerCase() === currentName.toLowerCase());
        const updatedItem = Object.assign({}, itemUnderConstruction);
        if (exactMatch) {
          updatedItem.name = exactMatch.name;
          updatedItem.category = exactMatch.category;
          updatedItem.isNew = false;
        } else {
          updatedItem.name = currentName;
          updatedItem.category = null;
          updatedItem.isNew = true;
        }
        setItemUnderConstruction(updatedItem);
        setNewItemName(currentName);
      },
      onError: () => setCreating(false)
    });
  };

  const onNewUnitFocus = (e) => {
    onNewUnitChange(e.target.value || '');
  };

  const onNewUnitChange = (value) => {
    setSuggestedUnits([]);
    setNewUnitName(value);
    const updatedItem = Object.assign({}, itemUnderConstruction);
    getSuggestedUnits({
      query: value,
      onSuccess: (data) => {
        const exactMatch = data.find((item) =>
          item.name.toLowerCase() === value.toLowerCase() || item.pluralName.toLowerCase() === value.toLowerCase());
        if (exactMatch) {
          const others = data.filter((item) => item._id !== exactMatch._id);
          setSuggestedUnits([exactMatch, ...others]);
          updatedItem.unit = exactMatch;
        } else if (value.trim() === '') {
          setSuggestedUnits([undefined, ...data]);
          updatedItem.unit = undefined;
        } else {
          const brandNew = { name: value.trim() };
          setSuggestedUnits([brandNew, ...data]);
          updatedItem.unit = brandNew;
        }
        setItemUnderConstruction(updatedItem);
      }
    });
  };

  const onNewUnitKeyDown = (e) => {
    if (e.keyCode === 13) {
      const valueNow = e.target?.value;
      if (!suggestedUnits.length) {
        processCurrentUnit();
      } else {
        if (unitEnterDelay) clearTimeout(unitEnterDelay);
        setUnitEnterDelay(setTimeout(() => {
          const valueAfterTimeout = e.target?.value;
          if (suggestedUnits.length && valueAfterTimeout && valueAfterTimeout === valueNow) {
            const updatedItem = Object.assign({}, itemUnderConstruction);
            if (suggestedUnits[0]) {
              updatedItem.unit = { name: suggestedUnits[0].name, pluralName: suggestedUnits[0].pluralName };
            } else {
              updatedItem.unit = undefined;
            }

            setItemUnderConstruction(updatedItem);
            setNewUnitName(suggestedUnits[0]?.name || '');
          }
        }, 100));
      }
    }
  };

  const processCurrentUnit = () => {
    const currentUnit = newUnitName;

    getSuggestedUnits({
      query: currentUnit,
      onSuccess: (data) => {
        const exactMatch = data.find((item) => item.name.toLowerCase() === currentUnit.toLowerCase()
          || item.pluralName.toLowerCase() === currentUnit.toLowerCase());
        const updatedItem = Object.assign({}, itemUnderConstruction);
        if (exactMatch) {
          updatedItem.unit = { name: exactMatch.name, pluralName: exactMatch.pluralName };
        } else if (currentUnit?.trim().length) {
          updatedItem.unit = { name: currentUnit };
        } else {
          updatedItem.unit = undefined;
        }
        setItemUnderConstruction(updatedItem);
        setNewUnitName(currentUnit?.trim().length ? currentUnit : '');
      }
    });
  };

  const onNewAmountChange = (value) => {
    const newValue = (value && !Number.isNaN(value) && value >= 0) ? value : 0;
    setNewAmount(newValue);
    const updatedItem = Object.assign({}, itemUnderConstruction);
    updatedItem.amount = newValue;
    setItemUnderConstruction(updatedItem);
  };

  const onNewItemSelected = (value) => {
    const selectedItem = suggestedItems.find((item) => item.name === value);
    if (selectedItem) {
      const updatedItem = Object.assign({}, itemUnderConstruction);
      updatedItem.name = selectedItem.name;
      updatedItem.category = selectedItem.category;
      updatedItem.isNew = selectedItem.isNew;
      setItemUnderConstruction(updatedItem);
      setNewItemName(value);
    }
  };

  const onNewUnitSelected = (value) => {
    const selectedUnit = suggestedUnits.find((unit) => unit?.name === value);
    const updatedItem = Object.assign({}, itemUnderConstruction);
    if (selectedUnit) {
      updatedItem.unit = selectedUnit;
      setNewUnitName(value);
    } else {
      updatedItem.unit = undefined;
      setNewUnitName('');
    }
    setItemUnderConstruction(updatedItem);
  };

  const abortEditing = () => {
    setItemUnderEditing(undefined);
  };

  const updateItem = () => {
    setCreating(true);
    const updatedItem = Object.assign({}, itemUnderConstruction);
    if (suggestedItems.length && !updatedItem.name) {
      updatedItem.name = suggestedItems[0].name;
      updatedItem.category = suggestedItems[0].category;
      updatedItem.isNew = suggestedItems[0].isNew;
    }

    const proceed = (itemData) => {
      const options = {
        listId: activeList._id,
        itemData,
        onSuccess: () => {
          setCreating(false);
          setItemUnderEditing(undefined);
        },
        onError: () => {
          setCreating(false);
        }
      };
      if (itemUnderConstruction._id) {
        updateItemInList(options);
      } else {
        addItemToList(options);
      }
    };

    //Adding new items to suggested items list
    if (!!updatedItem.isNew && updatedItem.name) {
      addCategoryForUnknownItem(updatedItem, proceed, findUSDAProducts, getUSDAProduct, addSuggestedItem);
    } else {
      proceed(updatedItem);
    }
  };

  return (
    <>
      {dimensions?.isScreenBiggerMD && !(dimensions.isScreenBiggerLG && dimensions.isScreenLessXL) ? (
        <Row className={`${styles.itemEditorWrapper} ItemEditorWrapper`}>
          <Col className={styles.itemSelector}>
            <AutoComplete
              className={styles.input}
              onSearch={onNewNameChange}
              onFocus={onNewNameFocus}
              placeholder={t('itemNamePlaceholder')}
              onSelect={onNewItemSelected}
              value={newItemName}
              onInputKeyDown={onNewNameKeyDown}
              disabled={creating || !isAvailable}
            >
              {suggestedItems.map((item) => (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </AutoComplete>
          </Col>
          <Col className={styles.amountInput}>
            <InputNumber
              className={styles.input}
              value={newAmount}
              disabled={creating || !isAvailable}
              min={0}
              decimalSeparator="."
              onChange={onNewAmountChange}
            />
          </Col>
          <Col className={styles.unitSelector}>
            <AutoComplete
              className={styles.input}
              onSearch={onNewUnitChange}
              onFocus={onNewUnitFocus}
              placeholder={t('unitPlaceholder')}
              onSelect={onNewUnitSelected}
              onInputKeyDown={onNewUnitKeyDown}
              value={newUnitName}
              disabled={creating || !isAvailable}
            >
              {suggestedUnits.map((unit) => (
                <Option key={unit?.name ?? 'undefined'} value={unit?.name || undefined}>
                  {unit?.name ?? (<i>undefined</i>)}
                </Option>
              ))}
            </AutoComplete>
          </Col>
          <Row type="flex" justify="space-around" align="middle" className={styles.controlsWrapper} >
            <Button type="icon" onClick={updateItem} disabled={creating || !isAvailable} >
              <Icon name="golden-check" />
            </Button>
            <Button type="icon" onClick={abortEditing} disabled={creating} >
              <Icon name="close" />
            </Button>
          </Row>
        </Row>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            className={`${styles.itemEditorWrapper} ${styles.mobile} ItemEditorWrapper`}
          >
            <Col className={styles.itemSelector}>
              <AutoComplete
                className={styles.input}
                onSearch={onNewNameChange}
                onFocus={onNewNameFocus}
                placeholder={t('itemNamePlaceholder')}
                onSelect={onNewItemSelected}
                onInputKeyDown={onNewNameKeyDown}
                value={newItemName}
                disabled={creating || !isAvailable}
              >
                {suggestedItems.map((item) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </AutoComplete>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            className={`${styles.itemEditorWrapper} ${styles.mobile} ItemEditorWrapper`}
          >
            <Col className={styles.amountInput}>
              <InputNumber
                className={styles.input}
                value={newAmount}
                disabled={creating || !isAvailable}
                min={0}
                decimalSeparator="."
                onChange={onNewAmountChange}
              />
            </Col>
            <Col className={styles.unitSelector}>
              <AutoComplete
                className={styles.input}
                onSearch={onNewUnitChange}
                onFocus={onNewUnitFocus}
                placeholder={t('unitPlaceholder')}
                onSelect={onNewUnitSelected}
                onInputKeyDown={onNewUnitKeyDown}
                value={newUnitName}
                disabled={creating || !isAvailable}
              >
                {suggestedUnits.map((unit) => (
                  <Option key={unit.name} value={unit.name}>
                    {unit.name}
                  </Option>
                ))}
              </AutoComplete>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            className={`${styles.itemEditorWrapper} ${styles.mobile} ${styles.noBorders} ItemEditorWrapper`}>
            <Row type="flex" justify="space-around" align="middle" className={styles.controlsWrapper} >
              <Button type="icon" onClick={updateItem} disabled={creating || !isAvailable} >
                <Icon name="golden-check" />
              </Button>
              <Button type="icon" onClick={abortEditing} disabled={creating} >
                <Icon name="close" />
              </Button>
            </Row>
          </Row>
        </>
      ) }
    </>


  );
};

ItemEditor.propTypes = {
  item: PropTypes.object,
  creating: PropTypes.bool,
  setCreating: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool,
  setItemUnderEditing: PropTypes.func.isRequired,
  activeList: PropTypes.object,
  /*categories: PropTypes.arrayOf(PropTypes.object),
  recipes: PropTypes.arrayOf(PropTypes.object),*/
  getSuggestedItems: PropTypes.func.isRequired,
  getSuggestedUnits: PropTypes.func.isRequired,
  addItemToList: PropTypes.func.isRequired,
  updateItemInList: PropTypes.func.isRequired,
  findUSDAProducts: PropTypes.func.isRequired,
  getUSDAProduct: PropTypes.func.isRequired,
  addSuggestedItem: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ lists: { activeList/*, categories*/ }, dimensions/*, recipe: { recipes }*/ }) => ({
      activeList, dimensions/*, categories, recipes*/
    }),
    {
      getSuggestedItems,
      getSuggestedUnits,
      addItemToList,
      updateItemInList,
      findUSDAProducts,
      getUSDAProduct,
      addSuggestedItem
    }
  ),
  withDimensions
)(ItemEditor);
