import React from 'react';
import { AuthRoute } from '../auth/components';

import { Lists } from './pages';
import locales from './locales';
import sagas from './sagas';
import reducers from './reducers';

export default {
  routes: [<AuthRoute key="lists" exact path="/lists" component={Lists} />],
  locales: { ns: 'lists', resources: locales },
  menuItems: [
    {
      name: 'lists',
      path: '/lists',
      width: 53
    }
  ],
  sagas,
  reducers: { lists: reducers }
};
