import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Checkbox, notification } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import ReactMarkdown from 'react-markdown/with-html';

import styles from './styles.module.scss';
import { Button, FieldAdapter as Field, RenderField } from '../../../common/components';
//import history from '../../../../utils/history';
import { withDimensions } from '../../../common/HOC';
import { createBlogger } from '../../../user/sagas';

const BloggerSignUp = ({
  dimensions,
  handleSubmit,
  isValid,
  values: { firstName, lastName, email, blogUrl }
}) => {
  const { t } = useTranslation('landing');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const formTitle = useRef(null);
  const titleImageWrapper = useRef(null);

  const onChecked = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const scrollToForm = () => {
    const position = formTitle?.current?.getBoundingClientRect?.()?.top;
    if (position) window.scrollTo({ top: position - 100, behavior: 'smooth' });
  };

  const renderForm = () => (
    <Form name="bloggerSignUp" onSubmit={handleSubmit} className={styles.form}>
      <span className={styles.label}>{t('firstName')}:</span>
      <Field
        size="large"
        name="firstName"
        component={RenderField}
        type="text"
        value={firstName}
      />
      <span className={styles.label}>{t('lastName')}:</span>
      <Field
        size="large"
        name="lastName"
        component={RenderField}
        type="text"
        value={lastName}
      />
      <span className={styles.label}>{t('email')}:</span>
      <Field
        size="large"
        name="email"
        component={RenderField}
        type="text"
        value={email}
      />
      <span className={styles.label}>{t('bloggerUrl')}:</span>
      <Field
        size="large"
        name="blogUrl"
        component={RenderField}
        type="text"
        value={blogUrl}
      />
      <Checkbox onChange={onChecked} checked={isTermsAccepted} className={`not-rounded ${styles.iAgree}`}>
        {t('iAgree')}
      </Checkbox>
      <Button
        disabled={!isValid || !isTermsAccepted}
        type="primary"
        htmlType="submit"
        className={`${styles.fullWidthButton} ${dimensions?.isScreenBiggerLG ? styles.bigBtn : ''}`}
        onClick={handleSubmit}
      >
        {t('submit')}
      </Button>
    </Form>
  );

  return (
    <div className={`${styles.pageWrapper} ${dimensions?.isScreenBiggerLG ? '' : styles.mobile}`}>
      {dimensions?.isScreenBiggerLG ? (
        <>
          <Row type="flex" justify="center" align="top">
            <div
              className={styles.imageWrapper}
              ref={titleImageWrapper}
              style={{
                height: titleImageWrapper.current
                  ? `${Math.round(titleImageWrapper.current.offsetWidth * 0.8419)}px`
                  : '400px'
              }}
            >
              <img src="/foodBlogger.svg" alt="lemon pie" />
              <div className={styles.mainTextBlock}>
                <p className={styles.mainTitle}>{t('moreTraffic')}</p>
                <p className={styles.mainDescription}>
                  <ReactMarkdown
                    source={t('returningUsersBackToYourSite')}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                  />
                </p>
                <Button
                  type="primary"
                  className={styles.bigBtn}
                  onClick={scrollToForm}
                >
                  {t('joinZestary')}
                </Button>
              </div>
            </div>
          </Row>
          <Row type="flex" justify="center" align="middle" className={styles.bottomBlock}>
            <Col>
              <div className={styles.descriptionBlock}>
                <p className={styles.subTitle}>{t('moreRevenue')}</p>
                <p className={styles.description}>{t('additionalPathToRevenue')}</p>
              </div>
              <div className={styles.descriptionBlock}>
                <p className={styles.subTitle}>{t('customerSatisfaction')}</p>
                <p className={styles.description}>{t('easierConvertIngredientsToShoppingList')}</p>
              </div>
            </Col>
            <Col className={styles.formWrapper}>
              <div className={styles.formHeader} ref={formTitle}>{t('joinZestary')}</div>
              {renderForm()}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row type="flex" justify="center" align="top">
            <div className={styles.mainTextBlock}>
              <p className={styles.mainTitle}>{t('moreTraffic')}</p>
              <p className={styles.mainDescription}>
                <ReactMarkdown
                  source={t('returningUsersBackToYourSite')}
                  disallowedTypes={['paragraph']}
                  unwrapDisallowed
                />
              </p>
              <Button
                type="primary"
                onClick={scrollToForm}
              >
                {t('joinZestary')}
              </Button>
            </div>
          </Row>
          <Row type="flex" justify="center" align="top">
            <div
              className={styles.imageWrapper}
              ref={titleImageWrapper}
              style={{
                height: titleImageWrapper.current
                  ? `${Math.round(titleImageWrapper.current.offsetWidth * 0.8907)}px`
                  : '334px'
              }}
            >
              <img src="/foodBloggerM.svg" alt="lemon pie" />
            </div>
          </Row>
          <Row type="flex" justify="center" align="top">
            <div className={styles.descriptionBlock}>
              <p className={styles.subTitle}>{t('moreRevenue')}</p>
              <p className={styles.description}>{t('additionalPathToRevenue')}</p>
            </div>
          </Row>
          <Row type="flex" justify="center" align="top">
            <div className={styles.descriptionBlock}>
              <p className={styles.subTitle}>{t('customerSatisfaction')}</p>
              <p className={styles.description}>{t('easierConvertIngredientsToShoppingList')}</p>
            </div>
          </Row>
          <Row type="flex" justify="center" align="top">
            <div className={styles.formWrapper}>
              <div className={styles.formHeader} ref={formTitle}>{t('joinZestary')}</div>
              {renderForm()}
            </div>
          </Row>
        </>
      )}
    </div>

  );
};

BloggerSignUp.propTypes = {
  dimensions: PropTypes.object.isRequired,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  setValues: PropTypes.func,
  createBlogger: PropTypes.func.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    firstName: string()
      .max(40, t('enterMaxName'))
      .required(t('enterFirstName')),
    lastName: string()
      .max(40, t('enterMaxName')),
    email: string()
      .email(t('invalidEmail'))
      .required(t('enterEmail')),
    blogUrl: string()
      .required(t('enterBloggerURL'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('landing');
  const validationSchema = getValidationSchema(t);
  const onSubmit = (values, { resetForm }) => {
    props.createBlogger({
      user: values,
      onSuccess: () => {
        notification.success({ message: 'Request sent. We will contact you shortly.' });
        resetForm();
      },
      onError: () => {
        notification.error({ message: 'Onboarding request failed.' });
      }
    });
  };
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount={true}
      displayName="BloggerSignUpForm"
      onSubmit={onSubmit}
      initialValues={{ firstName: '', lastName: '', email: '', blogUrl: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    { createBlogger }
  ),
  withDimensions,
  withFormik
)(BloggerSignUp);
