import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { notification } from 'antd';

import history from '../../../utils/history';
import config from '../../../config';
import { actionTypes as userActionType } from '../reducers';
import { isFunc } from '../../../utils/functions';

const Api = {
  login: ({ loginData, listId }) => axios.post(`/login${listId ? `?list=${listId}` : ''}`, loginData),
  user: () => axios.get('/me'),
  createUser: ({ userData, listId }) => axios.post(`/signup${listId ? `?list=${listId}` : ''}`, userData),
  createBlogger: ({ userData }) => axios.post('/blogger-signup', userData),
  updateUser: ({ userData }) => axios.patch('/user', userData),
  //deleteUser: () => axios.delete('/user/spi'),
  getGoogleCode: (listId) => axios.get(`/auth/google${listId ? `?list=${listId}` : ''}`),
  loginWithGoogle: ({ code, listId }) => axios.post(`/auth/google${listId ? `?list=${listId}` : ''}`, code),
  getFacebookCode: (listId) => axios.get(`/auth/facebook${listId ? `?list=${listId}` : ''}`),
  loginWithFacebook: ({ code, listId }) => axios.post(`/auth/facebook${listId ? `?list=${listId}` : ''}`, code),
  loginWithApple: (codeResponse) => axios.post('/auth/apple', codeResponse),
  requestResetPassword: (email) => axios.post('/forgot-password', { email }),
  getBroughtUsersCounts: (body) => axios.post('/user/brought-count', body)
};

export const actionTypes = {
  GET_USER: 'GET_USER',
  DO_LOGIN: 'DO_LOGIN',
  CREATE_USER: 'CREATE_USER',
  CREATE_BLOGGER: 'CREATE_BLOGGER',
  UPDATE_USER: 'UPDATE_USER',
  //DELETE_USER: 'DELETE_USER',
  LOGOUT: 'LOGOUT',
  GET_GOOGLE_CODE: 'GET_GOOGLE_CODE',
  LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
  GET_FACEBOOK_CODE: 'GET_FACEBOOK_CODE',
  LOGIN_WITH_FACEBOOK: 'LOGIN_WITH_FACEBOOK',
  GET_APPLE_CODE: 'GET_APPLE_CODE',
  LOGIN_WITH_APPLE: 'LOGIN_WITH_APPLE',
  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  GET_BROUGHT_USERS_COUNT: 'GET_BROUGHT_USERS_COUNT'
};

const cookies = new Cookies();

const processLoginResult = async (result, onSuccess, onError, errMsg) => {
  if (result?.data?.token) {
    await cookies.set('X-JWT', result.data.token, config.isLocalhost ? { path: '/' } : { path: '/', sameSite: 'None', secure: true });
    if (result.status === 201) {
      notification.success({ message: 'The new account was created.' });
      notification.success({ message: 'Welcome to Zestary!' });
    }
    if (isFunc(onSuccess) && result.data) onSuccess(result.data);
  } else if (isFunc(onError)) onError(errMsg);
};

function* performLogOut(isSoft) {
  yield put({ type: 'USER_LOGOUT' });
  yield cookies.remove('X-JWT', config.isLocalhost ? { path: '/' } : { path: '/', sameSite: 'None', secure: true });
  if (!isSoft) history.push('/login');
}

function* sagaDoLogin({ payload: { login: loginData, listId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.login, { loginData, listId });
    yield processLoginResult(result, onSuccess, onError, 'Unable to sign in');
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetGoogleCode({ payload: { listId, onSuccess, onError } }) {
  try {
    const response = yield call(Api.getGoogleCode, listId);
    if (isFunc(onSuccess) && response?.data) onSuccess(response.data);
    else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDoGoogleLogin({ payload: { codeString: code, listId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.loginWithGoogle, { code, listId });
    yield processLoginResult(result, onSuccess, onError, 'Unable to sign in');
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetFacebookCode({ payload: { listId, onSuccess, onError } }) {
  try {
    const response = yield call(Api.getFacebookCode, listId);
    if (isFunc(onSuccess) && response?.data) onSuccess(response.data);
    else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDoFacebookLogin({ payload: { codeString: code, listId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.loginWithFacebook, { code, listId });
    yield processLoginResult(result, onSuccess, onError, 'Unable to sign in');
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAppleCode({ payload: { onSuccess, onError } }) {
  try {
    const response = yield window.AppleID.auth.signIn();
    console.log('Response:', response);
    if (isFunc(onSuccess) && response) onSuccess(response);
    else if (isFunc(onError)) onError();
  } catch (error) {
    if (isFunc(onError)) onError(error);
  }
}

function* sagaDoAppleLogin({ payload: { codeResponse, onSuccess, onError } }) {
  try {
    const result = yield call(Api.loginWithApple, codeResponse);
    yield processLoginResult(result, onSuccess, onError, 'Unable to sign in');
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetUser({ payload }) {
  try {
    if (!cookies.get('X-JWT')) {
      yield put({ type: userActionType.IS_LOADED_USER, payload: true });
      return;
    }

    const result = yield call(Api.user);
    if (!result) yield performLogOut();
    if (result.status >= 400) yield performLogOut();
    yield put({ type: userActionType.USER, payload: result.data });
    if (isFunc(payload?.onSuccess)) {
      payload.onSuccess();
    }

    yield put({ type: userActionType.IS_LOADED_USER, payload: true });
  } catch {
    performLogOut();
  }
}

function* sagaCreateUser({ payload: { user: userData, listId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createUser, { listId, userData });
    yield processLoginResult(result, onSuccess, onError, 'Unable to sign up');
  } catch {
    // eslint-disable-next-line no-unused-expressions
    isFunc(onError) && onError();
  }
}

function* sagaCreateBlogger({ payload: { user: userData, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createBlogger, { userData });
    if (result.status === 201 && isFunc(onSuccess)) {
      onSuccess();
    } else if (isFunc(onError)) {
      onError();
    }
  } catch {
    isFunc(onError) && onError();
  }
}

function* sagaUpdateUser({ payload: { userData, onSuccess, onError } }) {
  try {
    const user = yield call(Api.updateUser, { userData });
    if (isFunc(onSuccess)) onSuccess(user.data);

    const skipPassword = ({ password, ...userData }) => userData; // eslint-disable-line

    yield put({ type: userActionType.USER, payload: skipPassword(user.data) });
  } catch {
    if (isFunc(onError)) {
      onError();
    }
  }
}

/*function* sagaDeleteUser({ payload: { userId } }) {
  console.log("Deleting user's account...");
  try {
    yield call(Api.deleteUser, userId);
    yield performLogOut();
  } catch {
    console.log('Unable to delete user\'s account');
  }
}*/

function* sagaLogOut({ payload: { isSoft } }) {
  try {
    yield performLogOut(isSoft);
  } catch {
    console.log('Unable to perform logout');
  }
}

function* sagaForgotPassword({ payload: { email, onSuccess, onError } }) {
  try {
    const result = yield call(Api.requestResetPassword, email);
    if (isFunc(onSuccess)) onSuccess(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetBroughtUsersCounts({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getBroughtUsersCounts, body);
    if (isFunc(onSuccess)) onSuccess(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getUser = payload => ({ type: actionTypes.GET_USER, payload });
export const doLogin = payload => ({ type: actionTypes.DO_LOGIN, payload });
export const createUser = payload => ({ type: actionTypes.CREATE_USER, payload });
export const createBlogger = payload => ({ type: actionTypes.CREATE_BLOGGER, payload });
export const updateUser = payload => ({ type: actionTypes.UPDATE_USER, payload });
//export const deleteUser = payload => ({ type: actionTypes.DELETE_USER, payload });
export const logOut = payload => ({ type: actionTypes.LOGOUT, payload });
export const getGoogleCode = payload => ({ type: actionTypes.GET_GOOGLE_CODE, payload });
export const doGoogleLogin = payload => ({ type: actionTypes.LOGIN_WITH_GOOGLE, payload });
export const getFacebookCode = payload => ({ type: actionTypes.GET_FACEBOOK_CODE, payload });
export const doFacebookLogin = payload => ({ type: actionTypes.LOGIN_WITH_FACEBOOK, payload });
export const getAppleCode = payload => ({ type: actionTypes.GET_APPLE_CODE, payload });
export const doAppleLogin = payload => ({ type: actionTypes.LOGIN_WITH_APPLE, payload });
export const requestResetPassword = payload => ({ type: actionTypes.REQUEST_RESET_PASSWORD, payload });
export const getBroughtUsersCounts = payload => ({ type: actionTypes.GET_BROUGHT_USERS_COUNT, payload });

export default [
  takeLatest(actionTypes.GET_USER, sagaGetUser),
  takeLatest(actionTypes.DO_LOGIN, sagaDoLogin),
  takeLatest(actionTypes.CREATE_USER, sagaCreateUser),
  takeLatest(actionTypes.CREATE_BLOGGER, sagaCreateBlogger),
  takeLatest(actionTypes.UPDATE_USER, sagaUpdateUser),
  //takeLatest(actionTypes.DELETE_USER, sagaDeleteUser),
  takeLatest(actionTypes.LOGOUT, sagaLogOut),
  takeLatest(actionTypes.GET_GOOGLE_CODE, sagaGetGoogleCode),
  takeLatest(actionTypes.LOGIN_WITH_GOOGLE, sagaDoGoogleLogin),
  takeLatest(actionTypes.GET_FACEBOOK_CODE, sagaGetFacebookCode),
  takeLatest(actionTypes.LOGIN_WITH_FACEBOOK, sagaDoFacebookLogin),
  takeLatest(actionTypes.GET_APPLE_CODE, sagaGetAppleCode),
  takeLatest(actionTypes.LOGIN_WITH_APPLE, sagaDoAppleLogin),
  takeLatest(actionTypes.REQUEST_RESET_PASSWORD, sagaForgotPassword),
  takeLatest(actionTypes.GET_BROUGHT_USERS_COUNT, sagaGetBroughtUsersCounts)
];
