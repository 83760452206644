import environments from './environments';

const configs = { environments };

const getLocationInfo = () => {
  const availableEnvironments = [
    {
      host: 'staging.zestary.com',
      hostname: 'staging.zestary.com',
      serverHost: 'api.staging.zestary.com',
      serverUrl: 'https://api.staging.zestary.com',
      env: 'staging',
      isLocalhost: false
    },
    {
      host: 'zestary.com',
      hostname: 'zestary.com',
      serverHost: 'api.zestary.com',
      serverUrl: 'https://api.zestary.com',
      env: 'prod',
      isLocalhost: false
    }
  ];
  const { hostname, host } = window.location;
  const currentEnvironment = availableEnvironments.find((env) => env.host === host);
  if (currentEnvironment) { return currentEnvironment; }

  const env = 'dev';
  const isLocalhost = hostname === 'localhost';

  return {
    env,
    host,
    isLocalhost,
    hostname,
    serverUrl: isLocalhost ? 'http://localhost:8080' : `http://${hostname}`,
    serverHost: isLocalhost ? 'localhost:8080' : hostname
  };
};

const { env, host, hostname, serverUrl, isLocalhost, serverHost } = getLocationInfo();
const config = configs.environments[env];

const commonConfig = {
  ENV: env,
  HOST: host,
  HOSTNAME: hostname,
  SERVER_URL: serverUrl,
  SERVER_HOST: serverHost,
  isLocalhost/*,
  PAGINATION_PAGE_SIZE: 10,
  COOKIE_EXPIRATION_TIME: new Date(Date.now() + 86400e3 * 30)*/
};

export default { ...commonConfig, ...config };
