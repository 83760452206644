import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { Button, Footer } from '../../../common/components';
import { retrievePage, getBlogger } from '../../sagas';
import { pageTemplate } from './pageTemplate';
import config from '../../../../config';

const TestTool = ({ retrievePage, getBlogger }) => {
  const { t } = useTranslation('admin');
  const [targetUrl, setTargetUrl] = useState('');
  const [publisherId, setPublisherId] = useState(undefined);
  const [recipeScript, setRecipeScript] = useState(undefined);
  const [publisher, setPublisher] = useState(undefined);
  const [externalId, setExternalId] = useState(undefined);
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const retrieveTargetPage = () => {
    retrievePage({
      url: targetUrl,
      onSuccess: (data) => {
        const parser = new DOMParser();
        const externalDocument = parser.parseFromString(data, 'text/html');
        const sources = externalDocument.querySelectorAll('script[type="application/ld+json"]');
        const arrayOfSources = Array.prototype.slice.call(sources);
        const newRecipeScript = arrayOfSources.find((source) =>
          !!(JSON.parse(source.innerText))?.['@graph']?.find((graph) => graph['@type'] === 'Recipe'));
        setRecipeScript(JSON.parse(newRecipeScript.innerText));
      },
      onError: () => {
        setRecipeScript(undefined);
        notification.error({ message: t('unableRetrievePage') });
      }
    });
  };

  const retrievePublisher = () => {
    getBlogger({
      id: publisherId,
      onSuccess: (data) => {
        setPublisher(data);
      },
      onError: () => {
        setPublisher(undefined);
        notification.error({ message: t('unableRetrievePublisher') });
      }
    });
  };

  const generateFile = () => {
    let template = pageTemplate;
    template = template.replace('{{{RECIPE_JSON}}}', JSON.stringify(recipeScript));
    template = template.replace('{{{PUBLISHER_ID}}}', publisher._id);
    template = template.replace('{{{RECIPE_EXTERNAL_ID}}}', externalId.toString());
    template = template.replace(/\{\{\{APP_URL}}}/g,
      config.ENV === 'prod' ? 'https://zestary.com' : 'https://staging.zestary.com');

    const link = document.createElement('a');
    link.id = 'downloadLink';
    link.download = `Test_external_page_${Date.now()}.html`;
    const data = new Blob([template], { type: 'text/html' });
    if (downloadUrl) window.URL.revokeObjectURL(downloadUrl);
    const url = window.URL.createObjectURL(data);
    setDownloadUrl(url);
    link.href = url;

    document.body.append(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 10);
  };

  return (
    <>
      <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
        <Col className={styles.cardWrapper}>
          <div className={styles.pageTitle}>{t('testToolTitle')}</div>
          <div className={styles.pageLoaderWrapper}>
            <div className={styles.urlWrapper}>
              <Input
                placeholder={t('url')}
                onPressEnter={() => retrieveTargetPage()}
                className={styles.urlInput}
                value={targetUrl}
                onChange={(e) => setTargetUrl(e.target.value)}
              />
            </div>
            <Button type="primary" onClick={() => retrieveTargetPage()} disabled={!targetUrl}>
              {t('loadPage')}
            </Button>
          </div>
          <div>
            <div className={styles.recipeScript}>
              <div className={styles.label}>{t('recipeJson')}:{' '}{!recipeScript && (<span className={styles.undefined}>
                {t('undefined')}</span>) }
              </div>
              {!!recipeScript && (<pre className={styles.script}>{JSON.stringify(recipeScript, null, 2)}</pre>) }
            </div>
          </div>
          <div className={styles.publisherLoaderWrapper}>
            <div className={styles.publisherWrapper}>
              <Input
                placeholder={t('publisherId')}
                onPressEnter={() => retrievePublisher()}
                className={styles.publisherInput}
                value={publisherId}
                onChange={(e) => setPublisherId(e.target.value)}
              />
            </div>
            <Button type="primary" onClick={() => retrievePublisher()} disabled={!publisherId}>
              {t('loadPublisher')}
            </Button>
          </div>
          <div className={styles.recipeScript}>
            <div className={styles.label}>
              {t('publisher')}:{' '}
              <span className={!publisher ? styles.undefined : styles.ok}>
                {!publisher ? t('undefined') : `${publisher.firstName} ${publisher.lastName}`}
              </span>
            </div>
          </div>
          <div className={styles.publisherLoaderWrapper}>
            <div className={styles.publisherWrapper}>
              <Input
                placeholder={t('externalId')}
                className={styles.publisherInput}
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Button type="primary" onClick={() => generateFile()} disabled={!publisherId || !externalId || !recipeScript}>
              {t('generateHtml')}
            </Button>
          </div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

TestTool.propTypes = {
  retrievePage: PropTypes.func.isRequired,
  getBlogger: PropTypes.func.isRequired
};

export default compose(
  connect(
    null,
    { retrievePage, getBlogger }
  )
)(TestTool);
