import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { getGoogleCode, getFacebookCode } from '../../user/sagas';


const ExternalSocialLogin = props => {
  const {
    location,
    getGoogleCode,
    getFacebookCode
  } = props;
  const { t } = useTranslation('login');

  useEffect(() => {
    const { source, list } = queryString.parse(location.search);

    switch (source) {
      case 'google':
        requestGoogleCode(list);
        break;
      case 'facebook':
        requestFacebookCode(list);
        break;
      default:
        notification.error({ message: `${t('unknownSocialNetwork')}: ${source}` });
        break;
    }
  }, []); // eslint-disable-line

  const requestGoogleCode = (list) => {
    getGoogleCode({
      listId: list ?? null,
      onSuccess: (response) => {
        if (response.location) {
          window.location = response.location;
        } else {
          notification.error({ message: t('unableToGetGoogleAuthUrl') });
        }
      },
      onError: () => {
        notification.error({ message: t('unableToGetGoogleAuthUrl') });
      }
    });
  };

  const requestFacebookCode = (list) => {
    getFacebookCode({
      listId: list ?? null,
      onSuccess: (response) => {
        if (response.location) {
          window.location = response.location;
        } else {
          notification.error({ message: t('unableToGetFacebookAuthUrl') });
        }
      },
      onError: () => {
        notification.error({ message: t('unableToGetFacebookAuthUrl') });
      }
    });
  };

  return (
    <p />
  );
};

ExternalSocialLogin.propTypes = {
  getFacebookCode: PropTypes.func.isRequired,
  getGoogleCode: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  connect(
    ({ recipe: { externalSource } }) => ({ externalSource }),
    { getGoogleCode, getFacebookCode }
  )
)(ExternalSocialLogin);
