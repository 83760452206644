export default {
  email: 'Email',
  password: 'Password',
  signIn: 'Sign in',
  login: 'Login',
  signUp: 'Sign up',
  logIn: 'Log In',
  register: 'Register',
  signUpForFree: 'Sign up for a free shopping list',
  firstName: 'First name',
  lastName: 'Last name',
  repeatPassword: 'Repeat password',
  createAnAccount: 'create an account',
  createAccount: 'Create account',
  back: 'Back',
  or: 'or',
  signInWith: 'Sign In with',
  signUpWith: 'Sign Up with',
  facebook: 'Facebook',
  google: 'Google',
  apple: 'Apple',
  invalidEmail: 'Invalid email',
  enterEmail: 'Email is required',
  enterPassword: 'Password is required',
  enterFirstName: 'First name is required',
  confirmPassword: 'Please confirm a password',
  enterMaxName: 'Please enter no more than 40 characters',
  passwordMustMatch: 'Passwords must match',
  forgotYourPassword: 'Forgot password?',
  signUpFailed: 'Unable to sign up',
  loginFailed: 'Unable to login',
  unableToGetGoogleAuthUrl: 'Unable to get link to authentication page',
  unableToGetFacebookAuthUrl: 'Unable to get link to authentication page',
  unableToGetAppleToken: 'Unable to get the ID token',
  someoneSharedList: 'Someone wish to share a list with you. Log in or create an account to see the details.',
  resetYourPassword: 'Reset your password',
  forgotPasswordDescription: 'Enter your email to receive password reset instructions.' +
    ' You may need to check your spam folder.',
  requestInstructions: 'Request instructions',
  backToIngredientsList: 'Back to ingredients list',
  socialNetworkWarning: "Almost all browsers don't allow you to sign in/up through social networks in the internal" +
    ' pop up window, so a new tab will be opened. Sign in/up in the new tab, and then return here.',
  toSaveIngredientsAndRecipe: 'to add the ingredients to your shopping list and save the recipe',
  unknownSocialNetwork: 'Unknown social network'
};
