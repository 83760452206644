import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { useModal } from '../../../common/hooks';
import { getLists } from '../../../lists/sagas/listsSagas';
import { setActiveList, setAvailableLists, setSharedLists } from '../../../lists/reducers';
import { importToList, importToNewList } from '../../sagas';
import styles from './styles.module.scss';
import { Button } from '../../../common/components';
import { isFunc } from '../../../../utils/functions';


const ImportIngredientsModal = ({
  recipe,
  rejectedIngredientIds = [],
  user,
  modalTriggerComponent,
  getLists,
  availableLists,
  setActiveList,
  setAvailableLists,
  setSharedLists,
  importToList,
  importToNewList,
  iFrameSource,
  customSuccessCallback
}) => {
  const { t } = useTranslation('recipe');
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    if (!availableLists && user) {
      getLists({
        onSuccess: (data) => {
          if (Array.isArray(data)) {
            const myLists = data.filter((list) => list.owners?.indexOf?.(user._id) >= 0);
            const sharedLists = data.filter((list) => list.shared?.indexOf?.(user._id) >= 0);
            setSharedLists(sharedLists);
            setAvailableLists(myLists);
          }
        },
        onError: () => {
          notification.error({ message: t('unableGetLists') });
        }
      });
    }
  }, [user]); //eslint-disable-line

  const processSuccessfulImport = (data) => {
    setActiveList(data);
    hideImportIngredientsModal();
    setIsImporting(false);
    if (iFrameSource) {
      notification.success({ message: t('imported') });
      setTimeout(() => {
        iFrameSource.postMessage({ action: 'close', recipeId: recipe?.externalId }, '*');
      }, 2000);
    } else if (isFunc(customSuccessCallback)) {
      customSuccessCallback(data);
    }
  };

  const doImportToList = (recipeId, listId, rejectedIngredientIds) => {
    setIsImporting(true);
    importToList({
      recipeId,
      listId,
      rejectedIngredientIds,
      onError: () => {
        notification.error({ message: t('unableImportIngredients') });
        setIsImporting(false);
      },
      onSuccess: (data) => {
        processSuccessfulImport(data);
      }
    });
  };

  const doImportToNewList = (recipeId, rejectedIngredientIds) => {
    setIsImporting(true);
    importToNewList({
      recipeId,
      rejectedIngredientIds,
      onError: () => {
        notification.error({ message: t('unableImportIngredients') });
        setIsImporting(false);
      },
      onSuccess: (data) => {
        setAvailableLists([data, ...availableLists]);
        processSuccessfulImport(data);
      }
    });
  };

  const { Modal: ImportModal, hideModal: hideImportIngredientsModal } = useModal({
    body: {
      title: t('chooseList'),
      titleAlignment: 'left',
      Component: () => (
        <>
          {availableLists?.map?.((list) => (
            <p
              className={`${styles.listOption} ${isImporting ? styles.importing : ''}`}
              onClick={() => doImportToList(recipe._id, list._id, rejectedIngredientIds)}
              key={list._id}
            >{list.name}</p>
          ))}
          <Button
            type="secondary"
            className={styles.fullWidthBth}
            disabled={isImporting}
            onClick={() => doImportToNewList(recipe._id, rejectedIngredientIds)}
          >
            {t('importToNewList')}
          </Button>
        </>
      )
    },
    bodyStyle: {
      textAlign: 'left'
    },
    btnComponent: modalTriggerComponent
      ?? (({ onClick }) => (
        <div
          onClick={onClick}
        >
          {t('addToShoppingList')}
        </div>
      )),
    ok: {
      isHide: true
    },
    cancel: {
      isHide: true
    },
    modal: {
      width: 600
    },
    closable: true,
    maskClosable: true
  });

  return <>{ImportModal}</>;
};

ImportIngredientsModal.propTypes = {
  recipe: PropTypes.object.isRequired,
  rejectedIngredientIds: PropTypes.arrayOf(PropTypes.string),
  modalTriggerComponent: PropTypes.func,
  getLists: PropTypes.func.isRequired,
  setAvailableLists: PropTypes.func.isRequired,
  setSharedLists: PropTypes.func.isRequired,
  setActiveList: PropTypes.func.isRequired,
  importToList: PropTypes.func.isRequired,
  importToNewList: PropTypes.func.isRequired,
  iFrameSource: PropTypes.any,
  customSuccessCallback: PropTypes.func
};

export default compose(
  connect(
    ({ user: { user }, lists: { activeList, availableLists } }) => ({
      activeList, availableLists, user
    }),
    {
      getLists,
      setAvailableLists,
      setSharedLists,
      setActiveList,
      importToList,
      importToNewList
    }
  )
)(ImportIngredientsModal);
