import React from 'react';
import { NoLoggedInRoute } from '../auth/components';

import { Login } from './pages';
import locales from './locales';

export default {
  routes: [<NoLoggedInRoute key="login" exact path="/login" component={Login} />],
  locales: { ns: 'login', resources: locales }
};
