import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 5
});

const expectedAPIErrors = [
  {
    endpoint: 'api/v1/someunexpectedendpoint',
    method: 'post',
    status: 404,
    process: () => {
      notification.error({
        message: 'This is quite unexpected endpoint'
      });
    }
  }
];

const defaultErrorProcessing = (data) => {
  if (data?.message) notification.error({ message: data.message });
  else if (data?.error?.message) notification.error({ message: data.error.message });
  else if (typeof data?.errors === 'object' && data?.errors?.message) notification.error({ message: data.errors.message });
  else if (Array.isArray(data?.errors)) {
    data.errors.forEach(({ message }) => {
      if (message?.trim().length) {
        notification.error({
          message
        });
      }
    });
  }
};

export const processAPIErrors = (data, config, status) => {
  const expectedError = expectedAPIErrors.find(
    (e) =>
      (!e.status || status === e.status) &&
      (!e.method || config.method === e.method) &&
      (!e.endpoint || config.url.indexOf(e.endpoint) >= 0)
  );
  if (expectedError) expectedError.process();
  else defaultErrorProcessing(data);
};
