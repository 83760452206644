import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactStoreBadges from 'react-store-badges';
import { Row } from 'antd';

import queryString from 'query-string';
import styles from './styles.module.scss';
import { Header, TitleArea, PlateWithIngredients, InfoBlocks, Footer, BloggerSignUp } from '../components';
import config from '../../../config';

const Landing = ({ location }) => {
  const { t } = useTranslation('landing');
  const [mode, setMode] = useState('landing');
  const availableModes = [
    { key: 'landing', title: t('howItWorks'), width: 107 },
    { key: 'blogger', title: t('areYouBlogger'), width: 169 }
  ];

  useEffect(() => {
    const { switchToMode } = queryString.parse(location.search);
    if (switchToMode === 'blogger') {
      window.history.replaceState({}, document.title, '/');
      setMode('blogger');
    } else if (switchToMode === 'landing') {
      window.history.replaceState({}, document.title, '/');
      setMode('landing');
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (config.GA_TRACKING_ID) {
      ReactGA.event({
        category: 'Switching mode',
        action: `"/" - ${mode}`
      });
    }
  }, [mode]); // eslint-disable-line

  const [hide, doHide] = useState({
    mainTitle: false,
    mainDescription: false,
    mainCreateListButton: false,
    saladImg: false,
    block1: false,
    img1Mobile: false,
    description1Mobile: false,
    block2: false,
    img2Mobile: false,
    description2Mobile: false
  });
  const [isIngredientsAppeared, setIsIngredientsAppeared] = useState(false);
  const mainTitleRef = useRef(null);
  const mainDescriptionRef = useRef(null);
  const saladImgRef = useRef(null);
  const block1Ref = useRef(null);
  const img1MobileRef = useRef(null);
  const description1MobileRef = useRef(null);
  const block2Ref = useRef(null);
  const img2MobileRef = useRef(null);
  const description2MobileRef = useRef(null);

  useLayoutEffect(() => {
    const headerHeight = 100;
    const reEvaluationDebounce = 300;
    let reEvaluationTimeout = null;
    let mainTitlePos;
    let mainDescriptionPos;
    let saladImgPos;
    let block1Pos;
    let img1MobilePos;
    let description1MobilePos;
    let block2Pos;
    let img2MobilePos;
    let description2MobilePos;

    const topPos = element => (element?.getBoundingClientRect?.()?.top + window.scrollY) ?? Number.MAX_VALUE;

    const reEvaluateTopPositions = () => {
      if (reEvaluationTimeout) clearTimeout(reEvaluationTimeout);
      reEvaluationTimeout = setTimeout(() => {
        mainTitlePos = topPos(mainTitleRef.current);
        mainDescriptionPos = topPos(mainDescriptionRef.current);
        saladImgPos = topPos(saladImgRef.current);
        block1Pos = topPos(block1Ref.current);
        img1MobilePos = topPos(img1MobileRef.current);
        description1MobilePos = topPos(description1MobileRef.current);
        block2Pos = topPos(block2Ref.current);
        img2MobilePos = topPos(img2MobileRef.current);
        description2MobilePos = topPos(description2MobileRef.current);
      }, reEvaluationDebounce);
    };

    reEvaluateTopPositions();

    const onScroll = () => {
      const scrollPos = window.scrollY/* + window.innerHeight*/;
      setIsIngredientsAppeared(window.scrollY >= 5);
      doHide(() => ({
        mainTitle: mainTitlePos < scrollPos + headerHeight,
        mainDescription: mainDescriptionPos < scrollPos + headerHeight,
        mainCreateListButton: mainDescriptionPos < scrollPos + headerHeight,
        saladImg: saladImgPos < scrollPos + headerHeight - 20,
        block1: block1Pos < scrollPos + headerHeight - 20,
        img1Mobile: img1MobilePos < scrollPos + headerHeight - 20,
        description1Mobile: description1MobilePos < scrollPos + headerHeight - 20,
        block2: block2Pos < scrollPos + headerHeight - 20,
        img2Mobile: img2MobilePos < scrollPos + headerHeight - 20,
        description2Mobile: description2MobilePos < scrollPos + headerHeight - 20
      }));
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', reEvaluateTopPositions);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', reEvaluateTopPositions);
    };
  }, []);

  const renderStoreBadges = () => {
    return (
      <Row type="flex" justify="center" align="middle" className={styles.storeBadgesWrapper}>
        <ReactStoreBadges
          platform={'ios'}
          url={config.IOS_APP_LINK ?? ''}
          locale={'en-us'}
          height={44}
        />
        <ReactStoreBadges
          platform={'android'}
          url={config.ANDROID_APP_LINK ?? ''}
          locale={'en-us'}
          height={44}
        />
      </Row>
    );
  };

  return <div className={styles.pageWrapper}>
    <Header mode={mode} setMode={setMode} availableModes={availableModes} />
    { mode === 'landing' ? (
      <>
        <TitleArea hide={hide} mainTitleRef={mainTitleRef} mainDescriptionRef={mainDescriptionRef} />
        <PlateWithIngredients hide={hide} saladImgRef={saladImgRef} isIngredientsAppeared={isIngredientsAppeared} />
        <InfoBlocks
          hide={hide}
          block1Ref={block1Ref}
          img1MobileRef={img1MobileRef}
          description1MobileRef={description1MobileRef}
          block2Ref={block2Ref}
          img2MobileRef={img2MobileRef}
          description2MobileRef={description2MobileRef}
        />
        {renderStoreBadges()}
      </>
    ) : mode === 'blogger' ? (
      <BloggerSignUp />
    ) : (
      <p style={{ marginTop: '150px' }}>Thank you page will be here</p>
    )}

    <Footer setMode={setMode} mode={mode} />
  </div>;
};

export default withRouter(Landing);
