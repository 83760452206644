import { call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '../../../utils/functions';

const Api = {
  getRedirectToInstacartCart: ({ ingredients, zipCode }) => axios.post('/external/instacart', { ingredients, zipCode })
};

export const actionTypes = {
  GET_REDIRECT_TO_INSTACART_CART: 'GET_REDIRECT_TO_INSTACART_CART'
};

function* sagaGetRedirectToInstacartCart({ payload: { ingredients, zipCode, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getRedirectToInstacartCart, { ingredients, zipCode });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getRedirectToInstacartCart = (payload) => ({ type: actionTypes.GET_REDIRECT_TO_INSTACART_CART, payload });

export default [
  takeLatest(actionTypes.GET_REDIRECT_TO_INSTACART_CART, sagaGetRedirectToInstacartCart)
];
