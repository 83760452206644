import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import co from 'co';
import DefaultModal from './DefaultModal';

const Modal = ({
  modal,
  ok = {},
  cancel = {},
  back = {},
  forward = {},
  btnComponent,
  body = {},
  hideModal,
  showModal,
  closable = false,
  maskClosable = false,
  bodyStyle = {},
  waitForHandleOkCompletion = true
}) => {
  const [isOkLoading, setIsOkLoading] = useState(false);

  const handleOk = waitForHandleOkCompletion
    ? async () => {
      function* onClick() {
        yield Promise.resolve(setIsOkLoading(true));
        try {
          if (ok.onClick) {
            yield* ok.onClick();
          }
          yield Promise.resolve(setIsOkLoading(false));
          hideModal();
        } catch {
          setIsOkLoading(false);
        }
      }

      co(onClick);
    }
    : () => {
      if (ok.onClick) {
        ok.onClick();
      }
      hideModal();
    };

  const handleCancel = e => {
    if (cancel.onClick) {
      cancel.onClick(e);
    }
    hideModal();
  };

  const renderModalBtn = () => {
    const withEvent = Component => props => (
      <Component
        {...props}
        onClick={e => {
          if (props.forceStopPropagation) e.stopPropagation();
          showModal();
        }}
      />
    );
    const ModalBtn = withEvent(btnComponent);
    return <ModalBtn />;
  };

  const modalProps = {
    handleOk,
    handleCancel,
    modal: {
      ...modal,
      closeIcon: <FontAwesomeIcon icon="times" />
    },
    body,
    ok: { ...ok, isLoading: isOkLoading },
    cancel,
    back,
    forward,
    hideModal,
    closable,
    maskClosable,
    bodyStyle,
    width: modal.width
  };

  return (
    <>
      {btnComponent && renderModalBtn()}
      <DefaultModal {...modalProps} />
    </>
  );
};

Modal.propTypes = {
  modal: PropTypes.object,
  ok: PropTypes.object,
  cancel: PropTypes.object,
  btnComponent: PropTypes.func,
  body: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  type: PropTypes.string,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  width: PropTypes.number
};

const useModal = ({ modal = {}, visible, ...rest }) => {
  const [isVisible, setIsVisible] = useState(visible);

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  const newProps = { ...rest, modal: { ...modal, visible: isVisible }, showModal, hideModal };

  return {
    Modal: <Modal {...newProps} />,
    showModal,
    hideModal,
    isVisible,
    setIsVisible
  };
};

export default useModal;
