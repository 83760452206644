import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { Formik } from 'formik';
import { object, string } from 'yup';
import { Button, FieldAdapter as Field, RenderField } from '../../../common/components';
import styles from './styles.module.scss';

const ForgotPasswordForm = ({
  isValid,
  values: { email },
  setValues,
  handleSubmit
}) => {
  const { t } = useTranslation('login');

  useEffect(() => {
    setValues({
      email: ''
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.title}>{t('resetYourPassword')}</div>
      <div className={styles.description}>{t('forgotPasswordDescription')}</div>
      <span className={styles.label}>{t('email')}</span>
      <Field
        size="large"
        name="email"
        component={RenderField}
        type="text"
        value={email}
      />
      <Button
        disabled={!isValid}
        type="primary"
        htmlType="submit"
        className={styles.fullWidthButton}
        onClick={handleSubmit}
      >
        {t('requestInstructions')}
      </Button>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  bindSubmit: PropTypes.func.isRequired,
  bindIsValid: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    email: string()
      .email(t('invalidEmail'))
      .required(t('enterEmail'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('login');
  const validationSchema = getValidationSchema(t);
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      displayName="ResetPasswordForm"
      onSubmit={values => props.onSubmit(values)}
      initialValues={{ email: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default withFormik(ForgotPasswordForm);
