import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber } from 'antd';

const { TextArea } = Input;

const FormItem = Form.Item;

const RenderField = ({ input, label, type, meta: { touched, error }, placeholder, required, hasFeedback }) => {
  let validateStatus = '';
  if (touched) {
    validateStatus = error ? 'error' : 'success';
  }

  return (
    <FormItem
      label={label}
      validateStatus={validateStatus}
      help={touched && error}
      required={required}
      hasFeedback={hasFeedback}
    >
      {type === 'number' ? (
        <InputNumber {...input} />
      ) : type === 'textArea' ? (
        <TextArea {...input} placeholder={placeholder} />
      ) : (
        <Input {...input} placeholder={placeholder} type={type} />
      )}
    </FormItem>
  );
};

RenderField.propTypes = {
  hasFeedback: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string
};

export default RenderField;
