const defaultState = {
  modal: {}
};

export const actionTypes = {
  SET_MODAL: 'SET_MODAL'
};

export default function (state = defaultState, { type, payload = {} }) {
  switch (type) {
    case actionTypes.SET_MODAL:
      return {
        ...state,
        modal: payload
      };

    default:
      return state;
  }
}

// ACTIONS
export const setModal = payload => ({ type: actionTypes.SET_MODAL, payload });
