import mainLayoutAppearance from './mainLayoutAppearance';
import dimensions from './dimensions';

export default {
  mainLayoutAppearance,
  dimensions
};

export * from './mainLayoutAppearance';
export * from './dimensions';
