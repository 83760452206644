import PropTypes from 'prop-types';

import { useModal } from '../../../common/hooks';

const Modal = ({ modal = {}, setModal }) => {
  const ok = modal.ok
    ? {
      ...modal.ok,
      onClick: (...params) => {
        modal.ok.onClick && modal.ok.onClick(...params);
        setModal({});
      }
    }
    : { onClick: () => setModal({}) };

  const cancel = modal.cancel
    ? {
      ...modal.cancel,
      onClick: (...params) => {
        modal.cancel.onClick && modal.cancel.onClick(...params);
        setModal({});
      }
    }
    : { onClick: () => setModal({}) };
  const { Modal } = useModal({
    ...modal,
    ok,
    cancel
  });
  return Modal;
};

Modal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default Modal;
