import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { setIsHiddenMainLayoutWrapper } from '../../../common/reducers';
import { getUser } from '../../../user/sagas';
import { Spinner } from '../../../common/components';
import styles from '../AuthRoute/styles.module.scss';

const NoLoggedInRoute = ({
  component: Component,
  user,
  isLoadedUser,
  getUser,
  shouldHideMainLayoutWrapper,
  setIsHiddenMainLayoutWrapper,
  externalSource,
  ...rest
}) => {
  useEffect(() => {
    setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper);
    if (!user?.email) {
      getUser();
    }
  }, []); // eslint-disable-line

  return (
    <>
      {isLoadedUser ? (
        <Route {...rest} render={props => (!user
          ? <Component {...props} />
          : <Redirect to={!externalSource ? '/lists' : '/ingredient-import'} />)}
        />
      ) : (
        <Spinner className={styles.spinner} size="large" />
      )}
    </>
  );
};

NoLoggedInRoute.propTypes = {
  setIsHiddenMainLayoutWrapper: PropTypes.func.isRequired,
  component: PropTypes.any,
  user: PropTypes.object,
  isLoadedUser: PropTypes.bool,
  getUser: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user, isLoadedUser }, recipe: { externalSource } }) => ({ user, isLoadedUser, externalSource }),
    { getUser, setIsHiddenMainLayoutWrapper }
  )
)(NoLoggedInRoute);
