import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'antd';
import { object, string } from 'yup';
import { /*Link,*/ withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { Button, RenderField, FieldAdapter as Field } from '../../../common/components';
import styles from './styles.module.scss';

const SignInForm = props => {
  const {
    values: { email, password },
    isValid,
    setValues,
    location,
    handleSubmit,
    setMode,
    /*location,*/
    setTouched,
    hideForgotPassword
  } = props;

  const { t } = useTranslation('login');

  const onClickSubmit = () => {
    setValues({ email, password });
    setTouched({ email: true, password: true });
    handleSubmit();
  };

  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const preSetEmail = searchParams?.email?.trim();
    if (preSetEmail) setValues({ email: preSetEmail, password });
  }, []); // eslint-disable-line

  return (
    <Form name="login" onSubmit={handleSubmit}>
      <span className={styles.label}>{t('email')}</span>
      <Field
        size="large"
        name="email"
        component={RenderField}
        type="text"
        value={email}
      />
      <span className={styles.label}>{t('password')}</span>
      <Field
        size="large"
        name="password"
        component={RenderField}
        type={isShowPassword ? 'text' : 'password'}
        value={password}
        input={{
          suffix: (
            <FontAwesomeIcon
              icon={isShowPassword ? 'eye-slash' : 'eye'}
              className={styles.icon}
              onClick={() => setIsShowPassword(!isShowPassword)}
            />
          )
        }}
      />
      <Button
        disabled={!isValid}
        type="primary"
        htmlType="submit"
        className={styles.fullWidthButton}
        onClick={onClickSubmit}
      >
        {t('login')}
      </Button>
      {!hideForgotPassword && (
        <div className={styles.linkWrapper}>
          <Button
            type="bareLink"
            htmlType="button"
            onClick={() => setMode('forgotPassword')}
          >
            {t('forgotYourPassword')}
          </Button>
        </div>
      )}
    </Form>
  );
};

SignInForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setValues: PropTypes.func,
  location: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  hideForgotPassword: PropTypes.bool
};

const getValidationSchema = t => {
  return object().shape({
    email: string()
      .email(t('invalidEmail'))
      .required(t('enterEmail')),
    password: string()
      .required(t('enterPassword'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('login');
  const validationSchema = getValidationSchema(t);
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      displayName="LoginForm"
      validateOnMount={false}
      onSubmit={values => props.onSubmit(values)}
      initialValues={{ name: '', email: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default withFormik(withRouter(SignInForm));
