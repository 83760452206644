import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { Button } from '..';
import history from '../../../../utils/history';
import { withDimensions } from '../../HOC';

const Footer = ({ dimensions }) => {
  const { t } = useTranslation('landing');

  const renderLandingLinksBlock = () => (
    <>
      <Button
        type="bareLink"
        onClick={() => history.push('/?switchToMode=landing')}
        className={styles.footerLink}
      >
        {t('howItWorks')}
      </Button>
      <Button
        type="bareLink"
        onClick={() => history.push('/?switchToMode=blogger')}
        className={styles.footerLink}
      >
        {t('areYouBlogger')}
      </Button>
    </>
  );

  const renderLawLinksBlock = () => (
    <>
      <Button type="bareLink" onClick={() => history.push('/privacy-policy')} className={styles.footerLink}>
        {t('privacy')}
      </Button>
      <Button type="bareLink" onClick={() => history.push('/terms')} className={styles.footerLink}>
        {t('terms')}
      </Button>
    </>
  );

  return (
    <>
      {dimensions?.isScreenBiggerLG ? (
        <Row type="flex" justify="space-between" align="middle" className={styles.footerWrapper}>
          <Col />
          <Col>
            {renderLandingLinksBlock()}
            {renderLawLinksBlock()}
          </Col>
          <Col className={styles.footerText}>
            {t('cr')}
          </Col>
        </Row>
      ) : (
        <div className={styles.footerWrapperMobile}>
          {dimensions?.isScreenBiggerMD ? (
            <Row type="flex" justify="center" align="middle" className={styles.line}>
              {renderLandingLinksBlock()}
              {renderLawLinksBlock()}
            </Row>
          ) : (
            <>
              <Row type="flex" justify="center" align="middle" className={styles.line}>
                {renderLandingLinksBlock()}
              </Row>
              <Row type="flex" justify="center" align="middle" className={styles.line}>
                {renderLawLinksBlock()}
              </Row>
            </>
          ) }
          <Row type="flex" justify="center" align="middle" className={styles.line}>
            <Col className={`${styles.footerText}`}>
              {t('cr')}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(Footer);
