import React from 'react';
import { FreeRoute } from '../auth/components';

import { PrivacyPolicy } from './pages';

import locales from './locales';

export default {
  routes: [
    <FreeRoute
        key="privacy-policy"
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        shouldHideMainLayoutWrapper
    />
  ],
  locales: { ns: 'privacy-policy', resources: locales }
};
