import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, Menu, Pagination, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { ActionDropdown, Button, DeleteEntityModal, Footer, Icon } from '../../../common/components';
import {
  getBloggers,
  approveBlogger,
  rejectBlogger,
  getBloggersWithDebounce,
  getUsers,
  getUsersWithDebounce,
  deleteUserByAdmin
} from '../../sagas';
import history from '../../../../utils/history';

const Publishers = ({
  bloggers,
  users,
  getBloggers,
  approveBlogger,
  rejectBlogger,
  getBloggersWithDebounce,
  getUsers,
  getUsersWithDebounce,
  deleteUserByAdmin
}) => {
  const { t } = useTranslation('admin');
  const PAGE_SIZE = 15;
  const [statusFilter, setStatusFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [mode, setMode] = useState('bloggers');
  const [displayedPublishers, setDisplayedPublishers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [currentSearchParameter, setCurrentSearchParameter] = useState('');

  useEffect(() => {
    if (mode === 'bloggers') retrieveBloggers();
    else retrieveUsers();
  }, [statusFilter, roleFilter, mode]); // eslint-disable-line

  useEffect(() => {
    let sortedBloggers = [];
    ['registered', 'approved', 'rejected', 'deleted'].forEach((status) => {
      const currentGroup = bloggers?.entities?.filter((blogger) => blogger.status === status) ?? [];
      sortedBloggers = [...sortedBloggers, ...currentGroup];
    });
    setDisplayedPublishers(sortedBloggers);
  }, [bloggers]);

  useEffect(() => {
    setDisplayedUsers(users?.entities ?? []);
  }, [users]);

  const retrieveBloggers = (page = 1) => {
    getBloggers({
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      type: statusFilter,
      query: currentSearchParameter
    });
  };

  const retrieveUsers = (page = 1) => {
    getUsers({
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      role: roleFilter,
      query: currentSearchParameter
    });
  };

  const doApproveBlogger = (id) => {
    approveBlogger({
      id,
      onSuccess: () => retrieveBloggers()
    });
  };

  const doRejectBlogger = (id) => {
    rejectBlogger({
      id,
      onSuccess: () => retrieveBloggers()
    });
  };

  const doDeleteUser = (id) => {
    deleteUserByAdmin({
      id,
      onSuccess: () => retrieveUsers()
    });
  };

  const doDeleteBlogger = (id) => {
    deleteUserByAdmin({
      id,
      onSuccess: () => retrieveBloggers()
    });
  };

  const onQueryChange = (query) => {
    setCurrentSearchParameter(query);
    if (mode === 'bloggers') getBloggersWithDebounce({ limit: PAGE_SIZE, offset: 0, type: statusFilter, query });
    else getUsersWithDebounce({ limit: PAGE_SIZE, offset: 0, role: roleFilter, query });
  };

  const onSearch = (query) => {
    setCurrentSearchParameter(query);
    if (mode === 'bloggers') getBloggers({ limit: PAGE_SIZE, offset: 0, type: statusFilter, query });
    else getUsers({ limit: PAGE_SIZE, offset: 0, role: roleFilter, query });
  };

  const renderStatusMenuItems = () => {
    return (<Menu className="template-action-menu">
      {['', 'registered', 'approved', 'rejected', 'deleted'].map((status) => (
        <Menu.Item key={status || 'all'}>
          <div onClick={() => setStatusFilter(status)}>
            {t(status || 'all')}
          </div>
        </Menu.Item>
      ))}
    </Menu>);
  };

  const renderRoleMenuItems = () => {
    return (<Menu className="template-action-menu">
      {['', 'admin', 'blogger', 'user'].map((role) => (
        <Menu.Item key={role || 'all'}>
          <div onClick={() => setRoleFilter(role)}>
            {t(role || 'all')}
          </div>
        </Menu.Item>
      ))}
    </Menu>);
  };

  const renderBloggersHeader = () => {
    return (
      <Row className={`${styles.bloggerString} ${styles.header}`}>
        <Col className={styles.id}>{'ID'}</Col>
        <Col className={styles.firstName}>{t('firstName')}</Col>
        <Col className={styles.lastName}>{t('lastName')}</Col>
        <Col className={styles.email}>{t('email')}</Col>
        <Col className={styles.site}>{t('blogUrl')}</Col>
        <Col className={styles.createdAt}>{t('createdAt')}</Col>
        <Col className={styles.status}>{t('status')}</Col>
        <Col className={styles.controls}>{t('controls')}</Col>
      </Row>
    );
  };

  const renderUsersHeader = () => {
    return (
      <Row className={`${styles.userString} ${styles.header}`}>
        <Col className={styles.id}>{'ID'}</Col>
        <Col className={styles.firstName}>{t('firstName')}</Col>
        <Col className={styles.lastName}>{t('lastName')}</Col>
        <Col className={styles.email}>{t('email')}</Col>
        <Col className={styles.createdAt}>{t('createdAt')}</Col>
        <Col className={styles.role}>{t('role')}</Col>
        <Col className={styles.status}>{t('status')}</Col>
        <Col className={styles.id}>{t('referralPublisher')}</Col>
        <Col className={styles.controls}>{t('controls')}</Col>
      </Row>
    );
  };

  const renderBloggers = () => {
    return (<>
      {displayedPublishers.map((blogger) => (
        <Row className={styles.bloggerString} key={blogger._id}>
          <Col className={styles.id} title={blogger._id}>{blogger._id}</Col>
          <Col className={styles.firstName} title={blogger.firstName}>{blogger.firstName}</Col>
          <Col className={styles.lastName} title={blogger.lastName}>{blogger.lastName}</Col>
          <Col className={styles.email} title={blogger.email}>{blogger.email}</Col>
          <Col className={styles.site}>
            <a href={blogger.blogUrl?.indexOf('http:') === 0 || blogger.blogUrl?.indexOf('https:') === 0
              ? blogger.blogUrl
              : `http://${blogger.blogUrl}`} title={blogger.blogUrl}>{blogger.blogUrl}
            </a>
          </Col>
          <Col className={styles.createdAt}>{format(new Date(blogger.createdAt), 'yyyy-MM-dd')}</Col>
          <Col className={styles.status}>{t(blogger.status)}</Col>
          <Col className={styles.controls}>
            {(blogger.status === 'registered' || blogger.status === 'rejected') && (
              <Button
                type="bareLink"
                className={styles.approveBtn}
                onClick={() => doApproveBlogger(blogger._id)}
              >
                {t('approve')}
              </Button>
            )}
            {(blogger.status === 'registered') && (
              <Button
                type="bareLink"
                className={styles.rejectBtn}
                onClick={() => doRejectBlogger(blogger._id)}
              >
                {t('reject')}
              </Button>
            )}
            {(blogger.status === 'approved') && (
              <Button
                type="bareLink"
                className={styles.redirectBtn}
                onClick={() => history.push(`/recipes-of-publisher/${blogger._id}`)}
              >
                {t('seeRecipes')}
              </Button>
            )}
            {(blogger.status !== 'deleted') && (
              <DeleteEntityModal
                entity={blogger}
                entityName={t('userEntity')}
                deleteFn={() => doDeleteBlogger(blogger._id)}
                modalTriggerComponent={({ onClick }) => (
                  <Button
                    type="bareLink"
                    className={styles.rejectBtn}
                    onClick={onClick}
                  >
                    {t('delete')}
                  </Button>
                )}
              />
            )}
          </Col>
        </Row>
      ))}
    </>);
  };

  const renderUsers = () => {
    return (<>
      {displayedUsers.map((user) => (
        <Row className={styles.userString} key={user._id}>
          <Col className={styles.id} title={user._id}>{user._id}</Col>
          <Col className={styles.firstName} title={user.firstName}>{user.firstName}</Col>
          <Col className={styles.lastName} title={user.lastName}>{user.lastName}</Col>
          <Col className={styles.email} title={user.email}>{user.email}</Col>
          <Col className={styles.createdAt}>{format(new Date(user.createdAt), 'yyyy-MM-dd')}</Col>
          <Col className={styles.role}>{user.role}</Col>
          <Col className={styles.status}>{t(user.status)}</Col>
          <Col className={styles.id}>{user.referral?.bloggerId ?? ''}</Col>
          <Col className={styles.controls}>
            {(user.status !== 'deleted') && (
              <DeleteEntityModal
                entity={user}
                entityName={t('userEntity')}
                deleteFn={() => doDeleteUser(user._id)}
                modalTriggerComponent={({ onClick }) => (
                  <Button
                    type="bareLink"
                    className={styles.rejectBtn}
                    onClick={onClick}
                  >
                    {t('delete')}
                  </Button>
                )}
              />
            )}
          </Col>
        </Row>
      ))}
    </>);
  };

  return (
    <>
      <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
        <Col className={styles.cardWrapper}>
          <Row type="flex" align="top" justify="start" className={styles.pageTitle}>
            <Col
              className={`${styles.option} ${mode === 'bloggers' ? styles.active : ''}`}
              onClick={() => setMode('bloggers')}
            >
              {t('publishersControl')}
            </Col>
            <Col
              className={`${styles.option} ${mode === 'users' ? styles.active : ''}`}
              onClick={() => setMode('users')}
            >
              {t('usersList')}
            </Col>
          </Row>
          <div className={styles.tableControlsBtnsWrapper}>
            <div className={styles.searchWrapper}>
              <Input
                placeholder={t('searchPublishers')}
                onChange={(event) => onQueryChange(event.target.value)}
                onPressEnter={onSearch}
                className={styles.filterInput}
                prefix={<Icon name="search" onClick={onSearch}/>}
              />
            </div>
            {mode === 'bloggers' ? (
              <>
                <ActionDropdown
                  triggerElement={(<Button type="secondary">
                    <FontAwesomeIcon icon="filter" className={styles.buttonsIcon} />{statusFilter ? t(statusFilter) : t('all')}
                  </Button>)}
                  listPlacement="bottomLeft"
                >
                  {renderStatusMenuItems()}
                </ActionDropdown>
                <Button type="primary" onClick={() => retrieveBloggers(1 + Math.floor((bloggers?.offset || 0) / PAGE_SIZE))}>
                  {t('refresh')}
                </Button>
              </>
            ) : (
              <>
                <ActionDropdown
                  triggerElement={(<Button type="secondary">
                    <FontAwesomeIcon icon="filter" className={styles.buttonsIcon} />{roleFilter ? t(roleFilter) : t('all')}
                  </Button>)}
                  listPlacement="bottomLeft"
                >
                  {renderRoleMenuItems()}
                </ActionDropdown>
                <Button type="primary" onClick={() => retrieveUsers(1 + Math.floor((bloggers?.offset || 0) / PAGE_SIZE))}>
                  {t('refresh')}
                </Button>
              </>
            )}
          </div>
          {mode === 'bloggers' ? (<>
            {renderBloggersHeader()}
            {renderBloggers()}
          </>) : (<>
            {renderUsersHeader()}
            {renderUsers()}
          </>)}
          <Row className={styles.paginationWrapper} type="flex" align="top" justify="center">
            <Pagination
              defaultPageSize={PAGE_SIZE}
              pageSize={PAGE_SIZE}
              total={(mode === 'bloggers' ? bloggers : users)?.total || 0}
              hideOnSinglePage
              onChange={mode === 'bloggers' ? retrieveBloggers : retrieveUsers}
              current={1 + Math.floor(((mode === 'bloggers' ? bloggers : users)?.offset || 0) / PAGE_SIZE)}
              responsive
            />
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

Publishers.propTypes = {
  bloggers: PropTypes.object,
  users: PropTypes.object,
  getBloggers: PropTypes.func.isRequired,
  approveBlogger: PropTypes.func.isRequired,
  rejectBlogger: PropTypes.func.isRequired,
  getBloggersWithDebounce: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUsersWithDebounce: PropTypes.func.isRequired,
  deleteUserByAdmin: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ admin: { bloggers, users } }) => ({
      bloggers, users
    }),
    { getBloggers, approveBlogger, rejectBlogger, getBloggersWithDebounce, getUsers, getUsersWithDebounce, deleteUserByAdmin }
  )
)(Publishers);
