import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faCartPlus,
  faCheck,
  faCog,
  faDollarSign,
  faEllipsisH,
  faEllipsisV,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilter,
  faInfoCircle,
  faPaperPlane,
  faPenAlt,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  faSignOutAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faUpload,
  faUser,
  faUsers,
  faUserPlus,
  faUserTimes,
  faCheckCircle,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

library.add(
  faApple,
  faArrowLeft,
  faArrowRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faCartPlus,
  faCheck,
  faDollarSign,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFacebook,
  faFileAlt,
  faFilter,
  faGoogle,
  faInfoCircle,
  faInstagram,
  faLinkedin,
  faPaperPlane,
  faPenAlt,
  faPencilAlt,
  faPlus,
  faTimes,
  faTwitter,
  faUpload,
  faUser,
  faUsers,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faSignOutAlt,
  faCog,
  faQuestionCircle,
  faThumbsDown,
  faThumbsUp,
  faTrash,
  faUserPlus,
  faUserTimes
);
