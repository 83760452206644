const defaultState = {
  user: null,
  isLoadedUser: false
};

export const actionTypes = {
  USER: 'USER',
  IS_LOADED_USER: 'IS_LOADED_USER'
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.USER:
      return {
        ...state,
        user: payload
      };

    case actionTypes.IS_LOADED_USER:
      return {
        ...state,
        isLoadedUser: payload
      };

    default:
      return state;
  }
}
