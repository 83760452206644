const defaultState = {
  recipes: undefined,
  suggestedRecipes: [],
  recipe: undefined,
  isLoadedRecipes: false,
  externalRecipe: undefined,
  rawExternalRecipe: undefined,
  externalSource: undefined,
  iFrameId: undefined
};

export const actionTypes = {
  RECIPES: 'RECIPES',
  SUGGESTED_RECIPES: 'SUGGESTED_RECIPES',
  RECIPE: 'RECIPE',
  EXTERNAL_RECIPE: 'EXTERNAL_RECIPE',
  RAW_EXTERNAL_RECIPE: 'RAW_EXTERNAL_RECIPE',
  EXTERNAL_SOURCE: 'EXTERNAL_SOURCE',
  IFRAME_ID: 'IFRAME_ID'
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.RECIPES:
      return {
        ...state,
        recipes: payload
      };
    case actionTypes.SUGGESTED_RECIPES:
      return {
        ...state,
        suggestedRecipes: payload
      };
    case actionTypes.RECIPE:
      return {
        ...state,
        recipe: payload
      };
    case actionTypes.EXTERNAL_RECIPE:
      return {
        ...state,
        externalRecipe: payload
      };
    case actionTypes.RAW_EXTERNAL_RECIPE:
      return {
        ...state,
        rawExternalRecipe: payload
      };
    case actionTypes.EXTERNAL_SOURCE:
      return {
        ...state,
        externalSource: payload
      };
    case actionTypes.IFRAME_ID:
      return {
        ...state,
        iFrameId: payload
      };
    default:
      return state;
  }
}

export const setRecipe = (payload) => ({
  type: actionTypes.RECIPE,
  payload
});

export const setExternalRecipe = (payload) => ({
  type: actionTypes.EXTERNAL_RECIPE,
  payload
});

export const setRawExternalRecipe = (payload) => ({
  type: actionTypes.RAW_EXTERNAL_RECIPE,
  payload
});

export const setExternalSource = (payload) => ({
  type: actionTypes.EXTERNAL_SOURCE,
  payload
});

export const setIFrameId = (payload) => ({
  type: actionTypes.IFRAME_ID,
  payload
});
