export default {
  login: 'Login',
  register: 'Register',
  howItWorks: 'How it works',
  areYouBlogger: 'Are you a food blogger?',

  mainTitle: 'Shopping list that is everywhere you are',
  mainDescription: 'Available on Mobile and Web, it is everywhere you need it.' +
      ' In the kitchen, in the grocery store, on your couch while surfing delicious recipes on the web.' +
      ' A shopping list everywhere you are.',
  createMyList: 'Create my shopping list',
  subHeader1: 'Everyone can add to the list',
  description1_1: 'Cookers, shoppers, eaters, basically everyone that cares, can all add to the list.',
  description1_2: 'Share your shopping list with spouses, roommates, children, everyone who might want to suggest' +
      ' a few items to get during the next trip to the store.',
  subHeader2: 'Don’t forget a thing',
  description2: 'Organized and simple functionality that ensures you don’t have to make another' +
      ' run back to the store because you forgot something.',
  subHeader3: 'Keep your favorite recipes in one place',
  description3: 'A great way to store your favorite recipes, rate them, take some notes' +
      ' (“needs more salt, added 1 tablespoon”).  And when you want to make it, send the ingredients to' +
      ' your shopping list with a click of a button!',
  privacy: 'Privacy',
  terms: 'Terms',
  cr: '© 2020 Zestary',
  moreTraffic: 'Drive More Traffic',
  returningUsersBackToYourSite: `Partner with Zestary to drive returning users back to your site.
    We promote recommended recipes from *your* site to *your* users, where they are most often, on their phones.
    This drives your users back to your site over and over again.`,
  joinZestary: 'Join Zestary',
  moreRevenue: 'Drive More Revenue',
  additionalPathToRevenue: `In addition to driving more traffic to your site, Zestary offers an additional path
    to drive revenue from your existing traffic.  Your recipe ingredients become a shopping list
    and drive revenue through third party online shopping sites.`,
  customerSatisfaction: 'Increase Customer Satisfaction',
  easierConvertIngredientsToShoppingList: 'Make it easy for your readers to convert the ingredients into a shopping list' +
    ' or order ingredients online. Increase the likelihood that readers will try your recipe and become lifelong fans.',
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  bloggerUrl: 'Website URL',
  submit: 'Submit',
  invalidEmail: 'Invalid email',
  enterEmail: 'Email is required',
  enterFirstName: 'First name is required',
  enterMaxName: 'Please enter no more than 40 characters',
  enterBloggerURL: 'Website URL is required',
  iAgree: 'I agree to Zestary’s Publishers Terms and Conditions'
};
