import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, height, className, id }) => {
  return (
    <img
      className={`${className ?? ''}`}
      src={`/icons/${name}.svg`}
      id={id ?? null}
      alt={name}
      style={ height ? { height: `${height}px`, width: 'auto' } : {} }
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  height: PropTypes.number,
  id: PropTypes.string
};

export default Icon;
