import { call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '../../../utils/functions';

const Api = {
  getRedirectToAmazonCart: (ingredients) => axios.post('/external/amazon', ingredients)
};

export const actionTypes = {
  GET_REDIRECT_TO_AMAZON_CART: 'GET_REDIRECT_TO_AMAZON_CART'
};

function* sagaGetRedirectToAmazonCart({ payload: { ingredients, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getRedirectToAmazonCart, ingredients);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getRedirectToAmazonCart = (payload) => ({ type: actionTypes.GET_REDIRECT_TO_AMAZON_CART, payload });

export default [
  takeLatest(actionTypes.GET_REDIRECT_TO_AMAZON_CART, sagaGetRedirectToAmazonCart)
];
