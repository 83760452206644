export default {
  termsOfService: 'Terms of service',
  howItWorks: 'How it works',
  areYouBlogger: 'Are you a food blogger?',
  terms: 'Terms',
  points: {
    p_1: '1. What do these terms cover?',
    p_1_1: '1.1 These user terms and the documents referred to in them (we refer to these as the Terms) govern:',
    p_1_1_1: '1.1.1 your access to and use of our services through Your Account with us, including our website' +
      ' www.zestary.com (our Site), all functionality on our Site, applications, buttons, browser plugins,' +
      ' application programming interfaces and accessing functionality on third parties who have integrated them' +
      ' into their experiences (we refer to these as the Zestary Services);',
    p_1_1_2: '1.1.2 the rules that apply to any recipes or other information, text, graphics,' +
      ' photos or other materials' +
      ' or content you upload to or otherwise make available on the Zestary Services' +
      ' (we refer to this as Your Content); and',
    p_1_1_3: '1.1.3 how you are entitled to use in any other rights and content,' +
      ' including: (i) our proprietary rights' +
      ' in the Zestary Services themselves; and (ii) any content appearing on the Zestary' +
      ' Services (we refer to this as Zestary Content).',
    p_1_2: '1.2 Please read these Terms carefully before you start to use the Zestary Services or create an account ' +
      'with us (which we refer to in these Terms as Your Account). By using the Zestary Services or creating' +
      ' Your Account, you confirm that you accept these Terms and that you will comply with them.' +
      ' If you do not agree to these Terms you are not entitled to use Zestary Services or to create Your Account' +
      ' and must refrain from doing so.',
    p_1_3: '1.3 You may use the Zestary Services as a guest without creating an account' +
      ' (in each case subject to these Terms), but if you do so you may not be able to access and use certain' +
      ' functionality within the Zestary Services.',
    p_2: '2. Who do these terms apply to?',
    p_2_1: '2.1 These Terms apply to you as an individual once you create Your Account or' +
      ' otherwise use the Zestary Services.' +
      ' These Terms do not apply to businesses contracting with us, and any such contracts will be on separate terms.'
  }
};
