import { call, put, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import axios from 'axios';

import { actionTypes as adminActionType } from '../reducers';
import { isFunc } from '../../../utils/functions';

const Api = {
  getUsersStatistics: () => axios.get('/user/statistics'),
  getBloggers: ({ query, type, limit, offset }) =>
    axios.get(`/user/bloggers/?limit=${limit ?? 1000}&offset=${offset ?? 0}${query && query.trim() !== '' ? `&search=${query}` : ''}
    ${type && type.trim() !== '' ? `&type=${type}` : ''}`),
  getUsers: ({ query, role, limit, offset }) =>
    axios.get(`/user/all?limit=${limit ?? 1000}&offset=${offset ?? 0}${query && query.trim() !== '' ? `&search=${query}` : ''}
    ${role && role.trim() !== '' ? `&role=${role}` : ''}`),
  getBlogger: (id) => axios.get(`/user/bloggers/${id}`),
  approveBlogger: (id) => axios.get(`/user/bloggers/${id}/approve`),
  rejectBlogger: (id) => axios.get(`/user/bloggers/${id}/reject`),
  retrievePage: (url) => axios.get(`/external/page?url=${url}`),
  getRecipePromotionSchedule: () => axios.get('/schedule/recipe-promotion'),
  updateRecipePromotionSchedule: (schedule) => axios.patch('/schedule/recipe-promotion', schedule),
  exportSuggestedItems: () => axios.get('/lists/suggested-items/export'),
  exportSuggestedUnits: () => axios.get('/lists/suggested-units/export'),
  exportCategories: () => axios.get('/lists/categories/export'),
  exportCategoryMapping: () => axios.get('/lists/category-mapping/export'),
  importSuggestedItems: ({ fileData, config }) => axios.post('/lists/suggested-items/import', fileData, config),
  importSuggestedUnits: ({ fileData, config }) => axios.post('/lists/suggested-units/import', fileData, config),
  importCategories: ({ fileData, config }) => axios.post('/lists/categories/import', fileData, config),
  importCategoryMapping: ({ fileData, config }) => axios.post('/lists/category-mapping/import', fileData, config),
  deleteUserByAdmin: (id) => axios.delete(`/user/${id}/spi`)
};

export const actionTypes = {
  GET_USERS_STATISTICS: 'GET_USERS_STATISTICS',
  GET_BLOGGERS: 'GET_BLOGGERS',
  GET_USERS: 'GET_USERS',
  GET_BLOGGER: 'GET_BLOGGER',
  GET_BLOGGERS_WITH_DEBOUNCE: 'GET_BLOGGERS_WITH_DEBOUNCE',
  GET_USERS_WITH_DEBOUNCE: 'GET_USERS_WITH_DEBOUNCE',
  APPROVE_BLOGGER: 'APPROVE_BLOGGER',
  REJECT_BLOGGER: 'REJECT_BLOGGER',
  RETRIEVE_PAGE: 'RETRIEVE_PAGE',
  GET_RECIPE_PROMOTION_SCHEDULE: 'GET_RECIPE_PROMOTION_SCHEDULE',
  UPDATE_RECIPE_PROMOTION_SCHEDULE: 'UPDATE_RECIPE_PROMOTION_SCHEDULE',
  EXPORT_SUGGESTED_ITEMS: 'EXPORT_SUGGESTED_ITEMS',
  EXPORT_SUGGESTED_UNITS: 'EXPORT_SUGGESTED_UNITS',
  EXPORT_CATEGORIES: 'EXPORT_CATEGORIES',
  EXPORT_CATEGORY_MAPPING: 'EXPORT_CATEGORY_MAPPING',
  IMPORT_FILE: 'IMPORT_FILE',
  DELETE_USER_BY_ADMIN: 'DELETE_USER_BY_ADMIN'
};

function* sagaGetUsersStatistics({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getUsersStatistics);
    yield put({ type: adminActionType.USERS_STATISTICS, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetBloggers({ payload: { query, type, limit, offset, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getBloggers, { query, type, limit, offset });
    yield put({ type: adminActionType.BLOGGERS, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetUsers({ payload: { query, role, limit, offset, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getUsers, { query, role, limit, offset });
    yield put({ type: adminActionType.USERS, payload: data });
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetBlogger({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getBlogger, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaApproveBlogger({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.approveBlogger, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRejectBlogger({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.rejectBlogger, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteUserByAdmin({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.deleteUserByAdmin, id);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRetrievePage({ payload: { url, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.retrievePage, url);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetRecipePromotionSchedule({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getRecipePromotionSchedule);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateRecipePromotionSchedule({ payload: { onSuccess, onError, schedule } }) {
  try {
    const { data } = yield call(Api.updateRecipePromotionSchedule, schedule);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaExportSuggestedItems({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.exportSuggestedItems);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaExportSuggestedUnits({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.exportSuggestedUnits);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaExportCategories({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.exportCategories);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaExportCategoryMapping({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.exportCategoryMapping);
    if (isFunc(onSuccess)) onSuccess(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaImportFile({ payload: { file, dataType, onSuccess, onError } }) {
  try {
    const fileData = new FormData();
    fileData.append('file', file);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let api;
    switch (dataType) {
      case 'SuggestedItems':
        api = Api.importSuggestedItems;
        break;
      case 'SuggestedUnits':
        api = Api.importSuggestedUnits;
        break;
      case 'Categories':
        api = Api.importCategories;
        break;
      case 'CategoryMapping':
        api = Api.importCategoryMapping;
        break;
      default:
        if (isFunc(onError)) onError('Incorrect data type');
        return;
    }
    if (api) {
      const response = yield call(api, { fileData, config });
      if (isFunc(onSuccess)) onSuccess(response.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getUsersStatistics = (payload) => ({ type: actionTypes.GET_USERS_STATISTICS, payload });
export const getBloggers = (payload) => ({ type: actionTypes.GET_BLOGGERS, payload });
export const getUsers = (payload) => ({ type: actionTypes.GET_USERS, payload });
export const getBlogger = (payload) => ({ type: actionTypes.GET_BLOGGER, payload });
export const getBloggersWithDebounce = (payload) => ({ type: actionTypes.GET_BLOGGERS_WITH_DEBOUNCE, payload });
export const getUsersWithDebounce = (payload) => ({ type: actionTypes.GET_USERS_WITH_DEBOUNCE, payload });
export const approveBlogger = (payload) => ({ type: actionTypes.APPROVE_BLOGGER, payload });
export const rejectBlogger = (payload) => ({ type: actionTypes.REJECT_BLOGGER, payload });
export const retrievePage = (payload) => ({ type: actionTypes.RETRIEVE_PAGE, payload });
export const getRecipePromotionSchedule = (payload) => ({ type: actionTypes.GET_RECIPE_PROMOTION_SCHEDULE, payload });
export const updateRecipePromotionSchedule = (payload) => ({ type: actionTypes.UPDATE_RECIPE_PROMOTION_SCHEDULE, payload });
export const exportSuggestedItems = payload => ({ type: actionTypes.EXPORT_SUGGESTED_ITEMS, payload });
export const exportSuggestedUnits = payload => ({ type: actionTypes.EXPORT_SUGGESTED_UNITS, payload });
export const exportCategories = payload => ({ type: actionTypes.EXPORT_CATEGORIES, payload });
export const exportCategoryMapping = payload => ({ type: actionTypes.EXPORT_CATEGORY_MAPPING, payload });
export const importData = payload => ({ type: actionTypes.IMPORT_FILE, payload });
export const deleteUserByAdmin = payload => ({ type: actionTypes.DELETE_USER_BY_ADMIN, payload });

export default [
  takeLatest(actionTypes.GET_USERS_STATISTICS, sagaGetUsersStatistics),
  takeLatest(actionTypes.GET_BLOGGERS, sagaGetBloggers),
  takeLatest(actionTypes.GET_USERS, sagaGetUsers),
  takeLatest(actionTypes.GET_BLOGGER, sagaGetBlogger),
  debounce(500, actionTypes.GET_BLOGGERS_WITH_DEBOUNCE, sagaGetBloggers),
  debounce(500, actionTypes.GET_USERS_WITH_DEBOUNCE, sagaGetUsers),
  takeEvery(actionTypes.APPROVE_BLOGGER, sagaApproveBlogger),
  takeEvery(actionTypes.REJECT_BLOGGER, sagaRejectBlogger),
  takeLatest(actionTypes.RETRIEVE_PAGE, sagaRetrievePage),
  takeLatest(actionTypes.GET_RECIPE_PROMOTION_SCHEDULE, sagaGetRecipePromotionSchedule),
  takeLatest(actionTypes.UPDATE_RECIPE_PROMOTION_SCHEDULE, sagaUpdateRecipePromotionSchedule),
  takeLatest(actionTypes.EXPORT_SUGGESTED_ITEMS, sagaExportSuggestedItems),
  takeLatest(actionTypes.EXPORT_SUGGESTED_UNITS, sagaExportSuggestedUnits),
  takeLatest(actionTypes.EXPORT_CATEGORIES, sagaExportCategories),
  takeLatest(actionTypes.EXPORT_CATEGORY_MAPPING, sagaExportCategoryMapping),
  takeEvery(actionTypes.IMPORT_FILE, sagaImportFile),
  takeEvery(actionTypes.DELETE_USER_BY_ADMIN, sagaDeleteUserByAdmin)
];
