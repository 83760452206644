import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'antd';
import styles from './styles.module.scss';
import { Header, Footer } from '../../../landing/components';
import { Button } from '../../../common/components';
import history from '../../../../utils/history';

const PrivacyPolicy = () => {
  const { t } = useTranslation('privacy-policy');

  const availableModes = [
    { key: 'landing', title: t('howItWorks'), width: 107 },
    { key: 'blogger', title: t('areYouBlogger'), width: 169 }
  ];

  return (
      <div className={styles.pageWrapper}>
        <Header mode="privacy" availableModes={availableModes} />
        <Row type="flex" justify="center" align="top">
          <div className={styles.contentWrapper}>
            <p className={styles.mainTitle}>{t('privacyPolicy')}</p>
            <div className={styles.pageContent}>
              <p>{t('updatedOn')}</p>
              <p className={styles.subHeader}>ABOUT</p>
              <p>
                The vision of Sluice, LLC DBA Zestary.com and its affiliates (“Zestary,” “we,” “us,” and “our”) is to make shopping
                and cooking easier. As such, we provide a shopping list and recipe keeper platform that you can use, among other things,
                to create grocery lists, keep track of favorite recipes, and collaborate around food and cooking. This Privacy Policy
                is designed to help you understand how we collect, use and share your personal information and to assist you
                in exercising the privacy rights available to you.
              </p>
              <p className={styles.subHeader}>SCOPE</p>
              <p>
                This Privacy Policy applies to personal information processed by us in our business, including on our websites
                (e.g., zestary.com, grillingcompanion.com and any other websites that we own or operate), our mobile applications,
                our application program interface(s), and our related online and offline offerings (collectively, the “Services”).
              </p>
              <p>
                This Privacy Policy does not apply to any third-party websites, services or applications, even if they are
                accessible through our Services. In addition, capitalized terms not defined in this Privacy Policy will have
                the meaning set forth in our Terms of Service.
              </p>
              <p>
                Please note that if you use the Services on behalf of an organization (e.g., your employer), Zestary’s use of your personal
                information may be governed by the agreement we have with that organization. However, we will still process your
                information consistent with this Privacy Policy.
              </p>
              <p className={styles.subHeader}>PERSONAL INFORMATION WE COLLECT</p>
              <p>
                The categories of personal information we collect depend on how you interact with our Services.
              </p>
              <p className={styles.pointHeader}>Information You Provide to Us</p>
              <p>
                Account Information. When you create an Account, we will collect personal information, such as your name,
                email address, personal website, and picture.
              </p>
              <p>
                Payment Information. We may sell services or merchandise through our Services. When you make purchases through the Services,
                we may process your payments through a third-party application, such as the Apple App Store, Google Play App Store,
                Amazon App Store, social networking sites such as Facebook, and/or payment processing services such as Stripe.
                The third-party application may collect certain financial information from you to process a payment on behalf of Zestary,
                including your name, email address, address, payment card information, and other billing information.
                Zestary does not store or receive your payment information, but it may store and receive information associated with
                your payment information (e.g., your billing details).
              </p>
              <p>
                Your Communications with Us. We collect personal information from you such as email address, phone number,
                or mailing address when you request information about our Services, register for our newsletter,
                or otherwise communicate with us.
              </p>
              <p>
                Surveys. We may contact you to participate in surveys. If you decide to participate,
                you may be asked to provide certain information, which may include personal information.
              </p>
              <p>
                Interactive Features. We may offer interactive features such as collaboration tools, recipe notes, forums, blogs,
                social media pages and the ability to react, follow, observe in real-time and comment on users’ content.
                We, and others who use our Services, may collect the information you submit or make available through these interactive
                features. Any content you provide on the public sections of these features will be considered “public” and is not subject
                to the privacy protections referenced herein. By using these interactive features, you assume the risk that the personal
                information provided by you may be viewed and used by third parties for their own purposes.
              </p>
              <p>
                Customer Service. If you call or otherwise interact with Zestary’s customer service and support, we may collect the
                information you provide to our representatives. In addition, we may record telephone calls or video conferences
                between you and our representatives for training or quality assurance purposes.
              </p>
              <p>
                Note that, in accordance with
                our <Button type="bareLink" onClick={() => history.push('/terms')}>Terms of Service</Button> Terms
                of Service, Zestary is only for users 13 and over.
              </p>
              <p>
                Registration for Sweepstakes or Contests. We may run sweepstakes and contests. Contact information you provide may be
                used to reach you about the sweepstakes or contest and for other promotional, marketing and business purposes,
                if permitted by law. In some jurisdictions, we are required to publicly share information about the winners.
              </p>
              <p>
                Meetups, Conferences, Trade Shows, and Other Events. We may host or attend meetups, conferences, trade shows,
                and other events where we collect personal information from individuals who interact with or express an interest in
                Zestary and/or the Services. If we collect your information at one of these events, we will use it for the purposes
                for which it was collected.
              </p>
              <p>
                Business Development and Strategic Partnerships. We may collect personal information from individuals and
                third parties to assess and pursue potential business opportunities.
              </p>
              <p>
                Job Applications. We may post job openings and opportunities on the Services. If you reply to one of these postings
                by submitting your application, CV and/or cover letter to us, we will collect and process the information contained
                therein to assess your suitability, aptitude, skills, and qualifications for employment with Zestary.
              </p>
              <p className={styles.pointHeader}>Information Collected Automatically</p>
              <p>
                Automatic Data Collection. We may collect certain information automatically when you use the Services.
                This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers,
                mobile advertising and other unique identifiers, details about your browser, operating system or device,
                location information (including inferred location based off of your IP address), Internet service provider,
                pages that you visit before, during and after using the Services, information about the links you click,
                and information about how you interact with and use the Services.
              </p>
              <p>
                We may use a visitor’s IP address to fight spam, malware and identity theft.
              </p>
              <p>
                Cookies, Pixel Tags/Web Beacons, and Analytics Information. We, as well as third parties that may provide content,
                advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other
                technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially
                small data files placed on your device that allow us and our partners to record certain pieces of information
                whenever you visit or interact with our Services.
              </p>
              <ul>
                <li>
                  Cookies. Cookies are small text files placed in device browsers to store their preferences.
                  Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.
                </li>
                <li>
                  Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects
                  information about engagement on the Services. The use of a pixel tag allows us to record, for example, that a user has
                  visited a particular web page or clicked on a particular advertisement. We may also include web beacons in
                  e-mails to understand whether messages have been opened, acted on, or forwarded.
                </li>
              </ul>
              <p>
                This Policy applies to all personal data we collect or process about you in the context
                of your use of our services. Zestary is the data controller for the processing of personal data.
              </p>
              <p>
                Analytics. We may also use Google Analytics and other service providers to collect and process analytics
                information on our Services. For more information about how Google uses data, please
                visit <a href={'http://www.google.com/policies/privacy/partners/'}>www.google.com/policies/privacy/partners/</a>. You can
                opt out of Google Analytics’ collection and processing of data generated by your use of our website by
                going to <a href={'http://tools.google.com/dlpage/gaoptout'}>http://tools.google.com/dlpage/gaoptout</a>.
              </p>
              <p className={styles.pointHeader}>Information from Other Sources</p>
              <p>
                Zestary Customers. If you use our Services on behalf of an organization (e.g., your employer),
                that organization may provide us with information about you so that we can provision your account.
              </p>
              <p>
                Third Party Services and Organizations. We may obtain information about you from other sources, including from
                third party services and organizations. For example, if you access our Services through a third-party service,
                we may collect information about you from that third-party service that you have made available via your privacy settings.
              </p>
              <p className={styles.subHeader}>HOW WE USE YOUR INFORMATION</p>
              <p>
                We use your personal information for a variety of business purposes, including:
              </p>
              <p>
                To Provide the Services or Information Requested, such as:
              </p>
              <ul>
                <li>
                  Managing your information and Account;
                </li>
                <li>
                  Fulfilling our contract with you or the organization on whose behalf you use the Services;
                </li>
                <li>
                  Responding to questions, comments, and other requests;
                </li>
                <li>
                  Processing payment card and/or other financial information to facilitate your use of the Services;
                </li>
                <li>
                  Providing access to certain areas, functionalities, and features of our Services;
                </li>
                <li>
                  Answering requests for customer or technical support; and
                </li>
                <li>
                  Allowing you to register for events.
                </li>
              </ul>
              <p>
                Administrative Purposes, such as:
              </p>
              <ul>
                <li>
                  Pursuing legitimate interests, such as direct marketing, research and development (including marketing research),
                  network and information security, and fraud prevention;
                </li>
                <li>
                  Pursuing legitimate interests, such as direct marketing, research and development (including marketing research),
                  network and information security, and fraud prevention;
                </li>
                <li>
                  Measuring interest and engagement in our Services;
                </li>
                <li>
                  Improving the Services;
                </li>
                <li>
                  Developing new products and services;
                </li>
                <li>
                  Ensuring internal quality control and safety;
                </li>
                <li>
                  Authenticating and verifying individual identities;
                </li>
                <li>
                  Carrying out audits;
                </li>
                <li>
                  Communicating with you about your account, activities on our Services and Privacy Policy changes;
                </li>
                <li>
                  Preventing and prosecuting potentially prohibited or illegal activities;
                </li>
                <li>
                  Enforcing our agreements; and
                </li>
                <li>
                  Complying with our legal obligations.
                </li>
              </ul>
              <p>
                Marketing Our Products and Services. We may use personal information to tailor and provide you with
                content and advertisements. We may provide you with these materials as permitted by applicable law
              </p>
              <p>
                If you have any questions about our marketing practices or if you would like to opt out of the use of your personal
                information for marketing purposes, you may contact us at any time as set forth below.
              </p>
              <p>
                Consent. We may use personal information for other purposes that are clearly disclosed to you at the time you
                provide personal information or with your consent.
              </p>
              <p>
                De-identified and Aggregated Information Use. We may use personal information and other data about you to create
                de-identified and/or aggregated information, such as de-identified demographic information, de-identified
                location information, information about the device from which you access our Services, or other analyses we create.
                De-identified and/or aggregated information is not personal information, and we may use and disclose such information
                in a number of ways, including research, industry analysis, analytics, and any other legally permissible purposes.
              </p>
              <p>
                Share Content with Friends or Colleagues. Our Services may offer various tools and functionalities that allow you
                to share content. For example, we may allow you to provide information about your friends through shopping list
                sharing services. Our shopping list sharing services may allow you to forward or share certain content with a friend or
                colleague, such as an email inviting your friend to use our Services.
              </p>
              <p>
                How We Use Automatic Collection Technologies. We, as well as third parties that may provide content, advertising,
                or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies to automatically
                collect information through the Services. Our uses of these Technologies fall into the following general categories:
              </p>
              <ul>
                <li>
                  Operationally Necessary. This includes Technologies that allow you access to our Services, applications,
                  and tools that are required to identify irregular site behavior, prevent fraudulent activity and improve
                  security or that allow you to make use of our functionality;
                </li>
                <li>
                  Performance Related. We may use Technologies to assess the performance of our Services,
                  including as part of our analytic practices to help us understand how our visitors use the Services;
                </li>
                <li>
                  Functionality Related. We may use Technologies that allow us to offer you enhanced functionality when accessing
                  or using our Services. This may include identifying you when you sign into our Services or keeping track of your
                  specified preferences, interests, or past items viewed;
                </li>
                <li>
                  Advertising or Targeting Related. We may use first party or third-party Technologies to deliver content,
                  including ads relevant to your interests, on our Services or on third party sites.
                </li>
              </ul>
              <p>
                Please note that this Privacy Policy covers only our use of Technologies and does not govern the use of Technologies
                by any third parties.
              </p>
              <p>
                Cross-Device Tracking. Your browsing activity may be tracked across different websites and different devices or apps.
                For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your
                laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device
                identifiers, and we may match the information of the browser and devices that appear to be used by the same person.
              </p>
              <p className={styles.subHeader}>DISCLOSING YOUR INFORMATION TO THIRD PARTIES</p>
              <p>
                We may share any personal information we collect with the following categories of third parties:
              </p>
              <p>
                Other Users of Zestary’s Services. When you use the Services, we will share certain information with other users.
              </p>
              <ul>
                <li>
                  For Collaboration. You can create content, which may contain information about you, and grant permission to others
                  to see, share, edit, copy and download that content based on settings you or your administrator (if applicable) select.
                  Some of the collaboration features of the Services display some or all of your profile information to other Service users
                  when you share or interact with specific content. For example, when you share, we display your profile picture
                  and name as part of the grocery list you share so that other users with access to the list understand who shared it.
                  Please be aware that some content can be published or otherwise made publicly available, including information about you,
                  can be publicly viewed and indexed by and returned in search results of search engines. We are not responsible for
                  privacy practices of the other users who may view and use the posted information.
                </li>
                <li>
                  Food Blogger Accounts and Administrators. If you register or access the Services using an email address with a domain
                  that is owned by your employer or organization, or associate that email address with your existing account and such
                  organization wishes to establish a Zestary services account, certain information about you including your name,
                  profile picture, contact info, content, and account use may become accessible to that organization’s administrator and
                  other Zestary service users, as permitted by your administrator, to provide you additional products and services or to
                  integrate with Zestary or other products and services. Please note that your information may also be subject to your
                  organization’s privacy policy. We are not responsible for the privacy or security practices of our customers.
                </li>
              </ul>
              <p>
                Service Providers. We may share any personal information we collect about you with our third-party service providers.
                The categories of service providers to whom we entrust personal information include service providers for:
                (i) the provision of the Services; (ii) the provision of information, products, and other services you have requested;
                (iii) marketing and advertising; (iv) payment and transaction processing; (v) customer service activities;
                and (vi) the provision of IT and related services.
              </p>
              <p>
                Third-Party Platforms and Services. We will share your personal information with third-party platforms and/or services
                if you have expressly consented or requested that we do so. Please note we do not endorse, screen or approve,
                and are not responsible for, the practices or conduct of such third-party services.
              </p>
              <p>
                Business Partners. We may provide personal information to business partners to provide you with a product or service
                you have requested. We may also provide personal information to business partners with whom we
                jointly offer products or services.
              </p>
              <p>
                Affiliates. We may share personal information with our affiliated entities.
              </p>
              <p>
                Advertising Partners. Through our Services, we may allow third party advertising partners to set Technologies
                and other tracking tools to collect information regarding your activities and your device (e.g., your IP address,
                cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this information
                (and similar information collected from other websites) for purposes of delivering targeted advertisements to you
                when you visit third party services within their networks. This practice is commonly referred to
                as “interest-based advertising” or “personalized advertising.” If you prefer not to share your personal information
                with third party advertising partners, you may follow the instructions below.
              </p>
              <p>
                Disclosures to Protect Us or Others. We may access, preserve, and disclose any information we have associated
                with you if we believe doing so is required or appropriate to: (i) comply with law enforcement or national security
                requests and legal process, such as a court order or subpoena; (ii) protect your, our or others’ rights, property,
                or safety; (iii) to enforce Zestary’s policies and contracts; (iv) to collect amounts owed to us; (v) when we believe
                disclosure is necessary or appropriate to prevent financial loss or in connection with an investigation or prosecution
                of suspected or actual illegal activity; or (vi) if we, in good faith, believe that disclosure
                is otherwise necessary or advisable.
              </p>
              <p>
                Disclosure in the Event of Merger, Sale, or Other Asset Transfer. If we are involved in a merger, acquisition,
                financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service
                to another provider, then your information may be sold or transferred as part of such a transaction,
                as permitted by law and/or contract.
              </p>
              <p className={styles.subHeader}>INTERNATIONAL DATA TRANSFERS</p>
              <p>
                All information processed by us may be transferred, processed, and stored anywhere in the world,
                including but not limited to, the United States or other countries, which may have data protection laws that are different
                from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.
              </p>
              <p className={styles.subHeader}>YOUR CHOICES</p>
              <p>
                General. You may have the right to opt out of certain uses of your personal information.
              </p>
              <p>
                Email. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email
                to opt out of receiving future emails. Note that you will continue to receive transaction-related emails
                regarding products or Services you have requested. We may also send you certain non-promotional communications regarding
                us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding
                the Services or updates to our Terms of Service or this Privacy Policy).
              </p>
              <p>
                Mobile Devices. We may send you push notifications through our mobile application. You may at any time opt-out from
                receiving these types of communications by changing the settings on your mobile device. With your consent,
                we may also collect precise location-based information if you use our mobile application.
                You may opt-out of this collection by changing the settings on your mobile device.
              </p>
              <p>
                “Do Not Track”. Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers.
                Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
              </p>
              <p>
                Cookies and Interest-Based Advertising. You may stop or restrict the placement of Technologies on your device or
                remove them by adjusting your preferences as your browser or device permits. Please note that cookie-based opt-outs
                are not effective on mobile applications. However, you may opt-out of personalized advertisements on some mobile
                applications by following the instructions
                for <a href={'https://support.google.com/googleplay/android-developer/answer/6048248?hl=en'}>Android</a> and
                <a href={'https://support.apple.com/en-us/HT202074'}>iOS</a>.
              </p>
              <p>
                The online advertising industry also provides websites from which you may opt out of receiving targeted ads from
                data partners and other advertising partners that participate in self-regulatory programs.
                You can access these websites and learn more about targeted advertising and consumer choice and privacy,
                at <a href={'https://www.networkadvertising.org/managing/opt_out.asp'}>www.networkadvertising.org/managing/opt_out.asp</a>,
                <a href={'https://www.youronlinechoices.eu/'}>http://www.youronlinechoices.eu/</a>,
                <a href={'https://youradchoices.ca/choices/'}>https://youradchoices.ca/choices/</a>,
                and <a href={'https://www.aboutads.info/choices/'}>www.aboutads.info/choices/</a>.
              </p>
              <p>
                Please note you must separately opt out in each browser and on each device.
              </p>
              <p className={styles.subHeader}>YOUR PRIVACY RIGHTS</p>
              <p>
                For purposes of the California Consumer Privacy Act, we do not “sell” your personal information.
              </p>
              <p>
                In accordance with applicable law, you may have the right to: (i) request confirmation of whether we are processing
                your personal information; (ii) obtain access to or a copy of your personal information; (iii) receive an electronic
                copy of personal information that you have provided to us, or ask us to send that information to another company
                (the “right of data portability”); (iv) object to or restrict our uses of your personal information; (v) seek correction
                or amendment of inaccurate, untrue, incomplete, or improperly processed personal information; (vi) withdraw your consent
                and (vii) request erasure of personal information held about you by us, subject to certain exceptions prescribed by law.
                If you would like to exercise any of these rights, please contact us as set forth below.
              </p>
              <p>
                We will process such requests in accordance with applicable laws. To protect your privacy,
                we will take steps to verify your identity before fulfilling your request.
              </p>
              <p>
                If you are a California resident, you have the right not to receive discriminatory treatment by Zestary for the exercise
                of your rights conferred by the California Consumer Privacy Act.
              </p>
              <p>
                Please note that if you use our Services on behalf of an organization (e.g., your employer),
                that organization may be responsible for fulfilling the individual rights requests referenced above.
              </p>
              <p className={styles.subHeader}>DATA RETENTION</p>
              <p>
                We store the personal information we receive as described in this Privacy Policy for as long as you use our Services
                or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes,
                establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements,
                and comply with applicable laws.
              </p>
              <p className={styles.subHeader}>SECURITY OF YOUR INFORMATION</p>
              <p>
                We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy.
                Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us.
                To the fullest extent permitted by applicable laws, we do not accept liability for unauthorized disclosure.
              </p>
              <p>
                By using the Services or providing personal information to us, you agree that we may communicate with you electronically
                regarding security, privacy, and administrative issues relating to your use of the Services.
                If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services,
                by mail or by sending an email to you.
              </p>
              <p className={styles.subHeader}>THIRD PARTY WEBSITES/APPLICATIONS</p>
              <p>
                The Services may contain links to other websites/applications and other websites/applications may
                reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read
                the privacy policies of each website and application with which they interact. We do not endorse, screen or approve,
                and are not responsible for, the privacy practices or content of such other websites or applications.
                Visiting these other websites or applications is at your own risk.
              </p>
              <p className={styles.subHeader}>CHILDREN’S INFORMATION</p>
              <p>
                The Services are not directed to children under 13 (or other age as required by local law),
                and we do not knowingly collect personal information from children. If you learn that your child has provided
                us with personal information without your consent, you may contact us as set forth below. If we learn that we have
                collected a child’s personal information in violation of applicable law, we will promptly take steps to delete such
                information and terminate the child’s account.
              </p>
              <p className={styles.subHeader}>SUPERVISORY AUTHORITY</p>
              <p>
                If you are located in the European Economic Area or the United Kingdom, you have the right to lodge a complaint with
                a supervisory authority if you believe our processing of your personal information violates applicable law.
              </p>
              <p className={styles.subHeader}>CHANGES TO OUR PRIVACY POLICY</p>
              <p>
                We may revise this Privacy Policy from time to time at our sole discretion. If there are any material changes
                to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you
                will be deemed to have accepted the updated Privacy Policy if you continue to use the Services
                after the new Privacy Policy takes effect.
              </p>
              <p className={styles.subHeader}>CONTACT US</p>
              <p>
                If you have any questions about our privacy practices or this Privacy Policy, or if you wish to submit a request
                to exercise your rights as detailed in this Privacy Policy, please contact us at:
              </p>
              <p>Zestary, Inc.</p>
              <p>3813 West Corona Street</p>
              <p>Tampa, Florida 33629</p>
              <p>Email: <a href={'mailto:info@Zestary.com'}>info@Zestary.com</a></p>
            </div>
          </div>
        </Row>
        <Footer mode="privacy" />
      </div>
  );
};

export default PrivacyPolicy;
