import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox, notification, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'universal-cookie';
//import { uz } from 'unitz-ts';

import styles from './styles.module.scss';
import {
  removeItemFromList,
  updateItemInList,
  restoreItemsInList,
  shareList,
  updateList,
  deleteList
} from '../../sagas/listsSagas';
import { setAvailableLists, setActiveList } from '../../reducers';
import { ActionDropdown, Button, DeleteEntityModal, Icon } from '../../../common/components';
import { useModal } from '../../../common/hooks';
import { ShareListForm, EditModal, ItemEditor, ItemCreator, OnlineShopsSelector } from '..';
import history from '../../../../utils/history';
import { groupItems } from '../../helpers';
//import { parse, combine } from 'recipe-ingredient-parser-v2';

let shareListForm = null;
const cookies = new Cookies();

const Items = (props) => {
  const {
    activeList,
    availableLists,
    removeItemFromList,
    categories,
    shareList,
    updateItemInList,
    restoreItemsInList,
    updateList,
    deleteList,
    existingNames,
    setAvailableLists,
    setActiveList,
    setMode,
    isMobile,
    relatedRecipes,
    collaborators,
    user
    /*,
    setRelatedRecipes*/
  } = props;
  const { t } = useTranslation('lists');

  const [creating, setCreating] = useState(false);
  const [alphabetItems, setAlphabetItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [recipeItems, setRecipeItems] = useState([]);
  const [sortType, setSortType] = useState('alphabet');
  const [isAvailable, setIsAvailable] = useState(false);
  const [itemUnderEditing, setItemUnderEditing] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);// { name, purchased, groupName }

  const [isValid, setIsValid] = useState(false);

  const bindSubmit = submitFormFn => !shareListForm && (shareListForm = submitFormFn);
  const bindIsValid = isValid => setTimeout(() => setIsValid(isValid));

  const submitForm = () => {
    return shareListForm && shareListForm();
  };

  useEffect(() => {
    if (activeList?.items?.length && categories?.length) {
      const { items } = activeList;
      const sortedItems = items.sort((a, b) => (`${a.name}`).localeCompare(b.name));
      groupItems(t, sortedItems, [
        { setGroup: setAlphabetItems },
        { setGroup: setRecipeItems, groupContainer: relatedRecipes, fieldName: 'recipe', groupsNameField: 'title' },
        { setGroup: setCategoryItems, groupContainer: categories, fieldName: 'category' }
      ]);
    } else {
      setAlphabetItems([]);
      setRecipeItems([]);
      setCategoryItems([]);
    }
  }, [activeList, categories, relatedRecipes]); //eslint-disable-line

  useEffect(() => {
    setIsAvailable(!!availableLists?.find((al) => al._id === activeList?._id));
  }, [activeList, availableLists]); //eslint-disable-line

  useEffect(() => {
    const sorting = cookies.get('sortType');
    if (sorting && ['alphabet', 'category', 'recipe'].includes(sorting)) setSortType(sorting);
    //if (window.showWalmartByNowButton) window.showWalmartByNowButton();
  }, []); // eslint-disable-line

  const updateSortType = (newSortType) => {
    setSortType(newSortType);
    cookies.set('sortType', newSortType, { sameSite: true, secure: true });
  };

  const editSuccessCallback = (data) => {
    const cleanedListsList = availableLists.filter((al) => al._id !== data._id);
    setAvailableLists([data, ...cleanedListsList]);
  };

  const editErrorCallback = () => {
    notification.error({ message: t('listUpdateFailed') });
  };

  const setNewExpandedItem = (itemSignature) => {
    const newItem = (!expandedItem
    || (expandedItem.name !== itemSignature.name
      || !!expandedItem.purchased !== !!itemSignature.purchased
      || (expandedItem.groupName !== itemSignature.groupName
      )))
      ? itemSignature
      : null;
    setExpandedItem(newItem);
  };

  function onShareList({ email }) {
    shareListForm = null;
    shareList({
      inviteData: { email, list: activeList._id },
      onSuccess: (data) => {
        data.message && notification.success({ message: data.message });
      }
    });
  }

  const onDeleteList = (list) => {
    deleteList({
      id: list._id,
      onSuccess: () => {
        if (list._id === activeList?._id) setActiveList(undefined);
        notification.info({ message: t('listDeleted') });
        const cleanedListsList = availableLists.filter((al) => al._id !== list._id);
        setAvailableLists(cleanedListsList);
      },
      onError: () => {
        notification.error({ message: t('listDeletionFailed') });
      }
    });
  };

  const {
    Modal: ShareListModal,
    showModal
  } = useModal({
    body: {
      title: t('shareThisList'),
      titleAlignment: 'left',
      Component: ShareListForm,
      componentProps: {
        bindSubmit,
        bindIsValid,
        onSubmit: onShareList,
        list: activeList,
        t,
        collaborators,
        user
      }
    },
    ok: {
      onClick: submitForm,
      title: t('share'),
      isDisabled: !isValid
    },
    modal: {
      destroyOnClose: true
    },
    closable: true,
    maskClosable: true
  });

  const {
    Modal: BuyOnlineModal,
    showModal: showBuyOnlineModal
  } = useModal({
    body: {
      title: t('buyOnline'),
      titleAlignment: 'left',
      Component: OnlineShopsSelector,
      componentProps: {
        list: Object.assign({}, activeList),
        user
      }
    },
    ok: {
      isHide: true
    },
    modal: {
      destroyOnClose: true
    },
    closable: true,
    maskClosable: true
  });

  const purchaseItem = (item, checked) => {
    const updatedItem = item;
    updatedItem.purchased = !!checked;
    updateItemInList({
      listId: activeList._id,
      itemData: updatedItem
    });
  };

  const purchaseItems = (items, checked) => {
    items.forEach((item) => {
      purchaseItem(item, checked);
    });
  };

  const restoreItems = () => {
    restoreItemsInList({ listId: activeList._id });
  };

  const removeItem = (itemId) => {
    removeItemFromList({
      listId: activeList._id,
      itemId
    });
  };

  const removeItems = (items) => {
    items.forEach((item) => {
      removeItem(item._id);
    });
  };

  const editItem = (itemId) => {
    setItemUnderEditing(itemId);
  };

  const renderRecipe = (recipe) => {
    const recipeObj = relatedRecipes.find((r) => r._id === recipe);

    return (<>
      {!!recipeObj && (
        <Row className={styles.recipeContent} >
          <Col className={styles.recipeImage} onClick={() => history.push(`/recipes/${recipe}`)}>
            <img className={styles.image} height={24} width={40} src={recipeObj?.image?.indexOf("url('") === 0
              ? recipeObj.image.substr(5, recipe.image.length - 7)
              : recipeObj?.image
            } alt="Dish" />
          </Col>
          <Col className={styles.recipeTitle} onClick={() => history.push(`/recipes/${recipe}`)}>{recipeObj?.title}</Col>
        </Row>
      )}
    </>);
  };

  const renderItem = (item, groupName, isInner = false, index) => {
    if (item._id === itemUnderEditing) {
      return (
        <Row
          className={`${styles.item} 
          ${styles.editor} 
          ${isInner ? styles.inner : ''} 
          ${index === 0 ? styles.first : ''}`}
          key={item._id}
          align="middle"
          justify="start"
        >
          <ItemEditor
            item={item}
            creating={creating}
            setCreating={setCreating}
            isAvailable={isAvailable}
            setItemUnderEditing={setItemUnderEditing}
          />
        </Row>
      );
    }
    return (
      <section key={item._id}>
        <Row
          className={`${styles.item} 
          ${item.purchased ? styles.purchased : ''} 
          ${isInner ? styles.inner : ''} 
          ${index === 0 ? styles.first : ''}`}
          key={item._id}
          align="middle"
          justify="start"
        >
          <Row type="flex" align="middle" className={styles.checkBoxWrapper}>
            <Checkbox
              checked={item.purchased}
              onChange={item.isMerged
                ? ((e) => purchaseItems(item.items, e.target.checked))
                : ((e) => purchaseItem(item, e.target.checked))}
              disabled={!isAvailable}
            />
          </Row>
          <Col>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.amount}>
              {item.isMerged ? (
                item.amounts
              ) : (
                <>
                  {`${item.amount ? `${item.amount} ` : (item.unit ? 1 : '')}${item.unit?.pluralName && item.amount !== 1
                    ? item.unit.pluralName
                    : (item.unit?.name?.trim?.() ?? '')}`}
                </>
              )}
            </span>
            {(item.isMerged || item.recipes?.length > 0 || item.recipe) && (<span className={styles.point}>•</span>)}
            {item.isMerged && (
              <span onClick={() => setNewExpandedItem({ name: item.name, purchased: item.purchased, groupName })}>
              {item.recipes?.length > 0 && (<>
                <span onClick={() => setNewExpandedItem({ name: item.name, purchased: item.purchased, groupName })}>
                  <Icon
                    name="small-golden-book"
                    className={styles.recipeLink}
                  />
                </span>
                  <span className={styles.numberOfRecipes}>
                  {item.recipes.length}
                </span>
              </>)}
              <span
                className={styles.recipesCaret}
                onClick={() => setNewExpandedItem({ name: item.name, purchased: item.purchased, groupName })}
              >
                <FontAwesomeIcon icon="angle-down" />
              </span>
            </span>
            )}
          </Col>
          {(!!item.recipe && sortType !== 'recipe') && (
            <Row type="flex" align="middle" className={styles.recipeBlock} onClick={() => history.push(`/recipes/${item.recipe}`)}>
              {renderRecipe(item.recipe)}
            </Row>
          )}
          <Col className={styles.controlsWrapper}>
            {!item.isMerged && (
              <Button
                type="icon"
                onClick={() => editItem(item._id)}
                disabled={creating || !isAvailable}
              >
                <Icon name="edit" />
              </Button>
            )}
            <Button type="icon"
                    onClick={item.isMerged ? (() => removeItems(item.items)) : (() => removeItem(item._id)) }
                    disabled={creating || !isAvailable}
            >
              <Icon name="trash" />
            </Button>
          </Col>
        </Row>
      </section>
    );
  };

  const renderPotentiallyComplexItem = (item, groupName, index) => {
    const shouldBeExpanded = item.isMerged
      && expandedItem
      && expandedItem.name === item.name
      && !!expandedItem.purchased === !!item.purchased
      && expandedItem.groupName === groupName;

    return item.isMerged ? (
      (shouldBeExpanded ? [item, ...item.items] : [item]).map((complexItem, idx) => renderItem(complexItem, groupName, idx > 0, index))
    ) : renderItem(item, null, false, index);
  };

  const renderNotGrouped = () => (
    <>
      <Row className={styles.subHeader} type="flex" align="middle" justify="space-between">
        <Col>{t('myItems')}</Col>
        <Col />
      </Row>
      <div className={styles.itemGroup}>
        {alphabetItems.filter((item) => !item.purchased).map((item, index) =>
          renderPotentiallyComplexItem(item, null, index))}
      </div>
    </>
  );

  const renderGrouped = (groups) => (
    <>
      {groups.map((group, idx) => (
        <div key={idx}>
          {!!group.items.filter((item) => !item.purchased).length && (<>
            <Row className={styles.subHeader} type="flex" align="middle" justify="space-between">
              <Row type="flex" align="middle" justify="start">
                <Col>{group.groupName}</Col>
                {(sortType === 'recipe' && getFirstItemsRecipe(group)) && (
                  <Row
                    type="flex"
                    align="middle"
                    className={styles.recipeBlock}
                    onClick={() => history.push(`/recipes/${getFirstItemsRecipe(group)}`)}
                  >
                    {renderRecipe(getFirstItemsRecipe(group))}
                  </Row>
                )}
              </Row>
              <Col />
            </Row>
          </>)}
          <div className={styles.itemGroup}>
            {group.items.filter((item) => !item.purchased).map((item, index) =>
              renderPotentiallyComplexItem(item, group.groupName, index))}
          </div>
        </div>
      ))}
    </>
  );

  const getFirstItemsRecipe = (group) => {
    const firstItem = group.items.filter((item) => !item.purchased)?.[0];
    return firstItem?.recipe ?? firstItem?.recipes?.[0];
  };

  const renderGrouping = () => {
    return (
      <Button type="icon" className={styles.sortBtn}>
        <Icon name={sortType === 'category' ? 'sort' : sortType === 'recipe' ? 'dish' : 'sort-by-alphabet'} />
        {t(`grouping.${sortType}`)}
      </Button>
    );
  };

  const renderSmallAvatar = (collaborator, position) => {
    return (
      <div key={collaborator._id}>
        {collaborator.picture?.value ? (
          <img
            width="32"
            height="32"
            className={`${styles.smallAvatar}`}
            src={collaborator.picture.value}
            alt="collaborator avatar"
            style={{ left: `${position}px` }}
          />
        ) : (
          <div className={`${styles.smallAvatar}`} style={{ backgroundColor: '#FEBD2E', left: `${position}px` }}>
            {collaborator.firstName?.trim()?.[0] ?? ''}{collaborator.lastName?.trim()?.[0] ?? ''}
          </div>
        )}
      </div>
    );
  };

  const renderAvatarsWrapper = (collaborators) => {
    const width = Math.min(140, 20 + 32 * (collaborators.length + 1));
    const delta = Math.min(120 / (collaborators.length + 1), 32);
    return (
      <div className={styles.avatarsWrapper} style={{ width }}>
        {renderSmallAvatar(user, 10)}
        {collaborators.map((collaborator, idx) => {
          return renderSmallAvatar(collaborator, (idx + 1) * delta + 10);
        })}
      </div>
    );
  };

  const renderBuyOnlineBtn = () => {
    return (
      <div>
        <Button type="primary" className={styles.buyOnlineBtn} onClick={showBuyOnlineModal}>
          <FontAwesomeIcon icon="cart-plus" className={styles.menuIcon} />
          {t('buyOnline')}
        </Button>
      </div>
    );
  };

  return (<>{
    activeList
      ? (
      <div>
        <Row type="flex" justify="space-between" align="middle" className={styles.headingString}>
          {isMobile ? (
            <Col className={`${styles.listName} ${styles.mobile}`} onClick={() => setMode('lists')}>
              <div>
                <FontAwesomeIcon icon="angle-left" className={styles.backIcon} />{activeList.name}
              </div>
              {renderBuyOnlineBtn()}
            </Col>
          ) : (
            <Col className={styles.listName}>
              <div>{activeList.name}</div>
              {renderBuyOnlineBtn()}
            </Col>
          )}
          <Col className={styles.listControl}>
            {!isMobile && (
              <>
                <Button type="icon" onClick={showModal} disabled={!isAvailable} >
                  <Icon name="group-add" />
                </Button>
                {(collaborators && user) && renderAvatarsWrapper(collaborators)}
              </>
            )}
            <ActionDropdown
              triggerElement={(<Button type="icon" disabled={!isAvailable} ><Icon name="more-vert" /></Button>)}
              listPlacement="bottomRight"
              disabled={!isAvailable}
            >
              <Menu className="template-action-menu">
                <Menu.Item key={-400}>
                  <EditModal
                    list={activeList}
                    title={t('editListTitle')}
                    onSubmitFn={updateList}
                    existingNames={existingNames}
                    successCallback={editSuccessCallback}
                    errorCallback={editErrorCallback}
                    modalTriggerComponent={({ onClick }) => (
                      <div onClick={onClick}>
                        <Icon name="edit" className={styles.menuIcon}/>
                        {t('editList')}
                      </div>
                    )}
                  />
                </Menu.Item>
                <Menu.Item key={-401} disabled>
                  <div onClick={() => {}}>
                    <Icon name="duplicate" className={styles.menuIcon}/>
                    {t('duplicateList')}
                  </div>
                </Menu.Item>
                <Menu.Item key={-402}>
                  <div onClick={showModal}>
                    <Icon name="share" className={styles.menuIcon}/>
                    {t('shareList')}
                  </div>
                </Menu.Item>
                <Menu.Item key={-405}>
                  <div onClick={showBuyOnlineModal}>
                    <FontAwesomeIcon icon="cart-plus" className={styles.menuIcon} />
                    {t('buyOnline')}
                  </div>
                </Menu.Item>
                <Menu.Item key={-403}>
                  <DeleteEntityModal
                    entity={activeList}
                    entityName={t('list')}
                    deleteFn={onDeleteList}
                    modalTriggerComponent={({ onClick }) => (
                      <div onClick={onClick}>
                        <Icon name="trash" className={styles.menuIcon}/>
                        {t('deleteList')}
                      </div>
                    )}
                  />
                </Menu.Item>
              </Menu>
            </ActionDropdown>

          </Col>
        </Row>
        {activeList.description?.trim?.() && (
          <Row type="flex" justify="start" align="middle" className={styles.headingString}>
            <Col className={styles.listDescription}>
              {activeList.description}
            </Col>
          </Row>
        )}
        {/*<div className="walmart-buy-now" pubid="PD0Em256765"
             data-item-walmartid="51691170|2,47232163|1"
             data-color="orange"
             data-size="default">
        </div>*/}
        <Row type="flex" justify="space-between" align="middle" className={styles.headingString}>
          <ItemCreator
            creating={creating}
            setCreating={setCreating}
            isAvailable={isAvailable}
            setItemUnderEditing={setItemUnderEditing}
          />
          <Row type="flex" justify="end" align="middle" className={styles.grouperWrapper}>
            <ActionDropdown triggerElement={renderGrouping()} listPlacement="bottomRight">
              <Menu className="template-action-menu">
                {sortType !== 'alphabet' && (
                  <Menu.Item key={-300}>
                    <div onClick={() => updateSortType('alphabet')}>
                      <Icon name="sort-by-alphabet" className={styles.menuIcon}/>
                      {t('grouping.alphabet')}
                    </div>
                  </Menu.Item>
                )}
                {sortType !== 'category' && (
                  <Menu.Item key={-301}>
                    <div onClick={() => updateSortType('category')}>
                      <Icon name="sort" className={styles.menuIcon}/>
                      {t('grouping.category')}
                    </div>
                  </Menu.Item>
                )}
                {sortType !== 'recipe' && (
                  <Menu.Item key={-302}>
                    <div onClick={() => updateSortType('recipe')}>
                      <Icon name="dish" className={styles.menuIcon}/>
                      {t('grouping.recipe')}
                    </div>
                  </Menu.Item>
                )}
              </Menu>
            </ActionDropdown>
          </Row>
        </Row>
        <div className={styles.itemsContainer}>
          {alphabetItems.length ? (
            <>
              {sortType === 'alphabet'
                ? renderNotGrouped()
                : renderGrouped(sortType === 'category' ? categoryItems : recipeItems)
              }
              {alphabetItems.find((item) => !!item.purchased) && (
                <Row className={styles.subHeader} type="flex" align="middle" justify="space-between">
                  <Col>{t('purchased')}</Col>
                  <Col>
                    <ActionDropdown
                      triggerElement={(<Button type="icon" disabled={!isAvailable} ><Icon name="more-horiz" /></Button>)}
                      listPlacement="bottomRight"
                      disabled={!isAvailable}
                    >
                      <Menu className="template-action-menu">
                        <Menu.Item key={-500} disabled={!isAvailable}>
                          <div onClick={restoreItems}>
                            <Icon name="check" className={styles.menuIcon}/>
                            {t('restoreAll')}
                          </div>
                        </Menu.Item>
                      </Menu>
                    </ActionDropdown>
                  </Col>
                </Row>
              )}
              <div className={styles.itemGroup}>
                {alphabetItems.filter((item) => !!item.purchased).map((item) =>
                  renderPotentiallyComplexItem(item, null))}
              </div>
            </>
          ) : (
            <div className={styles.noItems}>
              <div className={styles.subheader}>{t('keepListsInOnePlace')}</div>
              <div className={styles.description}>{t('groupAndSort')}</div>
              <div className={styles.description}>{t('startAddingManually')}</div>
              <Button
                type="secondary"
                onClick={() => history.push('recipes')}
                className={styles.toRecipesBtn}
                disabled={!isAvailable}
              >
                <Icon name="golden-book" />
                {t('importFromRecipe')}
              </Button>
            </div>
          )}
        </div>
      </div>
      )
      : (
        <Row type="flex" justify="start" align="middle" className={styles.headingString}>
          <Col className={styles.listName}>{t('noListSelected')}</Col>
        </Row>
      )
  }{ShareListModal}{BuyOnlineModal}</>);
};

Items.propTypes = {
  activeList: PropTypes.object,
  availableLists: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  recipes: PropTypes.object,
  shareList: PropTypes.func.isRequired,
  restoreItemsInList: PropTypes.func.isRequired,
  removeItemFromList: PropTypes.func.isRequired,
  updateItemInList: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  setAvailableLists: PropTypes.func.isRequired,
  setActiveList: PropTypes.func.isRequired,
  existingNames: PropTypes.arrayOf(PropTypes.string),
  setMode: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  relatedRecipes: PropTypes.arrayOf(PropTypes.object),
  setRelatedRecipes: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user }, lists: { activeList, availableLists, sharedLists, categories, collaborators } }) => ({
      user, activeList, availableLists, sharedLists, categories, collaborators
    }),
    {
      removeItemFromList,
      updateItemInList,
      restoreItemsInList,
      shareList,
      updateList,
      deleteList,
      setAvailableLists,
      setActiveList
    }
  )
)(Items);
