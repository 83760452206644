import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, /*Menu,*/ Pagination, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { format, max } from 'date-fns';
import { useParams, withRouter } from 'react-router-dom';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { /*ActionDropdown,*/ Button, Footer, Icon } from '../../../common/components';
import { getBloggersRecipes, getBloggersRecipesWithDebounce, promoteRecipe, getPromotionsInfo } from '../../sagas';
import { getBroughtUsersCounts } from '../../../user/sagas';
import history from '../../../../utils/history';

const Dashboard = ({
  getBloggersRecipes,
  getBloggersRecipesWithDebounce,
  user,
  promoteRecipe,
  getPromotionsInfo,
  getBroughtUsersCounts
}) => {
  const { publisherId } = useParams();
  const { t } = useTranslation('recipe');
  const PAGE_SIZE = 10;
  const [displayedRecipes, setDisplayedRecipes] = useState({});
  const [currentSearchParameter, setCurrentSearchParameter] = useState('');
  const [broughtUsers, setBroughtUsers] = useState(undefined);

  useEffect(() => {
    retrieveBloggersRecipes();
  }, []); // eslint-disable-line

  const retrieveSuggestions = (result) => {
    const recipeIds = result.entities.map((recipe) => recipe._id);
    if (recipeIds.length) {
      getPromotionsInfo({
        recipeIds,
        onSuccess: (suggestions) => {
          //TODO define number of brought users
          getBroughtUsersCounts({
            body: {
              publisherId: publisherId ?? user._id,
              recipeIds
            },
            onSuccess: (counts) => {
              setBroughtUsers(counts.broughtCount);
              const extendedRecipes = result.entities.map((recipe) => {
                const promotions = suggestions.filter((suggestion) => suggestion.recipe === recipe._id);
                const formattedRecipe = { ...recipe, promotions };

                if (Array.isArray(counts.byRecipes)) {
                  formattedRecipe.broughtCount = counts.byRecipes.find((count) => count.id === recipe._id)?.count ?? 0;
                }

                return formattedRecipe;
              });
              setDisplayedRecipes({ ...result, entities: extendedRecipes });
            },
            onError: () => {
              notification.error({ message: t('unableGetBroughtCounts') });
              const extendedRecipes = result.entities.map((recipe) => {
                const promotions = suggestions.filter((suggestion) => suggestion.recipe === recipe._id);
                return { ...recipe, promotions };
              });
              setDisplayedRecipes({ ...result, entities: extendedRecipes });
            }
          });
        }
      });
    } else {
      setDisplayedRecipes({ ...result, entities: result.entities.map((recipe) => { return { ...recipe, promotions: [] }; }) });
    }
  };

  const retrieveBloggersRecipes = (page = 1) => {
    getBloggersRecipes({
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      query: currentSearchParameter,
      publisherId,
      onSuccess: (result) => {
        retrieveSuggestions(result);
      }
    });
  };

  const onQueryChange = (query) => {
    setCurrentSearchParameter(query);
    getBloggersRecipesWithDebounce({
      limit: PAGE_SIZE,
      offset: 0,
      query,
      publisherId,
      onSuccess: (result) => retrieveSuggestions(result)
    });
  };

  const onSearch = (query) => {
    setCurrentSearchParameter(query);
    getBloggersRecipes({
      limit: PAGE_SIZE,
      offset: 0,
      query,
      publisherId,
      onSuccess: (result) => retrieveSuggestions(result)
    });
  };

  const onPromoteRecipe = (id) => {
    promoteRecipe({
      id,
      onSuccess: (/*data*/) => {
        retrieveBloggersRecipes(1);
      },
      onError: () => notification.error({ message: t('unablePromoteRecipe') })
    });
  };

  const getGlobalRating = (recipe) => {
    const sum = recipe.rating?.reduce?.((acc, r) => (r.score + acc), 0) ?? 0;
    const votes = recipe.rating?.length ?? 0;
    return votes > 0 ? +(sum / votes).toFixed(2) : 0;
  };

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getLatestPromotionDate = (promotions) => {
    const dates = promotions.map((promotion) => new Date(promotion.createdAt)).filter(Boolean);
    return dates.length ? format(max(dates), 'yyyy-MM-dd') : '?';
  };

  const getNotificationsInfoString = (promotions) => {
    const sent = promotions.reduce((acc, promotion) => acc + (promotion.notificationsSent ?? 0), 0);
    const delivered = promotions.reduce((acc, promotion) => acc + (promotion.notificationsDelivered ?? 0), 0);
    const clicked = promotions.reduce((acc, promotion) => acc + (promotion.notificationsClickedBy ?? []).length, 0);
    return `${sent}/${delivered}/${clicked}`;
  };

  const getSuggestionDisplayedToNumber = (promotions) => {
    return promotions.reduce((acc, promotion) => acc + (promotion.seenBy ?? []).length, 0);
  };

  const getExplicitlyRejectedByNumber = (promotions) => {
    return promotions.reduce((acc, promotion) => acc + (promotion.rejectedBy ?? []).length, 0);
  };

  const renderBloggersRecipes = () => {
    return (<>
      {displayedRecipes?.entities?.map((recipe) => (
        <div className={styles.recipeCard} key={recipe._id}>
          <Row type="flex" justify="space-between" align="middle">
            <Col className={styles.title} title={recipe.title}>{recipe.title}</Col>
            {user.role !== 'admin' && (
              <Col className={styles.promote}>
                <Button
                  type="bareLink"
                  className={styles.promoteBtn}
                  disabled={recipe.setToPromote}
                  onClick={() => onPromoteRecipe(recipe._id)}
                >
                  {recipe.setToPromote ? t('selectedForPromotion') : t('promote')}
                </Button>
              </Col>
            )}
          </Row>
          <div>
            <a href={recipe.referenceUrl?.indexOf('http:') === 0 || recipe.referenceUrl?.indexOf('https:') === 0
              ? recipe.referenceUrl
              : `http://${recipe.referenceUrl}`} title={recipe.referenceUrl}>{recipe.referenceUrl}
            </a>
          </div>
          <Row type="flex" justify="start" align="middle" className={styles.dataString}>
            <Col>
              <span className={styles.label}>{t('created')}:</span>
              {format(new Date(recipe.createdAt), 'yyyy-MM-dd')}
            </Col>
            <Col>
              <span className={styles.label}>{t('nUsers')}:</span>
              {recipe.owners?.length > 0 ? recipe.owners?.filter?.(distinct).length - 1 : 0}
            </Col>
            <Col>
              <span className={styles.label}>{t('rating')}:</span>
              {getGlobalRating(recipe) || '?'}/5
            </Col>
            <Col>
              <span className={styles.label}>{t('nVotes')}:</span>
              {recipe.rating?.filter?.(distinct).length ?? 0}
            </Col>
            <Col>
              <span className={styles.label}>{t('linkTransitions')}:</span>
              {recipe.referenceUrlClicksCount ?? 0}/{recipe.clickedOnReferenceUrl?.length ?? 0}
            </Col>
            <Col>
              <span className={styles.label}>{t('broughtUsers')}:</span>
              {recipe.broughtCount ?? '?'}
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle" className={styles.dataString}>
            {recipe.promotions?.length ? (
              <>
                <Col>
                  <span className={styles.label}>{t('promotions')}:</span>
                  {recipe.promotions.length}
                </Col>
                <Col>
                  <span className={styles.label}>{t('latestPromotion')}:</span>
                  {getLatestPromotionDate(recipe.promotions)}
                </Col>
                <Col>
                  <span className={styles.label}>{t('notificationsSent')}:</span>
                  {getNotificationsInfoString(recipe.promotions)}
                </Col>
                <Col>
                  <span className={styles.label}>{t('seenAsSuggested')}:</span>
                  {getSuggestionDisplayedToNumber(recipe.promotions)}
                </Col>
                <Col>
                  <span className={styles.label}>{t('rejectedBy')}:</span>
                  {getExplicitlyRejectedByNumber(recipe.promotions)}
                </Col>
              </>
            ) : (
              t('neverPromoted')
            )}
          </Row>
        </div>
      ))}
    </>);
  };

  return (
    <>
      <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
        <Col className={styles.cardWrapper}>
          {user.role === 'admin' && (
            <div className={styles.linkBack}>
              <Button type="bareLink" onClick={() => history.push('/users')}>
                {t('backToListOfPublishers')}
              </Button>
            </div>
          )}
          <div className={styles.pageTitle}>{user.role === 'admin' ? t('publishersRecipes') : t('dashboard')}</div>
          <div className={styles.statisticsString}>
            {user.role === 'admin' ? t('publisherId') : t('yourId')}: {user.role === 'admin' ? publisherId : user._id}
          </div>
          <div className={styles.statisticsString}>{t('nSentToZestary')}: {displayedRecipes?.total || 0}</div>
          <div className={styles.statisticsString}>{t('broughtToZestary')}: {broughtUsers ?? '?'}</div>
          <div className={styles.tableControlsBtnsWrapper}>
            <div className={styles.searchWrapper}>
              <Input
                placeholder={t('searchRecipes')}
                onChange={(event) => onQueryChange(event.target.value)}
                onPressEnter={onSearch}
                className={styles.filterInput}
                prefix={<Icon name="search" onClick={onSearch}/>}
              />
            </div>
            <Button type="primary" onClick={() => retrieveBloggersRecipes(1 + Math.floor((displayedRecipes?.offset || 0) / PAGE_SIZE))}>
              {t('refresh')}
            </Button>
          </div>
          {renderBloggersRecipes()}
        </Col>
        <Row className={styles.paginationWrapper} type="flex" align="top" justify="center">
          <Pagination
            defaultPageSize={PAGE_SIZE}
            pageSize={PAGE_SIZE}
            total={displayedRecipes?.total || 0}
            hideOnSinglePage
            onChange={retrieveBloggersRecipes}
            current={1 + Math.floor((displayedRecipes?.offset || 0) / PAGE_SIZE)}
            responsive
          />
        </Row>
      </Row>
      <Footer />
    </>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object,
  getBloggersRecipes: PropTypes.func.isRequired,
  getBloggersRecipesWithDebounce: PropTypes.func.isRequired,
  promoteRecipe: PropTypes.func.isRequired,
  getPromotionsInfo: PropTypes.func.isRequired,
  getBroughtUsersCounts: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user } }) => ({
      user
    }),
    { getBloggersRecipes, getBloggersRecipesWithDebounce, promoteRecipe, getPromotionsInfo, getBroughtUsersCounts }
  ),
  withRouter
)(Dashboard);
