import React from 'react';
import { AuthRoute, FreeRoute } from '../auth/components';

import { Recipes, SingleRecipe, Dashboard, IngredientImporter, Instructions } from './pages';

import locales from './locales';
import sagas from './sagas';
import reducers from './reducers';

export default {
  routes: [
    <AuthRoute key="recipes" exact path="/recipes" component={Recipes} />,
    <AuthRoute key="recipe" exact path="/recipes/:recipeId" component={SingleRecipe}/>,
    <AuthRoute key="dashboard" exact path="/dashboard" authorization={['blogger']} component={Dashboard}/>,
    <AuthRoute key="dashboard" exact path="/recipes-of-publisher/:publisherId" authorization={['admin']} component={Dashboard}/>,
    <AuthRoute key="instructions" exact path="/instructions" authorization={['blogger']} component={Instructions}/>,
    <FreeRoute key="ingredient-import" exact path="/ingredient-import" component={IngredientImporter} shouldHideMainLayoutWrapper/>
  ],
  locales: { ns: 'recipe', resources: locales },
  menuItems: [
    {
      name: 'recipes',
      path: '/recipes',
      width: 74
    },
    {
      name: 'dashboard',
      authorization: ['blogger'],
      path: '/dashboard',
      width: 94
    },
    {
      name: 'instructions',
      authorization: ['blogger'],
      path: '/instructions',
      width: 104
    }
  ],
  sagas,
  reducers: { recipe: reducers }
};
