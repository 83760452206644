import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Upload, notification, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { object, string } from 'yup';
import { Formik } from 'formik';
import ImgCrop from 'antd-img-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import './styles.global.scss';

import { /*deleteUser,*/ updateUser } from '../../../user/sagas';
import { Button, FieldAdapter as Field, RenderField } from '../../../common/components';
import { useModal } from '../../../common/hooks';
import { ChangePasswordForm } from '..';
import { withDimensions } from '../../../common/HOC';

let submitPasswordForm = null;
const { TextArea } = Input;

const General = props => {
  const {
    user,
    updateUser,
    dimensions,
    values: { firstName, lastName, zipCode },
    setValues,
    handleSubmit,
    isValid
  } = props;
  const { t } = useTranslation('account');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  useEffect(() => {
    if (user) setValues({ firstName: user.firstName, lastName: user.lastName, zipCode: user.location?.zipCode ?? '' });
    setCurrentDescription(user.description ?? '');
    }, [user]); //eslint-disable-line

  const bindPasswordSubmit = submitFormFn => !submitPasswordForm && (submitPasswordForm = submitFormFn);
  const bindIsPasswordValid = isPasswordValid => setTimeout(() => { setIsPasswordValid(isPasswordValid); });

  const submitPassword = () => {
    return submitPasswordForm && submitPasswordForm();
  };

  function onChangePasswordConfirmed({ password, newPassword }) {
    submitPasswordForm = null;
    updateUser({
      userData: { password: newPassword, oldPassword: password },
      userId: user._id,
      onSuccess: hideChangePasswordModal
    });
  }

  const onDescriptionChange = event => setCurrentDescription(event.target.value);

  const onSaveDescription = () => {
    props.updateUser({
      userId: props.user._id,
      userData: { description: currentDescription ?? '' }
    });
  };

  const {
    Modal: ChangePasswordModal,
    showModal: showChangePasswordModal,
    hideModal: hideChangePasswordModal
  } = useModal({
    body: {
      title: t('changePasswordTitle'),
      titleAlignment: 'left',
      Component: ChangePasswordForm,
      componentProps: {
        bindSubmit: bindPasswordSubmit,
        bindIsValid: bindIsPasswordValid,
        onSubmit: onChangePasswordConfirmed,
        t
      }
    },
    ok: {
      onClick: submitPassword,
      title: t('save'),
      isDisabled: !isPasswordValid
    },
    modal: {
      destroyOnClose: true
    },
    closable: true,
    maskClosable: true
  });

  const showErrorImageProcessingNotification = () => {
    notification.error({ message: t('unableProcessImage') });
  };

  const beforeUpload = (file/*, list*/) => {
    setIsImageUploading(true);
    processCroppedImage(file);
    return false;
  };

  const beforeLogoUpload = (file/*, list*/) => {
    setIsLogoUploading(true);
    processCroppedLogo(file);
    return false;
  };

  const processCroppedImage = file => {
    const maxSize = 160;
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target && !event.target.error && event.target.readyState === 2) {
        const imageBase64Origin = event.target.result;
        const originalImageSize = imageBase64Origin.length;

        try {
          const image = new Image();
          image.onload = () => {
            try {
              const originalImage = image;
              const originalHeight = originalImage.naturalHeight;
              const originalWidth = originalImage.naturalWidth;
              const biggerSize = Math.max(originalHeight, originalWidth);
              const scalingCoef = Math.min(maxSize / biggerSize, 1);
              const encodedImageToUpload =
                getScaledImage(originalHeight, originalWidth, scalingCoef, originalImage, originalImageSize, imageBase64Origin, file.type);

              //console.log('Processed image size:', processedlImageSize);

              updateUser({
                userData: { picture: { value: encodedImageToUpload, type: 'base64' } },
                userId: user._id,
                onSuccess: () => {
                  setIsImageUploading(false);
                },
                onError: () => {
                  setIsImageUploading(false);
                }
              });
            } catch (error2) {
              console.log('Unable to process the image:', error2);
              showErrorImageProcessingNotification();
            }
          };
          image.src = imageBase64Origin;
        } catch (error) {
          console.log('Unable to process the image:', error);
          showErrorImageProcessingNotification();
        }
      } else {
        console.log(
          'Unable to process the image:',
          event.target && event.target.error ? event.target.error : 'unknown reason'
        );
        showErrorImageProcessingNotification();
      }
    };
    reader.readAsDataURL(file);
  };

  const processCroppedLogo = file => {
    const maxHeight = 200;
    const maxWidth = 600;
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target && !event.target.error && event.target.readyState === 2) {
        const imageBase64Origin = event.target.result;
        const originalImageSize = imageBase64Origin.length;

        try {
          const image = new Image();
          image.onload = () => {
            try {
              const originalImage = image;
              const originalHeight = originalImage.naturalHeight;
              const originalWidth = originalImage.naturalWidth;
              const scalingCoef = Math.min(maxHeight / originalHeight, maxWidth / originalWidth, 1);
              const encodedImageToUpload =
                getScaledImage(originalHeight, originalWidth, scalingCoef, originalImage, originalImageSize, imageBase64Origin, file.type);

              //console.log('Processed image size:', processedImageSize);

              updateUser({
                userData: { blogLogo: { value: encodedImageToUpload, type: 'base64' } },
                userId: user._id,
                onSuccess: () => {
                  setIsLogoUploading(false);
                },
                onError: () => {
                  setIsLogoUploading(false);
                }
              });
            } catch (error2) {
              console.log('Unable to process the image:', error2);
              showErrorImageProcessingNotification();
            }
          };
          image.src = imageBase64Origin;
        } catch (error) {
          console.log('Unable to process the image:', error);
          showErrorImageProcessingNotification();
        }
      } else {
        console.log(
          'Unable to process the image:',
          event.target && event.target.error ? event.target.error : 'unknown reason'
        );
        showErrorImageProcessingNotification();
      }
    };
    reader.readAsDataURL(file);
  };

  const getScaledImage = (originalHeight, originalWidth, scalingCoef, originalImage, originalImageSize, imageBase64Origin, mimeType) => {
    const targetHeight = Math.round(originalHeight * scalingCoef);
    const targetWidth = Math.round(originalWidth * scalingCoef);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = targetWidth;
    canvas.height = targetHeight;
    ctx.drawImage(originalImage, 0, 0, targetWidth, targetHeight);

    const processedImageBase64 = canvas.toDataURL(mimeType, 0.925);
    const processedImageSize = processedImageBase64.length;

    return scalingCoef >= 0.99
      ? processedImageSize < originalImageSize
        ? processedImageBase64
        : imageBase64Origin
      : processedImageBase64;
  };

  const renderImageUploader = () => (
    <div className={'ImageUploaderWrapper'}>
      <ImgCrop rotate shape="round">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={[]}
          beforeUpload={beforeUpload}
          disabled={isLogoUploading || isImageUploading}
        >
          <Button
            disabled={isLogoUploading || isImageUploading}
            type="secondary"
            htmlType="button"
            onClick={() => {}}
          >
            <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
            {t('changeProfilePhoto')}
          </Button>
        </Upload>
      </ImgCrop>
    </div>
  );

  const renderPublisherLogoUploader = () => (
    <div className={'ImageUploaderWrapper'}>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={[]}
        beforeUpload={beforeLogoUpload}
        disabled={isLogoUploading || isImageUploading}
      >
        <Button
          disabled={isLogoUploading || isImageUploading}
          type="secondary"
          htmlType="button"
          onClick={() => {}}
        >
          <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
          {t('changePublisherLogo')}
        </Button>
      </Upload>
    </div>
  );

  const renderUserAvatar = () => {
    return (
      <img
        width="160"
        height="160"
        className={`${styles.image}`}
        src={user.picture?.value ?? '/avatar.svg'}
        alt="user avatar"
      />
    );
  };

  const renderPublisherLogo = () => {
    return (
      <img
        className={`${styles.logoImage}`}
        src={user.blogLogo?.value ?? '/zestary.svg'}
        alt="blog logo"
      />
    );
  };

  return (
    <>
      <div className={styles.generalCard}>
        <Row type="flex" align="top" justify="start" className={styles.sectionHeader}>
          <Col>
            {t('editProfile')}
          </Col>
        </Row>
        {!dimensions?.isScreenBiggerLG && (
          <>
            <Row type="flex" align="top" justify="center">
              {renderUserAvatar()}
            </Row>
            <Row type="flex" justify="center" align="top" className={styles.buttonsWrapper}>
              {renderImageUploader()}
            </Row>
            {user?.role === 'blogger' && (
              <>
                <Row type="flex" align="top" justify="center">
                  {renderPublisherLogo()}
                </Row>
                <Row type="flex" justify="center" align="top" className={styles.buttonsWrapper}>
                  {renderPublisherLogoUploader()}
                </Row>
              </>
            )}
          </>
        )}
        <Row type="flex" align="top" justify="center">
          {dimensions?.isScreenBiggerLG && (
            <Col className={styles.imageWrapper}>
              {renderUserAvatar()}
              <Row type="flex" justify="center" align="top" className={styles.buttonsWrapper}>
                {renderImageUploader()}
              </Row>
              {user?.role === 'blogger' && (
                <>
                  <Row type="flex" align="top" justify="center">
                    {renderPublisherLogo()}
                  </Row>
                  <Row type="flex" justify="center" align="top" className={styles.buttonsWrapper}>
                    {renderPublisherLogoUploader()}
                  </Row>
                </>
              )}
            </Col>
          )}
          <Col className={styles.formWrapper}>

            <Form name="editProfile" onSubmit={handleSubmit}>
              <span className={styles.label}>{t('firstName')}</span>
              <Field
                size="large"
                name="firstName"
                component={RenderField}
                type="text"
                value={firstName}
              />
              <span className={styles.label}>{t('lastName')}</span>
              <Field
                size="large"
                name="lastName"
                component={RenderField}
                type="text"
                value={lastName}
              />
              <span className={styles.label}>{t('email')}</span>
              <Field
                size="large"
                name="email"
                component={RenderField}
                type="text"
                value={user?.email}
                disabled
              />
              <span className={styles.label}>{t('zipCode')}</span>
              <Field
                size="large"
                name="zipCode"
                component={RenderField}
                type="text"
                value={zipCode}
              />
              <span className={styles.label}>{t('registrationDate')}</span>
              <Field
                size="large"
                name="email"
                component={RenderField}
                type="text"
                value={format(new Date(user.createdAt), 'yyyy-MM-dd')}
                disabled
              />

              <Row type="flex" justify="start" align="middle">
                <Button
                  type="secondary"
                  htmlType="button"
                  disabled={isImageUploading}
                  onClick={showChangePasswordModal}
                >
                  {t('changePassword')}
                </Button>
              </Row>
              <Row type="flex" justify="space-between" align="middle" className={styles.buttonsWrapper}>
                <Button
                  disabled={
                    !isValid
                    || (firstName === user.firstName && lastName === user.lastName && zipCode === (user.location?.zipCode ?? ''))
                    || isImageUploading
                  }
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  {t('submit')}
                </Button>
                {/*<Button
                  disabled
                  type="bareLink"
                  htmlType="button"
                  className={styles.deleteAccountLink}
                  onClick={() => {}}
                  title="Not implemented yet"
                >
                  {t('deleteAccount')}
                </Button>*/}
              </Row>
            </Form>

            {user?.role === 'blogger' && (
              <>
                <div className={styles.descriptionEditorWrapper}>
                  <span className={styles.label}>{t('description')}</span>
                  <TextArea
                    size="large"
                    name="description"
                    onChange={event => onDescriptionChange(event)}
                    value={currentDescription}
                    type="textArea"
                    className={styles.descriptionTextArea}
                    placeholder={t('describeYourBlog')}
                  />
                </div>
                <Row type="flex" justify="start" className={styles.editorControlsWrapper}>
                  <Button type="secondary" onClick={() => setCurrentDescription(user?.description ?? '')}>{t('cancel')}</Button>
                  <Button
                    type="primary"
                    onClick={onSaveDescription}
                    disabled={user?.description === currentDescription || (!user?.description && currentDescription.trim() === '')}
                  >
                    {t('save')}
                  </Button>
                </Row>
              </>
            )}

          </Col>
        </Row>
      </div>
      {ChangePasswordModal}
    </>
  );
};

General.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  updateUser: PropTypes.func.isRequired,
  //deleteUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  dimensions: PropTypes.object.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    firstName: string()
      .max(40, t('enterMaxName'))
      .required(t('enterFirstName')),
    lastName: string()
      .max(40, t('enterMaxName')),
    zipCode: string()
      .matches(/^[0-9]{5}$/, t('incorrectZipCode'))
  });
};

const withFormik = Component => props => {
  const { t } = useTranslation('account');
  const validationSchema = getValidationSchema(t);
  const onSubmit = (values) => {
    const data = { firstName: values.firstName, lastName: values.lastName ?? '' };
    if ((props.user?.location?.zipCode ?? '') !== values.zipCode) data.location = { zipCode: values.zipCode };
    props.updateUser({
      userId: props.user._id,
      userData: data,
      onSuccess: () => notification.success({ message: t('accountUpdated') })
    });
  };
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount={true}
      displayName="EditProfileForm"
      onSubmit={onSubmit}
      initialValues={{ firstName: '', lastName: '' }}
    >
      {(formikProps) => <Component {...props} {...formikProps} />}
    </Formik>
  );
};

export default compose(
  connect(
    ({ user: { user }, dimensions }) => ({
      user,
      dimensions
    }),
    {
      updateUser/*,
      deleteUser*/
    }
  ),
  withDimensions,
  withFormik
)(General);
