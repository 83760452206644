import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { withFormik } from 'formik';
import { object, string } from 'yup';
import { FieldAdapter as Field, RenderField } from '../../../common/components';
import styles from '../ChangePasswordForm/styles.module.scss';

const ChangeNameForm = ({
  bindSubmit,
  bindIsValid,
  submitForm,
  isValid,
  values: { newFirstName, newLastName },
  setValues,
  handleSubmit,
  previousFirstName = '',
  previousLastName = ''
}) => {
  const { t } = useTranslation('account');
  bindSubmit(submitForm);
  bindIsValid(isValid);
  useEffect(() => {
    setValues({
      newFirstName: previousFirstName,
      newLastName: previousLastName
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.title}>{t('enterNewFirstName')}</div>
      <Field
        size="large"
        name="newFirstName"
        component={RenderField}
        type="text"
        // label={t('enterNewName')}
        value={newFirstName}
      />
      <div className={styles.title}>{t('enterNewLastName')}</div>
      <Field
        size="large"
        name="newLastName"
        component={RenderField}
        type="text"
        // label={t('enterNewName')}
        value={newLastName}
      />
    </Form>
  );
};

ChangeNameForm.propTypes = {
  values: PropTypes.object.isRequired,
  bindSubmit: PropTypes.func.isRequired,
  bindIsValid: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

const getValidationSchema = t => {
  return object().shape({
    newFirstName: string().required(t('enterYourFirstName'))
  });
};

const changePasswordFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ newFirstName: '', newLastName: '' }),
  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
  validationSchema: ({ t }) => getValidationSchema(t),
  displayName: 'ChangeNameForm' // helps with React DevTools
});

export default changePasswordFormWithFormik(ChangeNameForm);
