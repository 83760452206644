export default {
  accountSettings: 'Account Settings',
  editProfile: 'Edit profile',
  deleteAccount: 'Delete account',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  password: 'Password',
  registrationDate: 'Registration date',
  role: 'Role',
  deleteAccountWarning:
    'If you delete your account, you will no longer access to this data.' +
    " Only the Account Owner can delete the entire account and it's data.",
  deleteMyAccount: 'Delete My Account',
  deleteConfirmation: {
    deleteAccountTitle: 'Delete account?',
    content: 'A you sure you want to delete your account? This action can not be undone.'
  },
  enterNewFirstName: 'First name',
  enterNewLastName: 'Last name',
  enterYourFirstName: 'Please enter your first name',
  delete: 'Delete',
  save: 'Save',
  cancel: 'Cancel',
  account: 'Account',
  edit: 'Edit',
  submit: 'Submit',
  changePasswordTitle: 'Change Password',
  changeProfilePhoto: 'Change profile photo',
  changePublisherLogo: 'Change your blog\'s logo',
  changeNameTitle: 'Update Name',
  currentPasswordLabel: 'Current Password',
  newPasswordLabel: 'New Password',
  repeatPasswordLabel: 'Repeat Password',
  enterCurrentPassword: 'Please enter your current password',
  enterNewPassword: 'Please enter a new password',
  confirmPassword: 'Please confirm a password',
  passwordMustMatch: 'Passwords must match',
  enterMaxName: 'Please enter no more than 40 characters',
  enterFirstName: 'First name is required',
  registered: 'Registered',
  changePassword: 'Change password',
  emailNotDefined: 'Email is not defined',
  nameIsNotDefined: 'Name is not defined',
  username: 'Username',
  signOut: 'Sign Out',
  unableProcessImage: 'Unable to process the image',
  description: 'Description',
  describeYourBlog: 'Describe your blog',
  zipCode: 'Zip code',
  incorrectZipCode: 'Must be exactly 5 digits',
  accountUpdated: 'Account settings are updated'
};
