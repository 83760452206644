import React, { useEffect } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { setIsHiddenMainLayoutWrapper } from '../../../common/reducers';
import { getUser, logOut } from '../../../user/sagas';
import { Spinner } from '../../../common/components';
import styles from './styles.module.scss';

const AuthRoute = ({
  component: Component,
  user,
  authorization = [],
  isLoadedUser,
  getUser,
  setIsHiddenMainLayoutWrapper,
  shouldHideMainLayoutWrapper,
  logOut,
  ...rest
}) => {
  useEffect(() => {
    setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper);
    if (!user?.email) {
      getUser();
    }
  }, []); // eslint-disable-line

  const checkIfAccessible = () => {
    return !authorization.length || authorization.indexOf(user.role) >= 0;
  };

  return (
    <>
      {isLoadedUser ? (
        <Route
          {...rest}
          render={(props) =>
            user?.email ? (
              checkIfAccessible() ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      ) : (
        <Spinner className={styles.spinner} size="large" />
      )}
    </>
  );
};

AuthRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.object,
  isLoadedUser: PropTypes.bool,
  shouldHideMainLayoutWrapper: PropTypes.bool,
  getUser: PropTypes.func.isRequired,
  authorization: PropTypes.arrayOf(PropTypes.string),
  logOut: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user, isLoadedUser } }) => ({
      user,
      isLoadedUser
    }),
    {
      getUser,
      setIsHiddenMainLayoutWrapper,
      logOut
    }
  ),
  withRouter
)(AuthRoute);
