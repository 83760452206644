const defaultState = {
  isHiddenMainLayoutWrapper: true,
  isHiddenSidebar: false
};

const actionTypes = {
  SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER: 'SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER',
  SET_IS_HIDDEN_SIDEBAR: 'SET_IS_HIDDEN_SIDEBAR'
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER:
      return {
        ...state,
        isHiddenMainLayoutWrapper: payload
      };
    case actionTypes.SET_IS_HIDDEN_SIDEBAR:
      return {
        ...state,
        isHiddenSidebar: payload
      };

    default:
      return state;
  }
}

// ACTIONS
export const setIsHiddenMainLayoutWrapper = payload => ({
  type: actionTypes.SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER,
  payload
});
export const setIsHiddenSidebar = payload => ({ type: actionTypes.SET_IS_HIDDEN_SIDEBAR, payload });
