import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, notification } from 'antd';
import { isAfter } from 'date-fns';
import Cookies from 'universal-cookie';

import { AllLists, Items } from '../components';
import styles from './styles.module.scss';
import { getList, getListWithRecipes, getLists, getCategories, getCollaborators } from '../sagas/listsSagas';
import { setAvailableLists, setSharedLists, pullFromUpdateQueue, setActiveList } from '../reducers';
import { withDimensions } from '../../common/HOC';
import { Footer } from '../../common/components';
import {
  startGettingListUpdatesInBackground,
  stopGettingListUpdatesInBackground,
  getListUpdatesInBackground
} from '../sagas/listsUpdates';

const cookies = new Cookies();

const Lists = (props) => {
  const {
    getListWithRecipes,
    getLists,
    activeList,
    availableLists,
    sharedLists,
    user,
    setAvailableLists,
    setSharedLists,
    categories,
    getCategories,
    dimensions,
    startGettingListUpdatesInBackground,
    stopGettingListUpdatesInBackground,
    getListUpdatesInBackground,
    updateQueue,
    pullFromUpdateQueue,
    getCollaborators,
    setActiveList
  } = props;
  const { t } = useTranslation('lists');
  const [existingNames, setExistingNames] = useState([]);
  const [mode, setMode] = useState('lists'); // lists, items
  const [relatedRecipes, setRelatedRecipes] = useState([]);
  const [isLoadedListChecked, setIsLoadedListChecked] = useState(false);

  useEffect(() => {
    if (user) {
      getLists({
        onSuccess: (data) => {
          if (Array.isArray(data)) {
            const myLists = data.filter((list) => list.owners?.indexOf?.(user._id) >= 0);
            const sharedLists = data.filter((list) => list.shared?.indexOf?.(user._id) >= 0);
            setAvailableLists(myLists);
            setSharedLists(sharedLists);
            if (activeList && [...myLists, ...sharedLists].find((list) => list._id === activeList._id)) {
              getListWithRecipes({
                id: activeList._id,
                onError: () => notification.error({ message: t('unableGetList') }),
                onSuccess: (data) => { setRelatedRecipes(data.recipes ?? []); setMode('items'); }
              });
            }
          }
        },
        onError: () => {
          notification.error({ message: t('unableGetLists') });
        }
      });
    }

    if (!categories && user) {
      getCategories({
        onError: () => {
          notification.error({ message: t('unableGetCategories') });
        }
      });
    }
  }, [user]); //eslint-disable-line

  useEffect(() => {
    if (availableLists && sharedLists && !isLoadedListChecked) {
      const selectedListId = cookies.get('selectedList');
      setIsLoadedListChecked(true);
      if (selectedListId) {
        const selectedList = [...availableLists, ...sharedLists].find((list) => list._id === selectedListId);
        if (selectedList) {
          getListWithRecipes({
            id: selectedList._id,
            onError: () => notification.error({ message: t('unableGetList') }),
            onSuccess: (data) => { setRelatedRecipes(data.recipes ?? []); setMode('items'); }
          });
        }
      }
    }
  }, [availableLists, sharedLists]); // eslint-disable-line

  useEffect(() => {
    if (activeList) {
      cookies.set('selectedList', activeList._id, { path: '/', sameSite: true, secure: true });
      getCollaborators({
        listId: activeList._id,
        onError: () => notification.error({ message: t('unableGetCollaborators') })
      });
    } else if (isLoadedListChecked) cookies.remove('selectedList', { path: '/', sameSite: true, secure: true });
  }, [activeList]); //eslint-disable-line

  useEffect(() => {
    if (mode !== 'lists' && !activeList) setMode('lists');
  }, [mode, activeList]); //eslint-disable-line

  useEffect(() => {
    const names = availableLists?.map(l => l.name) ?? [];
    setExistingNames(names);
  }, [availableLists]); // eslint-disable-line

  useEffect(() => {
    getListUpdatesInBackground();
    startGettingListUpdatesInBackground();
    return () => {
      stopGettingListUpdatesInBackground();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (updateQueue?.length) {
      const currentUpdate = updateQueue[0];
      if (currentUpdate.removing) {
        const otherAvailable = availableLists.filter((al) => al._id !== currentUpdate._id);
        const otherShared = sharedLists.filter((sl) => sl._id !== currentUpdate._id);
        if (activeList?._id === currentUpdate._id) setActiveList(undefined);
        setAvailableLists(otherAvailable);
        setSharedLists(otherShared);
        pullFromUpdateQueue();
      } else if (availableLists.find((al) =>
        (al._id === currentUpdate._id
          && isAfter(new Date(currentUpdate.updatedAt), new Date(al.updatedAt))))
        || ![...sharedLists, ...availableLists].find((l) => l._id === currentUpdate._id)) {
        getListWithRecipes({
          id: currentUpdate._id,
          isSetActive: (activeList?._id === currentUpdate._id),
          onError: () => notification.error({ message: t('unableGetList') }),
          onSuccess: (data) => {
            const other = availableLists.filter((al) => al._id !== currentUpdate._id);
            setAvailableLists([data.list, ...other]);
            setRelatedRecipes(data.recipes ?? []);
            pullFromUpdateQueue();
          }
        });
      } else if (sharedLists.find((sl) =>
        (sl._id === currentUpdate._id && isAfter(new Date(currentUpdate.updatedAt), new Date(sl.updatedAt))))) {
        getListWithRecipes({
          id: currentUpdate._id,
          isSetActive: (activeList?._id === currentUpdate._id),
          onError: () => notification.error({ message: t('unableGetList') }),
          onSuccess: (data) => {
            const other = sharedLists.filter((sl) => sl._id !== currentUpdate._id);
            setSharedLists([data.list, ...other]);
            setRelatedRecipes(data.recipes ?? []);
            pullFromUpdateQueue();
          }
        });
      } else {
        pullFromUpdateQueue();
      }
    }
  }, [updateQueue]); // eslint-disable-line

  return (
    <>
      {dimensions?.isScreenBiggerLG ? (
        <Row type="flex" align="top" justify="start">
          <Col className={styles.listOfListsWrapper}>
            <AllLists existingNames={existingNames} setMode={setMode} setRelatedRecipes={setRelatedRecipes} />
          </Col>
          <Col className={styles.rightWrapper}>
            <div className={styles.listOfItemsWrapper}>
              <Items
                existingNames={existingNames}
                setMode={setMode}
                setRelatedRecipes={setRelatedRecipes}
                relatedRecipes={relatedRecipes}
              />
            </div>
            <Footer />
          </Col>
        </Row>
      ) : (
        <>
          {mode === 'lists' ? (
            <>
              <div className={styles.listOfListsWrapper}>
                <AllLists existingNames={existingNames} setMode={setMode} setRelatedRecipes={setRelatedRecipes} />
              </div>
              <Footer />
            </>
          ) : (
            <>
              <div className={styles.listOfItemsWrapper}>
                <Items existingNames={existingNames}
                       setMode={setMode}
                       setRelatedRecipes={setRelatedRecipes}
                       relatedRecipes={relatedRecipes}
                       isMobile
                />
              </div>
              <Footer />
            </>

          )}
        </>
      )}
    </>

  );
};

Lists.propTypes = {
  activeList: PropTypes.object,
  user: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  availableLists: PropTypes.arrayOf(PropTypes.object),
  sharedLists: PropTypes.arrayOf(PropTypes.object),
  getList: PropTypes.func.isRequired,
  getListWithRecipes: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getLists: PropTypes.func.isRequired,
  setAvailableLists: PropTypes.func.isRequired,
  setSharedLists: PropTypes.func.isRequired,
  startGettingListUpdatesInBackground: PropTypes.func.isRequired,
  stopGettingListUpdatesInBackground: PropTypes.func.isRequired,
  getListUpdatesInBackground: PropTypes.func.isRequired,
  updateQueue: PropTypes.arrayOf(PropTypes.object),
  recipes: PropTypes.arrayOf(PropTypes.object),
  pullFromUpdateQueue: PropTypes.func.isRequired,
  setActiveList: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({
      user: { user },
      lists: { activeList, availableLists, sharedLists, categories, updateQueue },
      dimensions
    }) => ({
      user, activeList, availableLists, sharedLists, categories, dimensions, updateQueue
    }),
    { getList,
      getListWithRecipes,
      getLists,
      setAvailableLists,
      setSharedLists,
      getCategories,
      startGettingListUpdatesInBackground,
      stopGettingListUpdatesInBackground,
      getListUpdatesInBackground,
      pullFromUpdateQueue,
      getCollaborators,
      setActiveList
    }
  ),
  withDimensions
)(Lists);
