import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doLogin, createUser, getGoogleCode, getFacebookCode, getAppleCode, doAppleLogin, requestResetPassword } from '../../user/sagas';
import history from '../../../utils/history';
import styles from './styles.module.scss';
import { SignUpForm, LoginForm, ForgotPasswordForm } from '../components';
import { Button, Footer, Icon } from '../../common/components';
import config from '../../../config';

const Login = props => {
  const {
    doLogin,
    location,
    createUser,
    getGoogleCode,
    getFacebookCode,
    getAppleCode,
    requestResetPassword,
    externalSource,
    doAppleLogin
  } = props;
  const { t } = useTranslation('login');
  const [mode, setMode] = useState('login');
  const [isWithInvite, setIsWithInvite] = useState(false);
  const [isAppleReady, setIsAppleReady] = useState(false);

  const cookies = new Cookies();
  let checkJWTTimeout = null;

  useEffect(() => {
    return () => {
      if (checkJWTTimeout) clearTimeout(checkJWTTimeout);
    };
  }); // eslint-disable-line

  useEffect(() => {
    const { switchToMode, list } = queryString.parse(location.search);
    setIsWithInvite(!!list);
    const initApple = () => {
      if (window.AppleID) {
        const options = {
          clientId: config.APPLE_SERVICE_ID,
          scope: 'name email',
          redirectURI: `https://${config.ENV === 'prod' ? '' : 'staging.'}zestary.com/auth/apple`,
          usePopup: true
        };
        if (list) options.state = `${list}`;
        window.AppleID.auth.init(options);
        setIsAppleReady(true);
      } else setTimeout(() => initApple(), 150);
    };
    initApple();
    if (switchToMode === 'signUp' || switchToMode === 'forgotPassword') {
      setMode(switchToMode.toString());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (config.GA_TRACKING_ID) {
      ReactGA.event({
        category: 'Switching mode',
        action: `"/login" - ${mode}`
      });
    }
  }, [mode]); // eslint-disable-line

  const observeForJWTChange = () => {
    if (checkJWTTimeout) clearTimeout(checkJWTTimeout);
    checkJWTTimeout = setTimeout(() => {
      const newJWT = cookies.get('X-JWT');
      if (newJWT?.length) {
        history.push(!externalSource ? '/lists' : '/ingredient-import');
      } else {
        observeForJWTChange();
      }
    }, 400);
  };
  if (externalSource) observeForJWTChange();

  const onLogin = async ({ email, password }) => {
    const { list } = queryString.parse(location.search);
    doLogin({
      listId: list ?? null,
      login: { email, password },
      onSuccess: () => {
        history.push(!externalSource ? '/lists' : '/ingredient-import');
      },
      onError: () => {
        notification.error({ message: t('loginFailed') });
      }
    });
  };

  const onSignUp = async ({ firstName, lastName, email, password }) => {
    const { list } = queryString.parse(location.search);
    createUser({
      listId: list ?? null,
      user: { firstName, lastName, email, password },
      onSuccess: () => {
        history.push(!externalSource ? '/lists' : '/ingredient-import');
      },
      onError: () => {
        notification.error({ message: t('signUpFailed') });
      }
    });
  };

  const onRequestResetPassword = ({ email }) => {
    requestResetPassword({
      email,
      onSuccess: (data) => {
        data.message && notification.success({ message: data.message });
        setMode('login');
      }
    });
  };

  const requestGoogleCode = () => {
    const { list } = queryString.parse(location.search);
    getGoogleCode({
      listId: list ?? null,
      onSuccess: (response) => {
        if (response.location) {
          window.location = response.location;
        } else {
          notification.error({ message: t('unableToGetGoogleAuthUrl') });
        }
      },
      onError: () => {
        notification.error({ message: t('unableToGetGoogleAuthUrl') });
      }
    });
  };

  const requestFacebookCode = () => {
    const { list } = queryString.parse(location.search);
    getFacebookCode({
      listId: list ?? null,
      onSuccess: (response) => {
        if (response.location) {
          window.location = response.location;
        } else {
          notification.error({ message: t('unableToGetFacebookAuthUrl') });
        }
      },
      onError: () => {
        notification.error({ message: t('unableToGetFacebookAuthUrl') });
      }
    });
  };

  const requestAppleCode = () => {
    getAppleCode({
      onSuccess: (response) => {
        doAppleLogin({
          codeResponse: response,
          onSuccess: () => {
            history.push(!externalSource ? '/lists' : '/ingredient-import');
          }
        });
      },
      onError: () => {
        notification.error({ message: t('unableToGetAppleToken') });
      }
    });
  };

  const renderFacebookButton = () => {
    const { list } = queryString.parse(location.search);
    const onClick = externalSource ? () => {} : requestFacebookCode;
    const href = `/auth/external?source=facebook${list ? `&list=${list}` : ''}`;
    const renderButton = () =>
      <Button type="secondary" className={styles.socialLogInBtn} onClick={onClick}>
        <Icon name="facebook" />
        {` ${t(mode === 'login' ? 'signInWith' : 'signUpWith')} ${t('facebook')}`}
      </Button>;

    return externalSource ? <a href={href} target="_blank" rel="noopener noreferrer">{renderButton()}</a> : renderButton();
  };

  const renderGoogleButton = () => {
    const { list } = queryString.parse(location.search);
    const onClick = externalSource ? () => {} : requestGoogleCode;
    const href = `/auth/external?source=google${list ? `&list=${list}` : ''}`;
    const renderButton = () =>
      <Button type="secondary" className={styles.socialLogInBtn} onClick={onClick}>
        <Icon name="google" />
        {` ${t(mode === 'login' ? 'signInWith' : 'signUpWith')} ${t('google')}`}
      </Button>;

    return externalSource ? <a href={href} target="_blank" rel="noopener noreferrer">{renderButton()}</a> : renderButton();
  };

  const renderAppleButton = () => {
    const renderButton = () =>
      <Button type="secondary" className={styles.socialLogInBtn} onClick={requestAppleCode} disabled={!isAppleReady}>
        <FontAwesomeIcon icon={['fab', 'apple']} className={styles.appleIcon} />
        {` ${t(mode === 'login' ? 'signInWith' : 'signUpWith')} ${t('apple')}`}
      </Button>;

    return renderButton();
  };

  return (
    <>
      <Row type="flex" align="top" justify="center" className={`${styles.pageWrapper} ${externalSource ? styles.inIFrame : ''}`}>
        <Col className={styles.formWrapper}>
          {mode !== 'forgotPassword' && (
            <p className={styles.header}>{mode === 'login' ? t('logIn') : t('register')}</p>
          )}
          {isWithInvite && (mode !== 'forgotPassword') && <p>{t('someoneSharedList')}</p>}
          <div className={styles.switchMode} style={mode === 'forgotPassword' ? { marginBottom: '15px' } : {}}>
            {(mode !== 'forgotPassword' && !isWithInvite && !externalSource) ? `${t('or')} ` : ''}
            {externalSource ? (<>
              <div className={styles.linkBack}>
                <Button type="bareLink" onClick={() => history.push('/ingredient-import')}>
                  {'< '}{t('backToIngredientsList')}
                </Button>
              </div>
              {mode !== 'forgotPassword' ? (
                <>
                  {mode === 'login' ? (
                    'Log in'
                  ) : (
                    <Button type="bareLink" onClick={() => setMode('login')}>
                      Log in
                    </Button>
                  )}
                  {' or '}
                  {mode === 'login' ? (
                    <Button type="bareLink" onClick={() => setMode('signUp')}>
                      create an account
                    </Button>
                  ) : (
                    'create an account'
                  )}
                  {' '}{t('toSaveIngredientsAndRecipe')}.
                </>
              ) : (
                <Button type="bareLink" onClick={() => setMode('login')}>
                  {t('back')}
                </Button>
              )}
            </>) : (
              <Button type="bareLink" onClick={() => setMode(mode === 'login' ? 'signUp' : 'login')}>
                {mode === 'login' ? t('createAnAccount') : mode === 'signUp' ? t('logIn').toLowerCase() : t('back')}
              </Button>
            )}
          </div>
          {mode !== 'forgotPassword' && (
            <>
              {externalSource && (<>
                <p className={styles.socialNetworkWarning}>{t('socialNetworkWarning')}</p>
              </>)}
              {renderGoogleButton()}
              {renderFacebookButton()}
              {renderAppleButton()}
              <Row type="flex" justify="center" align="middle" className={styles.middleLine}>
                <Col className={styles.justBorder} />
                <Col className={styles.or}>{t('or')}</Col>
                <Col className={styles.justBorder} />
              </Row>
            </>
          )}
          {
            mode === 'login'
              ? (<LoginForm onSubmit={onLogin} setMode={setMode}/>)
              : mode === 'signUp'
                ? (<SignUpForm onSubmit={onSignUp} />)
                : (<ForgotPasswordForm onSubmit={onRequestResetPassword} />)
          }
        </Col>
      </Row>
      {!externalSource && (<Footer />)}
    </>
  );
};

Login.propTypes = {
  doLogin: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getGoogleCode: PropTypes.func.isRequired,
  getFacebookCode: PropTypes.func.isRequired,
  getAppleCode: PropTypes.func.isRequired,
  doAppleLogin: PropTypes.func.isRequired,
  requestResetPassword: PropTypes.func.isRequired,
  externalSource: PropTypes.any
};

export default compose(
  withRouter,
  connect(
    ({ recipe: { externalSource } }) => ({ externalSource }),
    { doLogin, createUser, getGoogleCode, getFacebookCode, getAppleCode, requestResetPassword, doAppleLogin }
  )
)(Login);
