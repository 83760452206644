import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

const Header = ({ children, className = '', ...props }) => {
  return (
    <Button className={[styles.button, className].join(' ')} size="large" type="default" {...props}>
      {children}
    </Button>
  );
};

Header.propTypes = {
  children: PropTypes.node
};

export default Header;
