import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Menu } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { Button, Icon, Logo } from '../../../common/components';
import '../../../common/components/MainLayout/styles.global.scss';
import history from '../../../../utils/history';
import { withDimensions } from '../../../common/HOC';
import { isFunc } from '../../../../utils/functions';

const Header = ({ mode, setMode, availableModes, dimensions }) => {
  const { t } = useTranslation('landing');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleSelection = e => {
    if (isFunc(setMode)) setMode(e.key);
    else history.push(`/?switchToMode=${e.key}`);
  };

  useLayoutEffect(() => {
    const closeDD = (e) => {
      if (isMenuVisible && e.target?.id !== 'h-button-landing' && e.target?.id !== 'h-icon-landing') setIsMenuVisible(() => false);
    };

    window.addEventListener('touchend', closeDD);
    window.addEventListener('mouseup', closeDD);
    return () => {
      window.removeEventListener('touchend', closeDD);
      window.removeEventListener('mouseup', closeDD);
    };
  }, [isMenuVisible]);

  return (
    <>
      {dimensions?.isScreenBiggerLG ? (
        <div className={styles.headerWrapper}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Logo />
            </Col>
            <Row type="flex" justify="space-between" align="middle" className={styles.controlWrapper}>
              <Col>
                <div className="NavMenuWrapper">
                  <Menu theme="light" selectedKeys={[mode]} mode="horizontal" onClick={handleSelection}>
                    {availableModes.map(item => (
                      <Menu.Item key={item.key} style={ item.width ? { width: `${item.width}px` } : {}}>
                        {item.title}
                      </Menu.Item>
                    ))}
                  </Menu>
                </div>
              </Col>
              <Col>
                <Button type="bareLink" onClick={() => history.push('/login')} className={styles.loginLink}>{t('login')}</Button>
                <Button type="primary" onClick={() => history.push('/login?switchToMode=signUp')}>{t('register')}</Button>
              </Col>
            </Row>
          </Row>
        </div>
      ) : (
        <div className={`${styles.headerWrapperMobile} ${!isMenuVisible ? '' : styles.white}`}>
          <Row type="flex" justify="space-around" align="middle" className={styles.line}>
            <Col>
              <Logo height={24} />
            </Col>
            <Col className={styles.hamburgerIconWrapper}>
              <Button type="icon" rounded id="h-button-landing" onClick={() => setIsMenuVisible(!isMenuVisible)}>
                <Icon id="h-icon-landing" name="hamburger" />
              </Button>
            </Col>
          </Row>
          <div
            className={`${styles.ddMenu} ${isMenuVisible ? '' : styles.hidden}`}
            style={isMenuVisible ? { height: `${44 * (availableModes.length + 2)}px` } : {}}
          >
            {availableModes.map(item => (
              <div onClick={() => setMode(item.key)} key={item.key} className={`${styles.ddMenuItem}`}>
                {t(item.title)}
              </div>
            ))}
            <div onClick={() => history.push('/login')} key={-200} className={`${styles.ddMenuItem}`}>
              {t('login')}
            </div>
            <div
              onClick={() => history.push('/login?switchToMode=signUp')}
              key={-201}
              className={`${styles.ddMenuItem}`}
            >
              {t('register')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  availableModes: PropTypes.arrayOf(PropTypes.object)
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(Header);
