import * as Unitz from 'unitz-ts';

Unitz.Classes.addDefaults();
Unitz.Translations.addDefaults();
Unitz.Rates.addDefaults();
const { uz } = Unitz;

//console.log(uz('2 demons, 7 demons, 124764 mm, 2.3 km').compact().normalize());

const groupItems = (t, items = [], groups = []) => {
  const expectedGroups = Array.isArray(groups) ? groups : [groups];
  expectedGroups.forEach((groupingParameters) => {
    const { setGroup, groupContainer = [], fieldName, groupsNameField = 'name' } = groupingParameters;
    if (setGroup) {
      if (!fieldName) {
        setGroup(performMerging(items));
      } else {
        const activeGroups = getItemGroups(items, groupContainer, fieldName, groupsNameField, t);
        const groupedItems = divideItemsByGroups(items, activeGroups, groupContainer, fieldName, groupsNameField, t);
        setGroup(performMerging(groupedItems));
      }
    }
  });
};

const getItemGroups = (items, groupContainer, fieldName, groupsNameField = 'name', t) => {
  return items
    .map((item) => item[fieldName]
      ? groupContainer.find((group) => group._id === item[fieldName])?.[groupsNameField] ?? t('other')
      : t('other'))
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => b === t('other') ? -1 : a === t('other') ? 1 : (`${a}`).localeCompare(b));
};

const divideItemsByGroups = (items, groups, groupContainer, fieldName, groupsNameField = 'name', t) => {
  return groups.map((group) => {
    return { groupName: group,
      items: items.filter((item) =>
        ((!item[fieldName] || !groupContainer.find((g) => g._id === item[fieldName])) && group === t('other'))
        || (groupContainer.find((g) => g._id === item[fieldName])?.[groupsNameField] === group)) };
  });
};

const combineAmounts = (items) => {
  const amounts = items.map((value) => { return { amount: (value.amount || (value.unit ? 1 : 0)), unit: value.unit }; });
  const bareNumbers = amounts.filter((a) => !a.unit || !a.unit.name);
  const withUnits = amounts.filter((a) => !!a.unit?.name);
  const bareNumbersSum = bareNumbers.reduce((acc, num) => { return acc + num.amount; }, 0);
  const amountsString = withUnits.map((value) => `${value.amount} ${value.unit?.pluralName && value.amount !== 1
    ? value.unit.pluralName
    : (value.unit?.name?.trim?.() ?? '')}`)
    .join(', ');
  let resultString = '';
  if (amountsString?.length) {
    try {
      const compactedObject = uz(amountsString).compact()/*.normalize()*/;
      if (compactedObject.ranges?.length) {
        resultString = compactedObject.ranges.map((range) => {
          const isPlural = range.max?.value && range.max.value !== 1;
          const withRequiredUnit = withUnits.find((a) => a.unit?.name === range.max?.unit && a.unit.pluralName);
          const unitString = (isPlural && withRequiredUnit) ? withRequiredUnit.unit.pluralName : (range.max?.unit ?? '');
          return (range.max?.value ? `${range.max?.value} ` : '') + unitString;
        }).join(', ');
      }
    } catch (e) {
      console.log('Units processing error:', e);
    }
  }
  return resultString.length
    ? resultString + (bareNumbersSum > 0 ? ` and ${bareNumbersSum} more` : '')
    : bareNumbersSum || '';
};

const mergeItems = (items) => {
  let mergedItems = [];
  const uniqueNames = items.map((item) => item.name.toLowerCase()).filter(distinct);
  if (uniqueNames.length === items.length) mergedItems = items;
  else {
    uniqueNames.forEach((uniqueName) => {
      const similarItems = items.filter((item) => item.name.toLowerCase() === uniqueName);
      if (similarItems.length === 1) mergedItems.push(similarItems[0]);
      else {
        const images = similarItems
          .reduce((acc, value) => value.image ? [...acc, value.image] : acc, [])
          .filter(distinct);
        const categories = similarItems
          .reduce((acc, value) => value.category ? [...acc, value.category] : acc, [])
          .filter(distinct);
        const recipes = similarItems
          .reduce((acc, value) => value.recipe ? [...acc, value.recipe] : acc, [])
          .filter(distinct);
        const descriptions = similarItems
          .reduce((acc, value) => value.description ? [...acc, value.description] : acc, [])
          .filter(distinct);
        const mergedItem = {
          name: uniqueName,
          images,
          amounts: combineAmounts(similarItems),
          categories,
          recipes,
          descriptions,
          purchased: !!similarItems[0].purchased,
          isMerged: true,
          items: similarItems,
          _id: similarItems.map((value) => value._id).join('')
        };
        mergedItems.push(mergedItem);
      }
    });
  }
  return mergedItems;
};

const performMerging = (items) => {
  const isGrouped = !!items?.[0].groupName;
  let processedItems = [];

  if (isGrouped) {
    items.forEach((groupedItem) => {
      const { groupName } = groupedItem;
      const innerItems = groupedItem.items;
      const notPurchasedItems = innerItems.filter((item) => !item.purchased);
      const purchasedItems = innerItems.filter((item) => item.purchased);
      const processedInnerItems = [...mergeItems(notPurchasedItems), ...mergeItems(purchasedItems)];
      processedItems.push({ groupName, items: processedInnerItems });
    });
  } else {
    const notPurchasedItems = items.filter((item) => !item.purchased);
    const purchasedItems = items.filter((item) => item.purchased);
    processedItems = [...mergeItems(notPurchasedItems), ...mergeItems(purchasedItems)];
  }

  // console.log('Merged items:', processedItems);
  return processedItems;
};

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

export default groupItems;
