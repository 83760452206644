import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { Button, Logo } from '../../../common/components';
import history from '../../../../utils/history';
import { withDimensions } from '../../../common/HOC';
import { isFunc } from '../../../../utils/functions';

const Footer = ({ setMode, dimensions, mode }) => {
  const { t } = useTranslation('landing');

  const renderLinksBlock = () => (
    <>
      { mode !== 'landing' && (
        <Button
            type="bareLink"
            onClick={() => { if (isFunc(setMode)) setMode('landing'); else history.push('/?switchToMode=landing'); }}
            className={styles.footerLink}
        >
          {t('howItWorks')}
        </Button>
      )}
      { (mode !== 'blogger' && mode !== 'thanks') && (
        <Button
            type="bareLink"
            onClick={() => { if (isFunc(setMode)) setMode('blogger'); else history.push('/?switchToMode=blogger'); }}
            className={styles.footerLink}
        >
          {t('areYouBlogger')}
        </Button>
      )}
      { mode !== 'privacy' && (
        <Button type="bareLink" onClick={() => history.push('/privacy-policy')} className={styles.footerLink}>
          {t('privacy')}
        </Button>
      )}
      { mode !== 'terms' && (
        <Button type="bareLink" onClick={() => history.push('/terms')} className={styles.footerLink}>
          {t('terms')}
        </Button>
      )}
    </>
  );

  return (
    <>
      {dimensions?.isScreenBiggerLG ? (
        <Row type="flex" justify="space-between" align="middle" className={styles.footerWrapper}>
          <Col>
            <Logo height={24} />
          </Col>
          <Col>
            {renderLinksBlock()}
          </Col>
          <Col className={styles.footerText}>
            {t('cr')}
          </Col>
        </Row>
      ) : (
        <div className={styles.footerWrapperMobile}>
          <Row type="flex" justify="center" align="middle" className={styles.line}>
            <Col>
              <Logo height={24} />
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle" className={styles.line}>
            {renderLinksBlock()}
          </Row>
          <Row type="flex" justify="center" align="middle" className={styles.line}>
            <Col className={`${styles.footerText}`}>
              {t('cr')}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

Footer.propTypes = {
  setMode: PropTypes.func.isRequired,
  mode: PropTypes.string
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(Footer);
