import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

const Danger = ({ children, className = '', ...props }) => {
  return (
    <Button className={`${styles.button} ${className}`} size="large" type="danger" {...props}>
      {children}
    </Button>
  );
};

Danger.propTypes = {
  children: PropTypes.node
};

export default Danger;
