import { call, takeLatest, take, put, fork, cancel/*, apply*/ } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import Cookies from 'universal-cookie';
import { notification } from 'antd';

import config from '../../../config';
import { actionTypes as listActionTypes } from '../reducers';

const cookies = new Cookies();
let shouldBeTerminated = true;

export const actionTypes = {
  GET_LISTS_UPDATES_IN_BACKGROUND: 'GET_LISTS_UPDATES_IN_BACKGROUND',
  START_GETTING_LISTS_UPDATES_IN_BACKGROUND: 'START_GETTING_LISTS_UPDATES_IN_BACKGROUND',
  STOP_GETTING_LISTS_UPDATES_IN_BACKGROUND: 'STOP_GETTING_LISTS_UPDATES_IN_BACKGROUND'
};

let ws;

async function websocketInitChannel() {
  const jwtCookie = await cookies.get('X-JWT'); // eslint-disable-line
  ws = new WebSocket(`${config.isLocalhost ? 'ws' : 'wss'}://${config.SERVER_HOST}/api/v1/lists-observation`, jwtCookie || '');

  return eventChannel(emit => {
    ws.onmessage = e => {
      const data = JSON.parse(e.data);
      if (data.message) {
        notification.info({ message: data.message });
      } else {
        emit({
          type: listActionTypes.PUSH_TO_UPDATE_QUEUE,
          payload: data
        });
      }
    };

    ws.onerror = error => {
      console.log('Web-socket connection failed:', error);
    };

    ws.onclose = () => {
      if (!shouldBeTerminated) {
        setTimeout(
          function () {
            emit({ type: 'CONNECTION_CLOSED' });
          },
          config.isLocalhost ? 500 : 500
        );
      }
    };

    return () => {
      ws.close();
    };
  });
}

function* websocketSagas() {
  function* bgSync() {
    shouldBeTerminated = false;
    let channel = yield call(websocketInitChannel);
    try {
      while (true) {
        const action = yield take(channel);
        if (action.type === 'CONNECTION_CLOSED') {
          if (!shouldBeTerminated) channel = yield call(websocketInitChannel);
        } else {
          yield put(action);
        }
      }
    } finally {
      shouldBeTerminated = true;
      channel.close();
    }
  }

  while (yield take(actionTypes.START_GETTING_LISTS_UPDATES_IN_BACKGROUND)) {
    // starts the task in the background
    const bgSyncTask = yield fork(() => bgSync());

    // wait for the user stop action
    yield take(actionTypes.STOP_GETTING_LISTS_UPDATES_IN_BACKGROUND);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
  }
}

export const getListUpdatesInBackground = payload => ({
  type: actionTypes.GET_LISTS_UPDATES_IN_BACKGROUND,
  payload
});
export const startGettingListUpdatesInBackground = () => ({
  type: actionTypes.START_GETTING_LISTS_UPDATES_IN_BACKGROUND
});
export const stopGettingListUpdatesInBackground = () => ({
  type: actionTypes.STOP_GETTING_LISTS_UPDATES_IN_BACKGROUND
});

export default [takeLatest(actionTypes.GET_LISTS_UPDATES_IN_BACKGROUND, websocketSagas)];
