import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, notification, Select, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import 'rc-time-picker-date-fns/assets/index.css';
import TimePicker from 'rc-time-picker-date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import styles from './styles.module.scss';
import './styles.global.scss';
import { Button, Footer } from '../../../common/components';
import {
  getUsersStatistics,
  getRecipePromotionSchedule,
  updateRecipePromotionSchedule,
  exportSuggestedItems,
  exportSuggestedUnits,
  exportCategories,
  exportCategoryMapping,
  importData
} from '../../sagas';

const AdminDashboard = ({
  usersStatistics,
  getUsersStatistics,
  getRecipePromotionSchedule,
  updateRecipePromotionSchedule,
  exportSuggestedItems,
  exportSuggestedUnits,
  exportCategories,
  exportCategoryMapping,
  importData
}) => {
  const { t } = useTranslation('admin');
  const [currentSchedule, setCurrentSchedule] = useState(undefined);
  const [newSchedule, setNewSchedule] = useState(undefined);
  const [downloadFileUrl, setDownloadFileUrl] = useState(undefined);
  const [isFileProcessing, setIsFileProcessing] = useState(false);

  const uploaderProps = {
    disabled: isFileProcessing,
    fileList: [],
    accept: '.csv',
    multiple: false
  };

  useEffect(() => {
    retrieveUsersStatistics();
    retrieveRecipePromotionSchedule();
  }, []); // eslint-disable-line

  const retrieveUsersStatistics = () => {
    getUsersStatistics({
      onError: () => { notification.error({ message: t('unableGetUsersStatistics') }); }
    });
  };

  const retrieveRecipePromotionSchedule = () => {
    getRecipePromotionSchedule({
      onError: () => { notification.error({ message: t('unableGetRecipePromotionSchedule') }); },
      onSuccess: (schedule) => {
        setCurrentSchedule(schedule);
        const { dayOfWeek, hour, minute } = schedule;
        setNewSchedule({ dayOfWeek, hour, minute });
      }
    });
  };

  const doUpdateRecipePromotionSchedule = () => {
    updateRecipePromotionSchedule({
      schedule: newSchedule,
      onError: () => { notification.error({ message: t('unableUpdateRecipePromotionSchedule') }); },
      onSuccess: (data) => {
        setCurrentSchedule(data);
      }
    });
  };

  const dayOfWeekToString = (day) => {
    switch (day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return 'Ooops!';
    }
  };

  const get12hString = (hour, minute) => {
    const time = parse(`${hour}:${minute} Z`, 'H:m X', new Date());
    return format(time, "h:mm a 'UTC'xxxxx");
  };

  const onDayOfWeekChange = (value) => {
    const updatedSchedule = Object.assign({}, newSchedule);
    updatedSchedule.dayOfWeek = value;
    setNewSchedule(updatedSchedule);
  };

  const onTimeChange = (value) => {
    const updatedSchedule = Object.assign({}, newSchedule);
    updatedSchedule.hour = value.getUTCHours();
    updatedSchedule.minute = value.getUTCMinutes();
    setNewSchedule(updatedSchedule);
  };

  const doExportSuggestedItems = () => {
    setIsFileProcessing(true);
    exportSuggestedItems({
      onError: () => {
        setIsFileProcessing(false);
        notification.error({ message: t('unableExportSuggestedItems') });
      },
      onSuccess: (suggestedItems) => {
        const fields = ['_id', 'name', 'category', 'baseItem', 'baseCategory', 'score', 'createdAt', 'updatedAt'];
        prepareCSVDownload(suggestedItems, fields, 'Suggested_items');
      }
    });
  };

  const doExportSuggestedUnits = () => {
    setIsFileProcessing(true);
    exportSuggestedUnits({
      onError: () => {
        setIsFileProcessing(false);
        notification.error({ message: t('unableExportSuggestedUnits') });
      },
      onSuccess: (suggestedUnits) => {
        const fields = ['_id', 'name', 'pluralName', 'variants', 'isDefault', 'description', 'image'];
        prepareCSVDownload(suggestedUnits, fields, 'Suggested_units');
      }
    });
  };

  const doExportCategories = () => {
    setIsFileProcessing(true);
    exportCategories({
      onError: () => {
        setIsFileProcessing(false);
        notification.error({ message: t('unableExportCategories') });
      },
      onSuccess: (categories) => {
        const fields = ['_id', 'name', 'description', 'image'];
        prepareCSVDownload(categories, fields, 'Categories');
      }
    });
  };

  const doExportCategoryMapping = () => {
    setIsFileProcessing(true);
    exportCategoryMapping({
      onError: () => {
        setIsFileProcessing(false);
        notification.error({ message: t('unableExportCategoryMapping') });
      },
      onSuccess: (categories) => {
        const fields = ['_id', 'category', 'originCategory', 'altCategory', 'altKeywords', 'altCancelKeywords'];
        prepareCSVDownload(categories, fields, 'Category_mapping');
      }
    });
  };

  const prepareCSVDownload = (items, fields, filePrefix) => {
    const file = items.reduce((accIt, it) => {
      const itemString = fields.reduce((acc, field, idx) =>
        acc + getCsvVal(it?.[field]) + (idx < fields.length - 1 ? ',' : ''), '');
      return `${accIt + itemString}\n`;
    }, `${fields.join(',')}\n`);

    const link = document.createElement('a');
    link.id = `${filePrefix}_download_link`;
    link.download = `${filePrefix}_${Date.now()}.csv`;
    const data = new Blob([file], { type: 'text/csv' });
    if (downloadFileUrl) window.URL.revokeObjectURL(downloadFileUrl);
    const url = window.URL.createObjectURL(data);
    setDownloadFileUrl(url);
    link.href = url;

    document.body.append(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
      setIsFileProcessing(false);
    }, 10);
  };

  const getCsvVal = (value) => {
    const valuesToProcess = Array.isArray(value) ? value : [value];
    let str = valuesToProcess.map((value) => value ? value.toString() : '').join(',');
    if (str.indexOf(',') >= 0) str = `"${str.replace(/"/g, '""')}"`;
    return str;
  };

  const doImportData = (file, dataType) => {
    setIsFileProcessing(true);
    importData({
      file,
      dataType,
      onSuccess: () => {
        notification.success({ message: t(`csv.${dataType}Imported`) });
        setIsFileProcessing(false);
      },
      onError: () => {
        setIsFileProcessing(false);
      }
    });
    return false;
  };

  const renderUsersStatistics = () => {
    return (<>
      {['admins', 'publishers', 'users'].map((category) => (
        <Row key={category} className={styles.statisticsString}>
          <Col className={styles.header}>{t(category)}:</Col>
          <Col className={styles.amount}>{usersStatistics?.[category]?.total ?? '?'}</Col>
          <Col className={styles.activeAmount}>{`(${usersStatistics?.[category]?.active ?? '?'} ${t('active')})`}</Col>
        </Row>
      ))}
    </>);
  };

  const renderDayOfWeekSelector = () => {
    return (
      <Select defaultValue={newSchedule.dayOfWeek} style={{ width: 120, lineHeight: '42px' }} onChange={onDayOfWeekChange}>
        {[...Array(7).keys()].map((value) => (
          <Select.Option key={value} value={value}>{dayOfWeekToString(value)}</Select.Option>
        ))}
      </Select>
    );
  };

  return (
    <>
      <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
        <Col className={styles.cardWrapper}>
          <div className={styles.pageTitle}>{t('adminDashboard')}</div>
          <div className={styles.pageSubtitle}>{t('usersStatistics')}:</div>
          {renderUsersStatistics()}
          <div>
            <Button type="primary" onClick={retrieveUsersStatistics}>{t('refresh')}</Button>
          </div>
          {(currentSchedule && newSchedule) && (
            <>
              <br/>
              <div className={styles.pageSubtitle}>{t('recipePromotionSchedule')}:</div>
              <Row type="flex" justify="start" align="middle" className={styles.scheduleBlock}>
                <Col className={styles.label}>{`${t('currentPromotionTime')}: `}</Col>
                <Col>
                  {` ${dayOfWeekToString(currentSchedule.dayOfWeek)}, 
                  ${get12hString(currentSchedule.hour, currentSchedule.minute)}`}
                </Col>
                <Col className={styles.dayPicker}>{renderDayOfWeekSelector()}</Col>
                <Col className={styles.timePickerWrapper}>
                  <TimePicker
                    showSecond={false}
                    defaultValue={parse(`${newSchedule.hour}:${newSchedule.minute} Z`, 'H:m X', new Date())}
                    className={`${styles.timePicker} TimePickerRecipePromotion`}
                    onChange={onTimeChange}
                    format="h:mm a 'UTC'xxxxx"
                    use12Hours
                    allowEmpty={false}
                  />
                </Col>
                <Button
                  type="primary"
                  onClick={doUpdateRecipePromotionSchedule}
                  disabled={
                    currentSchedule.dayOfWeek === newSchedule.dayOfWeek
                    && currentSchedule.hour === newSchedule.hour
                    && currentSchedule.minute === newSchedule.minute
                  }
                >
                  {t('update')}
                </Button>
              </Row>
              <Row type="flex" justify="start" align="middle" className={styles.scheduleBlock}>
                <Col className={styles.label}>{`${t('lastPromotion')}: `}</Col>
                <Col>
                  {` ${currentSchedule?.lastTimeExecuted
                    ? format(new Date(currentSchedule.lastTimeExecuted), "yyyy-MM-dd h:mm a 'UTC'xxxxx")
                    : 'undefined'}`}
                </Col>
              </Row>
            </>
          )}
          <br />
          <div className={styles.pageSubtitle}>{t('dataImportExport')}:</div>
          <Row className={styles.importExportControl} type="flex" justify="space-between">
            <Button type="primary" onClick={doExportSuggestedItems} disabled={isFileProcessing}>
              {t('exportSuggestedItems')}
            </Button>
            <Upload { ...uploaderProps } beforeUpload={(file) => doImportData(file, 'SuggestedItems')}>
              <Button type="secondary" onClick={() => {}} disabled={isFileProcessing}>
                <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
                {t('importSuggestedItems')}
              </Button>
            </Upload>
          </Row>
          <Row className={styles.importExportControl} type="flex" justify="space-between">
            <Button type="primary" onClick={doExportSuggestedUnits} disabled={isFileProcessing}>
              {t('exportSuggestedUnits')}
            </Button>
            <Upload { ...uploaderProps } beforeUpload={(file) => doImportData(file, 'SuggestedUnits')}>
              <Button type="secondary" onClick={() => {}} disabled={isFileProcessing}>
                <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
                {t('importSuggestedUnits')}
              </Button>
            </Upload>
          </Row>
          <Row className={styles.importExportControl} type="flex" justify="space-between">
            <Button type="primary" onClick={doExportCategories} disabled={isFileProcessing}>
              {t('exportCategories')}
            </Button>
            <Upload { ...uploaderProps } beforeUpload={(file) => doImportData(file, 'Categories')}>
              <Button type="secondary" onClick={() => {}} disabled={isFileProcessing}>
                <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
                {t('importCategories')}
              </Button>
            </Upload>
          </Row>
          <Row className={styles.importExportControl} type="flex" justify="space-between">
            <Button type="primary" onClick={doExportCategoryMapping} disabled={isFileProcessing}>
              {t('exportCategoryMapping')}
            </Button>
            <Upload { ...uploaderProps } beforeUpload={(file) => doImportData(file, 'CategoryMapping')}>
              <Button type="secondary" onClick={() => {}} disabled={isFileProcessing}>
                <FontAwesomeIcon icon="upload" className={styles.uploadIcon} />
                {t('importCategoryMapping')}
              </Button>
            </Upload>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

AdminDashboard.propTypes = {
  usersStatistics: PropTypes.object,
  getUsersStatistics: PropTypes.func.isRequired,
  getRecipePromotionSchedule: PropTypes.func.isRequired,
  updateRecipePromotionSchedule: PropTypes.func.isRequired,
  exportSuggestedItems: PropTypes.func.isRequired,
  exportSuggestedUnits: PropTypes.func.isRequired,
  exportCategories: PropTypes.func.isRequired,
  exportCategoryMapping: PropTypes.func.isRequired,
  importData: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ admin: { usersStatistics } }) => ({
      usersStatistics
    }),
    {
      getUsersStatistics,
      getRecipePromotionSchedule,
      updateRecipePromotionSchedule,
      exportSuggestedItems,
      exportSuggestedUnits,
      exportCategories,
      exportCategoryMapping,
      importData
    }
  )
)(AdminDashboard);
