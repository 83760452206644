import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

const Secondary = ({ children, className = '', ...props }) => {
  return (
    <Button className={`${styles.button} ${className}`} size="large" type="default" {...props}>
      {children}
    </Button>
  );
};

Secondary.propTypes = {
  children: PropTypes.node
};

export default Secondary;
