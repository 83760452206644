export default {
  admins: 'Admins',
  admin: 'Admins',
  publishers: 'Publishers',
  blogger: 'Publishers',
  users: 'Users',
  user: 'Users',
  userEntity: 'user',

  active: 'active during last 7 days',
  adminDashboard: 'Admin Dashboard',
  usersStatistics: 'Users statistics',
  publishersControl: 'Publishers Control',
  usersList: 'Users List',
  unableGetUsersStatistics: 'Unable to get users statistics',
  unableGetRecipePromotionSchedule: 'Unable to get recipes\' promotion schedule',
  unableUpdateRecipePromotionSchedule: 'Unable to update recipes\' promotion schedule',
  refresh: 'Refresh',
  all: 'All',
  registered: 'Registered',
  approved: 'Approved',
  rejected: 'Rejected',
  deleted: 'Deleted',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  blogUrl: 'Website URL',
  createdAt: 'Created',
  status: 'Status',
  controls: 'Controls',
  approve: 'Approve',
  reject: 'Reject',
  delete: 'Delete',
  searchPublishers: 'Search publishers',
  testToolTitle: 'Test 3rd party pages generator',
  url: 'URL',
  loadPage: 'Load page',
  unableRetrievePage: 'Unable to get a recipe from the target page',
  unableRetrievePublisher: 'Unable to retrieve the publisher',
  recipeJson: 'Recipe JSON',
  undefined: 'Undefined',
  publisherId: 'Publisher ID',
  externalId: 'External recipe ID',
  loadPublisher: 'Load publisher',
  publisher: 'Publisher',
  generateHtml: 'Generate HTML file',
  recipePromotionSchedule: 'Recipes\' promotion schedule',
  lastPromotion: 'Previous promotion',
  currentPromotionTime: 'Current promotion time',
  update: 'Update',
  seeRecipes: 'See recipes',
  role: 'Role',
  referralPublisher: 'Referral publisher',
  exportSuggestedItems: 'Export suggested items',
  importSuggestedItems: 'Import suggested items',
  exportSuggestedUnits: 'Export suggested units',
  importSuggestedUnits: 'Import suggested units',
  exportCategories: 'Export categories',
  importCategories: 'Import categories',
  exportCategoryMapping: 'Export USDA category mapping',
  importCategoryMapping: 'Import USDA category mapping',
  unableExportSuggestedItems: 'Unable to export suggested items',
  unableExportSuggestedUnits: 'Unable to export suggested units',
  unableExportCategories: 'Unable to export categories',
  unableExportCategoryMapping: 'Unable to export USDA category mapping',
  dataImportExport: 'Data import/export',
  csv: {
    incorrectFileFormat: 'Incorrect data format',
    SuggestedItemsImported: 'Suggested items were imported successfully',
    SuggestedUnitsImported: 'Suggested units were imported successfully',
    CategoriesImported: 'Categories were imported successfully',
    CategoryMappingImported: 'Categories was imported successfully'
  }
};
