import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Menu } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { ActionDropdown, Button, Icon, Logo } from '..';
import './styles.global.scss';
import history from '../../../../utils/history';
import { withDimensions } from '../../HOC';


const MainHeader = ({ NavMenu, NavMenuMobile, dimensions, user, logOut, externalSource, accessibleItems }) => {
  const { t } = useTranslation('common');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useLayoutEffect(() => {
    const closeDD = (e) => {
      if (isMenuVisible && e.target?.id !== 'h-button' && e.target?.id !== 'h-icon') setIsMenuVisible(() => false);
    };

    window.addEventListener('mouseup', closeDD);
    window.addEventListener('touchend', closeDD);
    return () => {
      window.removeEventListener('touchend', closeDD);
      window.removeEventListener('mouseup', closeDD);
    };
  }, [isMenuVisible]);

  const renderUserAccountLabel = () => {
    return (
      <div>
        {user?.picture?.value ? (
          <img
            width="40"
            height="40"
            className={`${styles.accountName}`}
            src={user.picture?.value ?? '/avatar.svg'}
            alt="user avatar"
          />
        ) : (
          <div className={styles.accountName}>
            {user.firstName?.trim()?.[0] ?? ''}{user.lastName?.trim()?.[0] ?? ''}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {dimensions?.isScreenBiggerLG ? (
        <div className={styles.headerWrapper}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Logo to={!externalSource ? '/' : '/ingredient-import'} />
            </Col>
            {user ? (
              <Row type="flex" justify="end" align="middle" className={styles.controlWrapper}>
                <div className="NavMenuWrapper">
                  <NavMenu />
                </div>
                <ActionDropdown triggerElement={renderUserAccountLabel()} listPlacement="bottomRight">
                  <Menu className="template-action-menu">
                    <Menu.Item key={-100}>
                      <div onClick={() => history.push('/account')}>
                        <Icon name="profile" className={styles.menuIcon}/>
                        {t('editProfile')}
                      </div>
                    </Menu.Item>
                    <Menu.Item key={-101}>
                      <div onClick={() => logOut(true)}>
                        <Icon name="logout" className={styles.menuIcon}/>
                        {t('logOut')}
                      </div>
                    </Menu.Item>
                  </Menu>
                </ActionDropdown>
                {/*<Col className={styles.accountName} onClick={() => logOut(true)}>
                  {user.firstName?.trim()?.[0] ?? ''}{user.lastName?.trim()?.[0] ?? ''}
                </Col>*/}
              </Row>
            ) : (<Col />)}
          </Row>
        </div>
      ) : (
        <div className={styles.headerWrapperMobile}>
          <Row type="flex" justify={!externalSource ? 'space-around' : 'space-between'} align="middle" className={styles.line}>
            <Col>
              <Logo height={!externalSource ? 24 : 36} />
            </Col>
            {(user && !externalSource) && (
              <Col className={styles.hamburgerIconWrapper}>
                <Button type="icon" rounded id="h-button" onClick={() => setIsMenuVisible(!isMenuVisible)}>
                  <Icon name="hamburger" id="h-icon"/>
                </Button>
              </Col>
            )}
          </Row>
          {(user && !externalSource) && (
            <div
              className={`${styles.ddMenu} ${isMenuVisible ? '' : styles.hidden}`}
              style={isMenuVisible ? { height: `${44 * (accessibleItems.length + 2)}px` } : {}}
            >
              <NavMenuMobile />
              <div onClick={() => history.push('/account')} key={-200} className={`${styles.ddMenuItem}`}>
                {t('editProfile')}
              </div>
              <div
                onClick={() => logOut(true)} key={-201} className={`${styles.ddMenuItem}`}>
                {t('logOut')}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

MainHeader.propTypes = {
  accessibleItems: PropTypes.arrayOf(PropTypes.object),
  NavMenu: PropTypes.func.isRequired,
  NavMenuMobile: PropTypes.func.isRequired,
  user: PropTypes.object,
  logOut: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ dimensions, recipe: { externalSource } }) => ({
      dimensions, externalSource
    }),
    null
    /*{

    }*/
  ),
  withDimensions
)(MainHeader);
