import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';

import { Row, Col, notification, Menu, Checkbox, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import {
  getRecipe,
  setRating,
  getRecipeNotes,
  setRecipeNotes,
  acceptRecipe,
  declineRecipe,
  registerUrlClick,
  deleteRecipe
} from '../../sagas';
import {
  ActionDropdown,
  Button,
  DeleteEntityModal,
  Footer,
  Icon,
  Spinner
} from '../../../common/components';
import history from '../../../../utils/history';
import { ImportIngredientsModal } from '../../components';
import { useModal } from '../../../common/hooks';
import { OnlineShopsSelector } from '../../../lists/components';

const { TextArea } = Input;

const SingleRecipe = ({
  getRecipe,
  recipe,
  user,
  setRating,
  getRecipeNotes,
  setRecipeNotes,
  acceptRecipe,
  declineRecipe,
  registerUrlClick,
  deleteRecipe
}) => {
  const { t } = useTranslation('recipe');
  const { recipeId } = useParams();
  const [rejectedIngredients, setRejectedIngredients] = useState([]);
  const [notes, setNotes] = useState('');
  const [currentNotes, setCurrentNotes] = useState('');
  const [isNotesEditing, setIsNotesEditing] = useState(false);
  const [isMyRecipe, setIsMyRecipe] = useState(false);
  const [formedList, setFormedList] = useState(undefined);

  useEffect(() => {
    getRecipe({
      onError: () => {
        notification.error({ message: t('unableGetRecipe') });
        history.push('/recipes');
      },
      onSuccess: () => {
        getRecipeNotes({
          id: recipeId,
          onSuccess: (data) => {
            setNotes(data?.text ?? '');
          },
          onError: () => {
            notification.error({ message: t('unableGetNotes') });
          }
        });
      },
      recipeId
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    setRejectedIngredients([]);
  }, [recipe]); //eslint-disable-line

  useEffect(() => {
    if (recipe && user) {
      setIsMyRecipe((recipe.owners ?? []).indexOf(user._id) >= 0);
    }
  }, [recipe, user]); //eslint-disable-line

  useEffect(() => {
    if (recipe) {
      const newList = { name: recipe.title, items: recipe.ingredients ?? [] };
      newList.items = newList.items.filter((ingredient) => rejectedIngredients.indexOf(ingredient._id) < 0);
      setFormedList(newList);
    } else setFormedList(undefined);
  }, [recipe, rejectedIngredients]); //eslint-disable-line

  const {
    Modal: BuyOnlineModal,
    showModal: showBuyOnlineModal
  } = useModal({
    body: {
      title: t('buyOnline'),
      titleAlignment: 'left',
      Component: OnlineShopsSelector,
      componentProps: {
        list: Object.assign({}, formedList),
        user
      }
    },
    ok: {
      isHide: true
    },
    modal: {
      destroyOnClose: true
    },
    closable: true,
    maskClosable: true
  });

  const onChangeRating = (value) => {
    setRating({
      id: recipe._id,
      score: value,
      onError: () => notification.error({ message: t('unableRate') })
    });
  };

  const getGlobalRating = () => {
    const sum = recipe.rating?.reduce?.((acc, r) => (r.score + acc), 0) ?? 0;
    const votes = recipe.rating?.length ?? 0;
    return votes > 0 ? +(sum / votes).toFixed(2) : 0;
  };

  const onNotesChange = event => setCurrentNotes(event.target.value);

  const onSaveNotes = () => {
    setRecipeNotes({
      id: recipeId,
      text: currentNotes,
      onSuccess: (data) => {
        setNotes(data?.text ?? '');
        setIsNotesEditing(false);
      },
      onError: () => notification.error({ message: t('unableSaveNotes') })
    });
  };

  const checkIngredient = (id, newState) => {
    let currentlyRejected = rejectedIngredients;
    if (!newState) {
      currentlyRejected.push(id);
      currentlyRejected = currentlyRejected.filter((value, index, self) => self.indexOf(value) === index);
    } else currentlyRejected = currentlyRejected.filter((rejectedId) => rejectedId !== id);
    setRejectedIngredients(currentlyRejected);
  };

  const doAcceptRecipe = () => {
    acceptRecipe({
      recipeId: recipe._id,
      onSuccess: () => setIsMyRecipe(true),
      onError: () => notification.error({ message: t('unableAcceptRecipe') })
    });
  };

  const doDeclineRecipe = () => {
    declineRecipe({
      recipeId: recipe._id,
      onSuccess: () => history.push('/recipes?switchToMode=suggestedRecipes'),
      onError: () => notification.error({ message: t('unableDeclineRecipe') })
    });
  };

  const onDeleteRecipe = (recipe) => {
    deleteRecipe({
      recipeId: recipe._id,
      onSuccess: () => {
        notification.info({ message: t('recipeDeleted') });
        history.push('/recipes');
      },
      onError: () => {
        notification.error({ message: t('recipeDeletionFailed') });
      }
    });
  };

  const onUrlClick = () => {
    registerUrlClick({ id: recipe._id });
  };

  const renderIngredient = (item) => {
    return (
      <Row
        className={styles.item}
        key={item.name + item._id}
        align="middle"
        justify="space-between"
      >
        <Col>
          <span className={styles.checkBoxWrapper}>
            <Checkbox
              onChange={(e) => checkIngredient(item._id, e.target.checked)}
              checked={rejectedIngredients.indexOf(item._id) < 0}
            />
          </span>
          <span className={styles.name}>{item.name}</span>
        </Col>
        <Col>
          <span className={styles.amount}>
            {item.amount ? `${item.amount} ` : (item.unit?.name ? '1 ' : '')}
            {item.unit?.pluralName && item.amount !== 1 ? item.unit.pluralName : (item.unit?.name ?? (item.unit ?? ''))}
          </span>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {!recipe
        ? (<Spinner className={styles.spinner} size="large" />)
        : (
          <Row className={styles.pageWrapper} type="flex" align="top" justify="center">
            <Col className={styles.pageContent}>
              <div className={styles.linkBack}>
                <Button type="bareLink" onClick={() => history.push(`/recipes${!isMyRecipe ? '?switchToMode=suggestedRecipes' : ''}`)}>
                  {t('backToList')}
                </Button>
              </div>
              <Row type="flex" align="middle" justify="space-between" className={styles.headLine}>
                <Col className={styles.recipeTitle}>
                  {recipe.title}
                </Col>
                <Col>
                  <ActionDropdown
                    triggerElement={(
                      <Button type="icon">
                        <Icon name="more-vert" />
                      </Button>
                    )}
                    listPlacement="bottomRight"
                  >
                    <Menu className="template-action-menu">
                      <Menu.Item key={-400}>
                        <ImportIngredientsModal
                          recipe={recipe}
                          modalTriggerComponent={({ onClick }) => (
                            <div onClick={onClick}>
                              <Icon name="dish" className={styles.menuIcon}/>
                              {t('addAllToShoppingList')}
                            </div>
                          )}
                        />
                      </Menu.Item>
                      <Menu.Item key={-401} disabled={!isMyRecipe}>
                        <DeleteEntityModal
                          entity={recipe}
                          entityName={t('recipe')}
                          deleteFn={onDeleteRecipe}
                          modalTriggerComponent={({ onClick }) => (
                            <div onClick={onClick}>
                              <Icon name="trash" className={styles.menuIcon}/>
                              {t('deleteRecipe')}
                            </div>
                          )}
                        />
                      </Menu.Item>
                    </Menu>
                  </ActionDropdown>
                </Col>
              </Row>
              {recipe.referenceUrl && <Row className={styles.recipesRef}>
                <a href={recipe.referenceUrl} onMouseDown={onUrlClick}>
                  {recipe.referenceAlias ?? (recipe.referenceUrl.length < recipe.title.length ? recipe.referenceUrl : recipe.title)}
                  <Icon name="goto"/>
                </a>
              </Row>}
              <Row type="flex" align="middle" justify="start">
                <Col className={styles.ratingWrapper}>
                  <StarRatings
                    rating={recipe.rating?.find?.((r) => r.user === user._id)?.score ?? 0}
                    starRatedColor="#FFBD00"
                    starHoverColor="#FFBD00"
                    starEmptyColor="#9C9BA2"
                    changeRating={onChangeRating}
                    numberOfStars={5}
                    name='yourRating'
                    starDimension="20px"
                    starSpacing="3px"
                  />
                </Col>
                <Col className={styles.globalScore}>
                  { getGlobalRating() || '?' } / 5
                </Col>
                <Col className={styles.voted}>
                  Voted: {recipe.rating?.length ?? 0} user{recipe.rating?.length === 1 ? '' : 's'}
                </Col>
              </Row>
              <Row type="flex" justify="space-between" >
                <Col className={styles.leftArea}>
                  <Row type="flex" justify="center">
                    <Col className={styles.imageWrapper}>
                      <img
                        className={styles.image}
                        src={recipe.image?.indexOf("url('") === 0
                          ? recipe.image.substr(5, recipe.image.length - 7)
                          : recipe.image
                        }
                        alt="Dish"
                      />
                    </Col>
                  </Row>
                  <div className={styles.recipeDescription}>
                    {recipe.description}
                  </div>
                  {(isMyRecipe && recipe) && (
                    <div className={styles.notesBlock}>
                      <div className={styles.notesTitle}>{t('myNotes')}</div>
                      {!isNotesEditing ? (
                        <div className={styles.notesContent} onClick={() => { setCurrentNotes(notes); setIsNotesEditing(true); }}>
                          <pre>{notes?.length ? notes : t('notesPlaceholder')}</pre>
                        </div>
                      ) : (
                        <>
                          <div className={styles.notesEditorWrapper}>
                            <TextArea
                              size="large"
                              name="description"
                              onChange={event => onNotesChange(event)}
                              value={currentNotes}
                              type="textArea"
                              className={styles.notesTextArea}
                              placeholder={t('provideAnyNotes')}
                            />
                          </div>
                          <Row type="flex" justify="end" className={styles.editorControlsWrapper}>
                            <Button type="secondary" onClick={() => setIsNotesEditing(false)}>{t('cancel')}</Button>
                            <Button type="primary" onClick={onSaveNotes}>{t('save')}</Button>
                          </Row>
                        </>
                      )}
                    </div>
                  )}
                  {(!isMyRecipe && recipe) && (
                    <Row type="flex" justify="center" align="middle" className={styles.acceptingRecipeBlock}>
                      <Button type="primary" onClick={doAcceptRecipe}>{t('addToMyRecipes')}</Button>
                      <Button type="secondary" onClick={doDeclineRecipe}>{t('noThanks')}</Button>
                    </Row>
                  )}
                </Col>
                <Col className={styles.rightArea}>
                  <div className={styles.ingredientsSubtitle}>{t('ingredients')}</div>
                  <div>
                    {recipe.ingredients.map((ingredient) => renderIngredient(ingredient))}
                  </div>
                  <div>
                    <ImportIngredientsModal
                      recipe={recipe}
                      rejectedIngredientIds={rejectedIngredients}
                      modalTriggerComponent={({ onClick }) => (
                        <Button type="primary" onClick={onClick} className={styles.importBtn}>
                          {t('addSelectedToShoppingList')}
                        </Button>
                      )}
                    />
                  </div>
                  <div>
                    <Button type="secondary" onClick={showBuyOnlineModal} className={styles.buyOnlineBtn}>
                      <FontAwesomeIcon icon="cart-plus" className={styles.menuIcon} />
                      {t('buyOnline')}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>
        )}
      {BuyOnlineModal}
      <Footer />
    </>
  );
};

SingleRecipe.propTypes = {
  getRecipe: PropTypes.func.isRequired,
  setRating: PropTypes.func.isRequired,
  getRecipeNotes: PropTypes.func.isRequired,
  setRecipeNotes: PropTypes.func.isRequired,
  recipe: PropTypes.object,
  user: PropTypes.object,
  acceptRecipe: PropTypes.func.isRequired,
  declineRecipe: PropTypes.func.isRequired,
  registerUrlClick: PropTypes.func.isRequired,
  deleteRecipe: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ user: { user }, recipe: { recipe } }) => ({ user, recipe }),
    { getRecipe, setRating, getRecipeNotes, setRecipeNotes, acceptRecipe, declineRecipe, registerUrlClick, deleteRecipe }
  ),
  withRouter
)(SingleRecipe);
