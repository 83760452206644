export default {
  unableGetRecipes: 'Unable to retrieve recipes',
  unableGetSuggestedRecipes: 'Unable to retrieve suggested recipes',
  unableGetRecipe: 'Unable to retrieve recipe',
  unableGetNotes: 'Unable to retrieve recipe notes',
  notImplemented: 'Not implemented yet',
  searchRecipes: 'Search recipes',
  noIngredients: 'No ingredients',
  ingredients: 'Ingredients',
  backToList: '< All recipes',
  backToListOfPublishers: '< All publishers',
  noRecipesFound: 'No recipes were found',
  keepRecipesInOnePlace: 'Keep all your favorite recipes in one place',
  importRecipes: 'Import recipes from food blogs and they will appear here.',
  rateRecipe: 'Rate this recipe:',
  unableRate: 'Unable to update rating',
  myRecipes: 'My Recipes',
  suggestedRecipes: 'Suggested recipes',
  addToShoppingList: 'Add to shopping list',
  addAllToShoppingList: 'Add all ingredients to shopping list',
  addSelectedToShoppingList: 'Add selected ingredients to shopping list',
  deleteRecipe: 'Delete recipe',
  confirmDeletion: 'Confirm deletion',
  this: 'this',
  delete: 'Delete',
  recipe: 'recipe',
  recipes: 'Recipes',
  unableGetLists: 'Unable to retrieve lists',
  unableImportIngredients: 'Unable to import ingredients',
  chooseList: 'Choose list',
  importToNewList: 'Create new list',
  dashboard: 'Dashboard',
  importIngredientsTitle: 'Import Ingredients',
  imported: 'Imported',
  unableProcessRecipe: 'Unable to process recipe',
  cancel: 'Cancel',
  justSaveRecipe: 'Just save this recipe',
  recipeSaved: 'Saved',
  unableSaveRecipe: 'Unable to save recipe',
  unableSaveNotes: 'Unable to save recipe notes',
  myNotes: 'My notes',
  notesPlaceholder: 'Click here to add some notes to the recipe',
  provideAnyNotes: 'Provide any notes you wish',
  save: 'Save',
  promote: 'Promote',
  selectedForPromotion: 'Promotion pending',
  nUsers: '#Users',
  created: 'Created',
  nVotes: '#Votes',
  url: 'URL',
  postTitle: 'Post Title',
  nSentToZestary: 'Recipes sent to Zestary',
  refresh: 'Refresh',
  rating: 'Rating',
  instructions: 'Instructions',
  importingScript: 'Importing script',
  yourId: 'Your user ID',
  unablePromoteRecipe: 'Unable to promote the recipe',
  unableGetBroughtCounts: 'Unable to retrieve brought users counts',
  newRecipeLabel: 'New!',
  declineRecipe: 'Decline recipe',
  acceptRecipe: 'Accept recipe',
  unableAcceptRecipe: 'Unable to accept this suggested list',
  unableDeclineRecipe: 'Unable to decline this suggested list',
  noSuggestedRecipes: 'There are no suggested recipes for you now',
  mayAppearLater: 'They may appear later',
  addToMyRecipes: 'Add to My Recipes',
  noThanks: 'No thanks',
  neverPromoted: 'This recipe was never promoted',
  promotions: 'Promotions',
  latestPromotion: 'Latest',
  linkTransitions: 'Link transitions (total/unique users)',
  notificationsSent: 'Notifications (sent/delivered/clicked)',
  seenAsSuggested: 'Users saw it as suggested',
  rejectedBy: 'Explicitly rejected by',
  publishersRecipes: "Publisher's recipes",
  publisherId: "Publisher's ID",
  broughtUsers: 'Brought users',
  broughtToZestary: 'Users brought to Zestary',
  recipeDeleted: 'The recipe was deleted',
  recipeDeletionFailed: 'Unable to delete the recipe',
  buyOnline: 'Buy online',
  backToIngredientsList: 'Back to ingredients list',
  toSaveIngredientsAndRecipe: 'to add the ingredients to your shopping list and save the recipe',
  logInOrCreateAccount: 'Log in or create an account'
};
