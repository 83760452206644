const parseRecipeData = (recipeData, recipeUrl, recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn) => {
  try {
    const rawRecipe = typeof recipeData === 'string' ? JSON.parse(recipeData) : recipeData;
    let dataType = 'unknown';

    const isWPRMRecipePresent = (item) => {
      return (typeof item['@type'] === 'string' && item['@type'].toLowerCase() === 'recipe')
        || (Array.isArray(item['@type']) && item['@type'].length && item['@type'][0]?.toLowerCase?.() === 'recipe');
    };

    if (rawRecipe.title && rawRecipe.referenceUrl && rawRecipe.description && rawRecipe.ingredients?.length) dataType = 'standard';
    else if (rawRecipe['@context'] && ((rawRecipe['@graph']?.length && (rawRecipe['@graph'].find((g) => isWPRMRecipePresent(g))))
      || isWPRMRecipePresent(rawRecipe))) dataType = 'wprm';
    else if (rawRecipe.name && rawRecipe.summary && rawRecipe['ingredients_flat'] && rawRecipe['instructions_flat']) dataType = 'e_wprm';

    switch (dataType) {
      case 'standard':
        if (recipeUrl) rawRecipe.referenceUrl = recipeUrl;
        if (recipeUrlAlias) rawRecipe.referenceAlias = recipeUrlAlias;
        correctIngredients(rawRecipe, parseIngredients, onSuccessFn, onErrorFn);
        break;
      case 'wprm':
        parseWPRM(rawRecipe, recipeUrl, recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn);
        break;
      case 'e_wprm':
        parseExportWPRM(rawRecipe, recipeUrl, recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn);
        break;
      default:
        onErrorFn('Unable to recognize data format');
        break;
    }
  } catch {
    onErrorFn();
  }
};

const correctIngredients = (recipe, parseIngredients, onSuccessFn, onErrorFn) => {
  const recipeObj = Object.assign({}, recipe);
  recipeObj.ingredients = recipeObj.ingredients.map((ingredient) => {
    if (ingredient.amount || ingredient.unit) return ingredient;
    return typeof ingredient === 'string' ? ingredient : (ingredient.name ?? '');
  });

  const stringIngredients = recipeObj.ingredients
    .filter((ingredient) => typeof ingredient === 'string')
    .map((ingr) => stripTags(ingr));
  const objectIngredients = recipeObj.ingredients
    .filter((ingredient) => typeof ingredient !== 'string')
    .map((ingr) => {
      const ingredient = Object.assign({}, ingr);
      ingredient.name = stripTags(ingredient.name);
      return ingredient;
    });

  if (stringIngredients.length) {
    parseIngredients({
      ingredients: stringIngredients,
      onSuccess: (result) => {
        const correctedResult = result.map((it) => {
          const item = Object.assign({}, it);
          if (item.unit) item.unit = item.unit.name;
          return item;
        });
        recipeObj.ingredients = [...objectIngredients, ...correctedResult];
        onSuccessFn(recipeObj);
      },
      onError: () => onErrorFn()
    });
  } else {
    recipeObj.ingredients = objectIngredients;
    onSuccessFn(recipeObj);
  }
};

const parseWPRM = (rawRecipe, recipeUrl, recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn) => {
  const recipeObj = rawRecipe['@graph']
    ? rawRecipe['@graph'].find((g) => (typeof g['@type'] === 'string' && g['@type'].toLowerCase() === 'recipe')
      || (Array.isArray(g['@type']) && g['@type'].length && g['@type'][0]?.toLowerCase?.() === 'recipe'))
    : rawRecipe;

  const parsedRecipe = {};
  if (recipeObj.name) parsedRecipe.title = recipeObj.name; else throw new Error('Incorrect format: recipe name is not defined');
  if (recipeObj['@id']) parsedRecipe.referenceUrl = recipeObj['@id'];
  else if (recipeObj['url']) parsedRecipe.referenceUrl = recipeObj['url'];
  else if (recipeUrl) parsedRecipe.referenceUrl = recipeUrl;
  else throw new Error('Incorrect format: recipe URL is not defined');

  if (recipeObj.image) {
    if (typeof recipeObj.image === 'string') parsedRecipe.image = recipeObj.image;
    else if (recipeObj.image.length) parsedRecipe.image = recipeObj.image[0];
  }

  parsedRecipe.description = recipeObj.description || 'No description.';

  if (Array.isArray(recipeObj.recipeIngredient)) parsedRecipe.ingredients = recipeObj.recipeIngredient;
  else throw new Error('Incorrect format: no ingredients');
  if (recipeUrl) parsedRecipe.referenceUrl = recipeUrl;
  if (recipeUrlAlias) parsedRecipe.referenceAlias = recipeUrlAlias;

  correctIngredients(parsedRecipe, parseIngredients, onSuccessFn, onErrorFn);
};

const parseExportWPRM = (rawRecipe, recipeUrl, recipeUrlAlias, parseIngredients, onSuccessFn, onErrorFn) => {
  const parsedRecipe = {};
  if (rawRecipe.name) parsedRecipe.title = rawRecipe.name; else throw new Error('Incorrect format: recipe name is not defined');

  if (rawRecipe['image_url']) {
    if (typeof rawRecipe['image_url'] === 'string') parsedRecipe.image = rawRecipe['image_url'];
    else if (rawRecipe['image_url'].length) parsedRecipe.image = rawRecipe['image_url'][0];
  }

  parsedRecipe.description = htmlDecode(rawRecipe.summary.replace(/<\/?[^>]+(>|$)/g, '')) || 'No description.';

  if (Array.isArray(rawRecipe['ingredients_flat'])) {
    parsedRecipe.ingredients = rawRecipe['ingredients_flat'].map((ingredient) => {
      const formattedIngredient = { name: `${ingredient.name}${ingredient.notes ? (`, ${ingredient.notes}`) : ''}` };
      if (ingredient.converted?.['2']?.amount) formattedIngredient.amount = ingredient.converted?.['2']?.amount;
      if (ingredient.converted?.['2']?.unit) formattedIngredient.unit = ingredient.converted?.['2']?.unit;
      return formattedIngredient;
    });
  } else throw new Error('Incorrect format: no ingredients');

  if (recipeUrl) parsedRecipe.referenceUrl = recipeUrl;
  if (recipeUrlAlias) parsedRecipe.referenceAlias = recipeUrlAlias;

  correctIngredients(parsedRecipe, parseIngredients, onSuccessFn, onErrorFn);
};

const htmlDecode = (s) => {
  const el = document.createElement('div');
  el.innerHTML = s;
  s = el.innerText;
  return s;
};

const stripTags = (str) => {
  return str.replace(/(<([^>]+)>)/ig, ' ').replace(/\s\s/g, ' ');
};


/*const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};*/

export default parseRecipeData;
