import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { doFacebookLogin } from '../../user/sagas';
import history from '../../../utils/history';
import { Spinner } from '../../common/components';
import styles from './styles.module.scss';

const Facebook = props => {
  const { doFacebookLogin, location, externalSource } = props;
  const { t } = useTranslation('login');

  useEffect(() => {
    const { code, error, state } = queryString.parse(location.search);
    if (error || !code) {
      notification.error({ message: t('unableToLoginWithFacebook') });
      history.push('/login');
    } else {
      doFacebookLogin({
        listId: state ?? null,
        codeString: { code },
        onSuccess: () => {
          history.push(!externalSource ? '/lists' : '/ingredient-import');
        },
        onError: () => {
          notification.error({ message: t('unableToLoginWithFacebook') });
          history.push('/login');
        }
      });
    }
  }, []); // eslint-disable-line

  return (<Spinner className={styles.spinner} size="large" />);
};

Facebook.propTypes = {
  doFacebookLogin: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  connect(
    ({ recipe: { externalSource } }) => ({ externalSource }),
    { doFacebookLogin }
  )
)(Facebook);
