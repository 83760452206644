//eslint-disable
export const pageTemplate = '<!DOCTYPE html>\n' +
  '<html lang="en">\n' +
  '<head>\n' +
  '    <meta charset="UTF-8">\n' +
  '    <script\n' +
  '            src="{{{APP_URL}}}/scripts/zestary-ingredient-importer.js"\n' +
  '            type="application/javascript">\n' +
  '    </script>\n' +
  '    <script>\n' +
  '\n' +
  '      /* ---=== Edit this ===--- */\n' +
  '      var FAKE_RECIPE = {{{RECIPE_JSON}}};\n' +
  '      var FAKE_PUBLISHER_ID = \'{{{PUBLISHER_ID}}}\';\n' +
  '      var FAKE_EXTERNAL_ID = \'{{{RECIPE_EXTERNAL_ID}}}\';\n' +
  '      /* ---=== Edit this ===--- */\n' +
  '\n' +
  '\n' +
  '      function init() {\n' +
  '        document.getElementById(\'recipeId\').innerText = FAKE_EXTERNAL_ID;\n' +
  '        document.getElementById(\'publisherId\').innerText = FAKE_PUBLISHER_ID;\n' +
  '        document.getElementById(\'recipe\').innerText = JSON.stringify(FAKE_RECIPE, null, 2);\n' +
  '      }\n' +
  '\n' +
  '      function onClick() {\n' +
  '        if (window.zestaryIngredientImporter?.openImporter)\n' +
  '          window.zestaryIngredientImporter.openImporter({\n' +
  '            recipe: FAKE_RECIPE,\n' +
  '            recipeId: FAKE_EXTERNAL_ID,\n' +
  '            publisherId: FAKE_PUBLISHER_ID,\n' +
  '            url: \'{{{APP_URL}}}/ingredient-import\'\n' +
  '          });\n' +
  '      }\n' +
  '    </script>\n' +
  '    <style>\n' +
  '        .wrapper {\n' +
  '            padding: 50px;\n' +
  '        }\n' +
  '    </style>\n' +
  '    <title>Test Recipe Page</title>\n' +
  '</head>\n' +
  '<body onload="init()">\n' +
  '<div class="wrapper">\n' +
  '    <h2>Test Recipe Page</h2>\n' +
  '    <button type="button" onclick="onClick()">Import ingredients</button>\n' +
  '    <h5>Recipe ID: <span id="recipeId"></span></h5>\n' +
  '    <h5>Publisher ID: <span id="publisherId"></span></h5>\n' +
  '    <h5>Recipe:</h5>\n' +
  '    <pre id="recipe"></pre>\n' +
  '</div>\n' +
  '</body>\n' +
  '</html>';
