import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Menu, Row } from 'antd';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import ReactStoreBadges from 'react-store-badges';

import styles from './styles.module.scss';
import './styles.global.scss';
import { logOut } from '../../../user/sagas';
import { Modal } from '../../../modal/components';
import { setModal } from '../../../modal/reducers';
import MainHeader from './MainHeader';
import modules from '../../..';
import history from '../../../../utils/history';
import config from '../../../../config';
import { useModal } from '../../hooks';

const { Content, Header } = Layout;

const conversionPath = path => {
  if (path?.indexOf('http') === 0 || path?.indexOf('https') === 0) {
    return path;
  }
  return `/${path || ''}`.replace(/\/+/g, '/');
};

const getMenuItemPath = ({ target, name, path, t }) => (
  <Link to={conversionPath(path)} target={target} title={t(`navMenu.${name}`)}>
    {t(`navMenu.${name}`)}
  </Link>
);

const MainLayout = props => {
  const {
    location: { pathname },
    user,
    children,
    modal,
    setModal,
    isHiddenMainLayoutWrapper,
    logOut
  } = props;

  const { t } = useTranslation('common');
  const [openKeys, setOpenKeys] = useState([]);
  const [accessibleItems, setAccessibleItems] = useState([]);

  const cookies = new Cookies();
  let currentJWT = null;
  let checkJWTTimeout = null;

  useEffect(() => {
    return () => {
      if (checkJWTTimeout) clearTimeout(checkJWTTimeout);
    };
  }); // eslint-disable-line

  useEffect(() => {
    if (window.mobileCheck) {
      if (!cookies.get('mobileSuggestionDisplayed') && window.mobileCheck()) {
        cookies.set('mobileSuggestionDisplayed', '1', { path: '/', sameSite: true, secure: true, maxAge: 3600 * 48 });
        showMobileModal();
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (user?.role) {
      setAccessibleItems(modules.menuItems.filter((item) => !item.authorization?.length || item.authorization.indexOf(user.role) >= 0));
    }

    if (config.GA_TRACKING_ID) {
      ReactGA.set({ userId: user?._id });
    }
  }, [user]); // eslint-disable-line

  //Uploads state if JWT changes
  const observeForJWTChange = () => {
    if (checkJWTTimeout) clearTimeout(checkJWTTimeout);
    checkJWTTimeout = setTimeout(() => {
      const newJWT = cookies.get('X-JWT');
      if (!currentJWT) {
        currentJWT = newJWT;
        observeForJWTChange();
      } else if (currentJWT !== newJWT) {
        if (window.location.pathname !== '/login') logOut(false); // eslint-disable-line
      } else {
        observeForJWTChange();
      }
    }, 400);
  };
  if (!isHiddenMainLayoutWrapper) observeForJWTChange();

  useEffect(() => {
    if (modules && !openKeys.includes(pathname)) {
      setOpenKeys([pathname]);
    }
  }, [openKeys, pathname]); // eslint-disable-line

  const { Modal: MobileModal, showModal: showMobileModal, hideModal: hideMobileModal } = useModal({
    body: {
      title: t('mobileDetected'),
      titleAlignment: 'left',
      Component: () => (
        <>
          <p>It seems that you are using a mobile device.</p>
          <p>We suggest you using the mobile application for better experience.</p>
          <Row type="flex" justify="center" align="middle" className={styles.storeBadgesWrapper}>
            <ReactStoreBadges
              platform={'ios'}
              url={config.IOS_APP_LINK ?? ''}
              locale={'en-us'}
              height={44}
            />
            <ReactStoreBadges
              platform={'android'}
              url={config.ANDROID_APP_LINK ?? ''}
              locale={'en-us'}
              height={44}
            />
          </Row>
        </>
      )
    },
    bodyStyle: {
      textAlign: 'left'
    },
    ok: {
      title: t('btns.gotIt'),
      onClick: () =>
        hideMobileModal(),
      btnType: 'primary'
    },
    cancel: {
      isHide: true
    },
    modal: {
      width: 600
    },
    waitForHandleOkCompletion: false,
    closable: true,
    maskClosable: true
  });

  const NavMenu = () => (
    <Menu
      theme="light"
      defaultOpenKeys={accessibleItems.map(item => item.path)}
      defaultSelectedKeys={openKeys}
      selectedKeys={openKeys}
      mode="horizontal"
    >
      {accessibleItems
        /*.filter(item => !item.bloggerOnly || user?.roles?.toLowerCase().indexOf('blogger') >= 0)*/
        .map(item => (
          <Menu.Item key={item.path} style={ item.width ? { width: `${item.width}px` } : {}}>
            {getMenuItemPath({ ...item, t })}
          </Menu.Item>
        ))}
    </Menu>
  );

  const NavMenuMobile = () => (
    <>
      {accessibleItems
        /*.filter(item => !item.bloggerOnly || user?.roles?.toLowerCase().indexOf('blogger') >= 0)*/
        .map(item => (
          <div onClick={() => history.push(item.path)} key={item.path} className={`${styles.ddMenuItem}`}>
            {getMenuItemPath({ ...item, t })}
          </div>
        ))}
    </>
  );

  const renderMainLayout = (
    <Layout className={`MainLayout ${styles.mainLayout}`}>
        <Header className={`${styles.mainHeader}`}>
          <MainHeader NavMenu={NavMenu} user={user} logOut={logOut} NavMenuMobile={NavMenuMobile} accessibleItems={accessibleItems} />
        </Header>
      <Content className={styles.appContent}>{children}</Content>
      {modal.visible && <Modal modal={modal} setModal={setModal} />}
      {MobileModal}
    </Layout>
  );

  return isHiddenMainLayoutWrapper ? children : renderMainLayout;
};

MainLayout.propTypes = {
  children: PropTypes.any,
  isHiddenMainLayoutWrapper: PropTypes.bool,
  logOut: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({
      common: { isHiddenMainLayoutWrapper }, user: { user }, modal: { modal }
    }) => ({
      isHiddenMainLayoutWrapper,
      user,
      modal
    }),
    { logOut, setModal }
  ),
  withRouter
)(MainLayout);
