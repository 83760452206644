import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

const Primary = ({ children, className = '', ...props }) => {
  return (
    <Button className={[styles.button, className].join(' ')} {...props} size="large" type="primary">
      {children}
    </Button>
  );
};

Primary.propTypes = {
  children: PropTypes.node
};

export default Primary;
