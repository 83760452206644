import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

const BareLink = ({ children, className = '', ...props }) => {
  return (
    <Button className={[styles.btn, className].join(' ')} size="large" type="default" {...props}>
      {children}
    </Button>
  );
};

BareLink.propTypes = {
  children: PropTypes.node
};

export default BareLink;
