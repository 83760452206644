import React from 'react';
import { FreeRoute } from './components';

import { Google, Facebook, ExternalSocialLogin } from './pages';
import locales from '../login/locales';

export default {
  routes: [<FreeRoute key="/auth/google" exact path="/auth/google" component={Google} shouldHideMainLayoutWrapper />,
    <FreeRoute key="/auth/facebook" exact path="/auth/facebook" component={Facebook} shouldHideMainLayoutWrapper />,
    <FreeRoute key="/auth/external" exact path="/auth/external" component={ExternalSocialLogin} />],
  locales: { ns: 'auth', resources: locales }
};
