import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

class FieldAdapter extends Component {
  static propTypes = {
    formik: PropTypes.object.isRequired,
    component: PropTypes.func,
    onChangeText: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.string,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.object,
    required: PropTypes.bool,
    hasFeedback: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  onChange = e => {
    const { onChange, formik, name } = this.props;
    if (onChange) {
      onChange(e.target.value, e);
    } else {
      ['string', 'number', 'boolean'].includes(typeof e) ? formik.setFieldValue(name, e) : formik.handleChange(e);
    }
  };

  onBlur = e => {
    const { formik, onBlur, name } = this.props;
    formik.setFieldTouched(name, true);
    onBlur && onBlur(e);
  };

  onChangeText = value => {
    const { formik, onChangeText, onChange, name } = this.props;
    if (onChange && !onChangeText) {
      onChange(value);
    } else if (onChangeText) {
      onChangeText(value);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  render() {
    const {
      formik,
      component,
      name,
      defaultValue,
      defaultChecked,
      disabled,
      input: propInput = {},
      required,
      hasFeedback,
      size = 'large',
      className
    } = this.props;

    const getNestedProperty = obj => {
      return name.split('.').reduce((acc, item) => (acc ? acc[item] : acc), obj);
    };

    let { value, checked } = this.props;
    value = value || '';
    checked = checked || false;
    const meta = {
      touched: getNestedProperty(formik.touched),
      error: getNestedProperty(formik.errors)
    };

    const input = {
      onBlur: this.onBlur,
      name,
      value,
      checked,
      size,
      defaultValue,
      defaultChecked,
      disabled,
      className,
      ...propInput
    };

    const changeEventHandler = 'onChange';
    input[changeEventHandler] = this[changeEventHandler];

    return React.createElement(component, {
      ...this.props,
      input,
      meta,
      required,
      hasFeedback
    });
  }
}

export default connect(FieldAdapter);
