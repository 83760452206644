const minScreenXXL = 1600;
const minScreenXL = 1200;
const minScreenLG = 992;
const minScreenMD = 768;
const minScreenSM = 576;
const minScreenXS = 480;
const minScreenXXS = 420;

const matchScreenWidth = (min, max) => innerWidth => {
  if (!min) {
    return innerWidth < max;
  }

  if (!max) {
    return innerWidth >= min;
  }

  return innerWidth >= min && innerWidth < max;
};

const isScreenXXL = matchScreenWidth(minScreenXXL);
const isScreenXL = matchScreenWidth(minScreenXL, minScreenXXL);
const isScreenLG = matchScreenWidth(minScreenLG, minScreenXL);
const isScreenMD = matchScreenWidth(minScreenMD, minScreenLG);
const isScreenSM = matchScreenWidth(minScreenSM, minScreenMD);
const isScreenXS = matchScreenWidth(minScreenXS, minScreenSM);
const isScreenXXS = matchScreenWidth(null, minScreenXS);

const isScreenLessXXL = matchScreenWidth(null, minScreenXXL);
const isScreenLessXL = matchScreenWidth(null, minScreenXL);
const isScreenLessLG = matchScreenWidth(null, minScreenLG);
const isScreenLessMD = matchScreenWidth(null, minScreenMD);
const isScreenLessSM = matchScreenWidth(null, minScreenSM);
const isScreenLessXS = matchScreenWidth(null, minScreenXS);
const isScreenLessXXS = matchScreenWidth(null, minScreenXXS);

const isScreenBiggerXXL = matchScreenWidth(minScreenXXL);
const isScreenBiggerXL = matchScreenWidth(minScreenXL);
const isScreenBiggerLG = matchScreenWidth(minScreenLG);
const isScreenBiggerMD = matchScreenWidth(minScreenMD);
const isScreenBiggerSM = matchScreenWidth(minScreenSM);
const isScreenBiggerXS = matchScreenWidth(minScreenXS);
const isScreenBiggerXXS = matchScreenWidth(minScreenXXS);

export const computeSizes = ({ innerWidth, innerHeight }) => {
  return {
    innerWidth,
    innerHeight,
    isScreenXXL: isScreenXXL(innerWidth),
    isScreenXL: isScreenXL(innerWidth),
    isScreenLG: isScreenLG(innerWidth),
    isScreenMD: isScreenMD(innerWidth),
    isScreenSM: isScreenSM(innerWidth),
    isScreenXS: isScreenXS(innerWidth),
    isScreenXXS: isScreenXXS(innerWidth),
    isScreenLessXXL: isScreenLessXXL(innerWidth),
    isScreenLessXL: isScreenLessXL(innerWidth),
    isScreenLessLG: isScreenLessLG(innerWidth),
    isScreenLessMD: isScreenLessMD(innerWidth),
    isScreenLessSM: isScreenLessSM(innerWidth),
    isScreenLessXS: isScreenLessXS(innerWidth),
    isScreenLessXXS: isScreenLessXXS(innerWidth),
    isScreenBiggerXXL: isScreenBiggerXXL(innerWidth),
    isScreenBiggerXL: isScreenBiggerXL(innerWidth),
    isScreenBiggerLG: isScreenBiggerLG(innerWidth),
    isScreenBiggerMD: isScreenBiggerMD(innerWidth),
    isScreenBiggerSM: isScreenBiggerSM(innerWidth),
    isScreenBiggerXS: isScreenBiggerXS(innerWidth),
    isScreenBiggerXXS: isScreenBiggerXXS(innerWidth)
  };
};
