import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './styles.module.scss';
import { withDimensions } from '../../../common/HOC';

const InfoBlocks = ({
  dimensions,
  hide,
  block1Ref,
  block2Ref,
  img1MobileRef,
  description1MobileRef,
  img2MobileRef,
  description2MobileRef
}) => {
  const { t } = useTranslation('landing');

  return (
    <>
        {dimensions?.isScreenBiggerXL
          ? (
            <div className={`${styles.infoBlockWrapper}`}>
              <div ref={block1Ref}>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  className={`${styles.paragraphWrapper}`}
                >
                  <div className={`${styles.imageWrapper}`}>
                    <img
                      width="715"
                      height="480"
                      className={`${styles.image} ${hide?.block1 ? styles.hidden : ''}`}
                      src="/Section1.png"
                      alt="sharing list"
                    />
                  </div>
                  <div className={`${styles.textBlock} ${hide?.block1 ? styles.hidden : ''}`}>
                    <p className={styles.subTitle}>{t('subHeader1')}</p>
                    <p className={styles.description}>{t('description1_1')}</p>
                    <p className={styles.description}>{t('description1_2')}</p>
                  </div>
                </Row>
              </div>
              <div ref={block2Ref}>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  className={`${styles.paragraphWrapper}`}
                >
                  <div className={`${styles.textBlock} ${hide?.block2 ? styles.hidden : ''}`}>
                    <p className={styles.subTitle}>{t('subHeader2')}</p>
                    <p className={styles.description}>{t('description2')}</p>
                  </div>
                  <div className={`${styles.imageWrapper}`}>
                    <img
                      width="682"
                      height="600"
                      className={`${styles.image} ${hide?.block2 ? styles.hidden : ''}`}
                      src="/Section2.png"
                      alt="don't forget"
                    />
                  </div>
                </Row>
              </div>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className={`${styles.paragraphWrapper}`}
              >
                <div className={`${styles.imageWrapper}`}>
                  <img
                    width="631"
                    height="483"
                    className={`${styles.image}`}
                    src="/Section3.png"
                    alt="keep recipes in one place"
                  />
                </div>
                <div className={`${styles.textBlock}`}>
                  <p className={styles.subTitle}>{t('subHeader3')}</p>
                  <p className={styles.description}>{t('description3')}</p>
                </div>
              </Row>
            </div>
          )
          : (
            <div className={`${styles.infoBlockWrapper} ${styles.mobile}`}>
              <Row type="flex" justify="center" align="top">
                <div
                  className={`${styles.textBlock} ${hide?.description1Mobile ? styles.hidden : ''}`}
                  ref={description1MobileRef}
                >
                  <p className={styles.subTitle}>{t('subHeader1')}</p>
                  <p className={styles.description}>{t('description1_1')}</p>
                  <p className={styles.description}>{t('description1_2')}</p>
                </div>
              </Row>
              <div className={`${styles.imageWrapper}`} ref={img1MobileRef}>
                <img
                  width="375"
                  height="371"
                  className={`${styles.image} ${hide?.img1Mobile ? styles.hidden : ''}`}
                  src="/Section1M.png"
                  alt="sharing list"
                />
              </div>

              <Row type="flex" justify="center" align="top">
                <div className={`${styles.textBlock} ${hide?.description2Mobile ? styles.hidden : ''}`} ref={description2MobileRef}>
                  <p className={styles.subTitle}>{t('subHeader2')}</p>
                  <p className={styles.description}>{t('description2')}</p>
                </div>
              </Row>
              <div className={`${styles.imageWrapper}`} ref={img2MobileRef}>
                <img
                  width="374"
                  height="527"
                  className={`${styles.image} ${hide?.img2Mobile ? styles.hidden : ''}`}
                  src="/Section2M.png"
                  alt="don't forget"
                />
              </div>

              <Row type="flex" justify="center" align="top">
                <div className={`${styles.textBlock}`}>
                  <p className={styles.subTitle}>{t('subHeader3')}</p>
                  <p className={styles.description}>{t('description3')}</p>
                </div>
              </Row>
              <div className={`${styles.imageWrapper}`}>
                <img
                  width="350"
                  height="360"
                  className={`${styles.image}`}
                  src="/Section3M.png"
                  alt="keep recipes in one place"
                />
              </div>
            </div>
          )
        }
    </>
  );
};

InfoBlocks.propTypes = {
  dimensions: PropTypes.object.isRequired,
  hide: PropTypes.object
};

export default compose(
  connect(
    ({ dimensions }) => ({
      dimensions
    }),
    null
  ),
  withDimensions
)(InfoBlocks);
