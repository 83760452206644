import React from 'react';
import { FreeRoute } from '../auth/components';

import { Landing } from './pages';
import locales from './locales';

export default {
  routes: [<FreeRoute key="login" exact path="/" component={Landing} shouldHideMainLayoutWrapper />],
  locales: { ns: 'landing', resources: locales }
};
